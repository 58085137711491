import React, { useEffect, useState } from 'react';
import { searchWebResources } from '../../../services/search/search';
import { Highlight, Publication } from '../../../types';
import { useSearchContext } from '../context/searchContext';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';



export default function useGetWebResources({
  id,
  websiteId,
  groupBy,
  mode,
}: {
  id: string;
  websiteId?: string;
  groupBy: boolean;
  mode?: 'roots' | 'volume';
}) {
  const [webresources, setWebresources] = useState<
    undefined | { document: Publication; highlight: Highlight }[]
  >(undefined);
  const { query, setTotalHits, pagination, setGroupBy, selectedPubType, setPaginate } = useSearchContext();
  const [newRequest, setNewRequest] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);

  // Debounced Query
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      if (id && !selectedPubType.value) {
        let limit = 7;
        if (groupBy && !debouncedQuery) {
          limit = 2;
        }
        if (!groupBy) {
          limit = pagination.limit;
        }

 
        
          const req = await fetch(searchDocuments('webpages'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              data: searchWebResources({
                q: debouncedQuery,
                volumeId: id,
                groupBy: groupBy ? 'publication_id' : undefined,
                websiteId,
                groupLimit: 5,
                limit,
                offset: pagination.offset,
                page: pagination.page,
                mode,
              }),
            }),
          });

          const res: any = await req.json();

          if (res.data.grouped_hits) {
            setGroupBy({ webresources: true });
            setWebresources(res?.data?.grouped_hits);
            setTotalHits({ webresources: res.data.found_docs });
          } else {
            setGroupBy({ webresources: false });
            setWebresources(res?.data?.hits);
            setTotalHits({ webresources: res.data.found });
          }

          if (!groupBy) {
            setTotalHits({ pubs: null });
          }
       
      }
    })();
  }, [
    debouncedQuery,
    id,
    pagination.offset,
    pagination.page,
    newRequest,
    selectedPubType.value,
    mode,
    groupBy,
    websiteId,
  ]);

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setNewRequest((prev) => prev + 1);
      setIsLoaded(true);
    }, 200);
  }, [pagination.limit, groupBy, websiteId]);

  useEffect(() => {
    setPaginate({ page: 1, offset: 0 });
  }, [groupBy]);

  return {
    webresources,
    isLoaded,
  };
}
