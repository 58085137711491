import React from "react";
// import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

import {

    Pin,
    Help,
    AddPublication,
    AddNew,
    MyContacts,
    Draft,
    Call,
    Link,
    Card,
    Chat,
    Close,
   
    Map,
    NewLocations,
    MyResources,
    Open,
    OpenWindow,
    Share,
    Stats,
    TeddraLogo,

    Viewer,
    HomeIcon,
    FilterIcon,
    Document,
    FlagIcon,
    GroupBy,
    Degroup,
    ViewAsGridIcon,
    ViewAsListIcon,
    ViewAsLargeListIcon,
    PadlockClosed,
   Pie,
   NewSubscribers,
   Subscribers,
    Miniature,
    Zoom,
    Print,
    UserIcon,
    MinuatureTab,
  
    Globe,
    Entreprise,

    List,
    HideTree,
    Thumbnails,
    Blocks,
    Ellipsis,
   
    Search,
    PlusIcon,
    SearchIcon,
    Unpin,
    TeddraLogoTaskBar,
    Cursor,
    Install,
    Folder,
    ChevronDownIcon,
    XCircleIcon,
    Edit,
   Trash,AI,
   UserFolder,
    Preview,Teddra,Image,News,Shortcut,MyPublications,MapMarker,LinkIcon,
    PanelClose, PanelOpen,Phone,OpenPage, PublishedFolder,Desq,Profiles,Pages, 
  
    TwoLines,Modes,LocalTree,Reduce,Calendar, Broadcast, CommercialTree, ModeUrl,MoreInfo,TeddraLogoSelected,SearchByType,SearchByRegion,Server,Datacenter,Notification, OneLine,Manage,Publish,AddLocation,Legal,Unshortcut,EmailSent,Warning,ShowInfo, HideInfo,Delete,Searchloup, Date, Author,File,Password,

    PinBinder,SharedBinder,ExpertBinder,Directory,Drive,AddDocument,WebPage, Classified, Knowledge, Explorer, Jobs, Newspapers, Contacts, Figures, Immo, Calandars

} from "./index";
import { IconOptions } from "../../types";
type ComponentOptions = {
  [key: string]: React.ElementType
}
const Components : ComponentOptions = {
 
    Pin,
    Help,
    AddPublication,
    AddNew,
    ChevronDownIcon,
    Call,
    
    Card,
    Chat,
    Close,
    Pie,
    Map,
    SearchIcon,
    MyResources,
    Open,
    OpenWindow,
    Share,
    Stats,
    TeddraLogo,
   
    Viewer,
    HomeIcon,
    FilterIcon,
    Document,
    FlagIcon,
    GroupBy,
    Degroup,
    ViewAsGridIcon,
    ViewAsListIcon,
    ViewAsLargeListIcon,
    PadlockClosed,
  
    Miniature,
    Zoom,
    Print,
    UserIcon,
    MinuatureTab,
  
    Globe,
    Entreprise,

    List,
    HideTree,
    Thumbnails,
    Blocks,
    Ellipsis,
 
    Search,
    PlusIcon,
   
    Unpin,
    TeddraLogoTaskBar,
    NewLocations,
    Install,
    Folder,
    XCircleIcon,
    Edit,
    Cursor,
    Trash,
    MyContacts,
    Draft,
    NewSubscribers,
    Subscribers,
    Link,
    UserFolder,AI,
    Preview,Teddra,Image,News,Shortcut,MyPublications,MapMarker,LinkIcon,PanelClose, PanelOpen,Phone,OpenPage, PublishedFolder,Desq,Profiles,Pages, 
   TwoLines,Modes,LocalTree,Reduce,Calendar,Broadcast,CommercialTree, ModeUrl, MoreInfo,TeddraLogoSelected,SearchByType,SearchByRegion,Server,Datacenter,Notification,OneLine,Manage,Publish,AddLocation,Legal,Unshortcut,EmailSent,Warning,ShowInfo, HideInfo,Delete, Newspapers,Searchloup, Date, Author,File,Password,PinBinder,SharedBinder,ExpertBinder,Directory, Drive,AddDocument,WebPage, Classified, Knowledge, Explorer, Jobs, Contacts, Figures, Immo, Calandars





};

export default function Icon({ name, className, style } : IconOptions) {
  if (typeof (Components as any)[name ? name : ''] != "undefined") {
    return React.createElement((Components as any)[name ? name : ''], {
      className: className,
      style: style?style:{}
    });
  }

  return <div></div>;
}
