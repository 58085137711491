import React, { useEffect, useState } from 'react'


import { Spin } from 'antd';
import TierComponent from '../../../components/Tier';
import StepsButtons from '../../../components/stepsButtons'
import { allTries } from '../../../../../services/api/request/plans';
import { Tier } from '../../../../../types';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../../context/AuthContext';



export default function PlansCom() {
    const [tiers, settiers] = useState<Tier[]>([]);
    const [loading, setloading] = useState(false);
    const { planId, profile, setplanId, setaccountType, setCurrentStep, accountType} = useSignupContext()
    const navigate = useNavigate()
    const { user } = useAuthContext()
  
    const prevStep=() => navigate('/connect/signup/steps/2')
    const nextStep=() => {
        if(user && user.emailVerified){
            navigate(`/connect/signup/steps/6/${accountType === 'ORGANIZATION' ? 'organization' : 'individual'}`)

        }else if(user && !user.emailVerified){
            navigate(`/connect/signup/steps/5`)
        }else{
            navigate(`/connect/signup/steps/4`)
        }
    }
    useEffect(() => {
    
        setCurrentStep(3)
      }, []);

    useEffect(() => {
        if(profile.id){
            fetch(allTries(profile.id)).then(res => res.json()).then(data => 
            {

                const d = data.data?.reverse().map((d:any) => ({id:d.id, ...d.tierData}))
                // d.push({
                //     title: {en:'Free'},
                //     planId,
                //     price:0,
                //     cycleNbr:30,
                //     nbrOfTestPeriodDays:0,
                //     type:'INDIVIDUAL',
                //     billingCycle:'MONTHLY',
                //     features:[
                //         {
                //             id: '001',
                //             groupFeaturesId: '001',
                //             value: 'Free',
                //             title: {en:'Free'},
                //             code: '001'
                //         },
                //         {
                //             id: '005',
                //             groupFeaturesId: '005',
                //             value: '5',
                //             title: {en:'Free'},
                //             code: '005'
                //         }
                //     ],
                //     description:{
                //         en:'Start with five publications'
                //     },
                //     id:'free'
                // })
                settiers( d)
            }
            ).finally(() => setloading(false)).catch(err => console.log(err))
            

        }else{
            navigate(`/connect/signup/steps/1`)
        }
    }, [profile.id]);

 
    const handleSelect = (data:Tier) => {
        if(data.type && data.id){
            setplanId(data.id!)
            setaccountType(data.type)

        }
    }
  return (
    <div className='space-y-2 flex flex-col h-full'>
        <div className="flex-1">
            <Spin spinning={loading}>
                <div className="h-full flex flex-col space-y-[100px] justify-center">
                    {/* <div className='border-b border-main pb-6'>
                        <p className="text-[22px] font-semibold flex-1 leading-[27px] ml-[41px]">{profile.name}: Choose your plan
                        </p>
                    
                    </div> */}
                    <div className='w-full flex flex-col items-center'>
                        <p className="title-level2 text-center">{profile?.name}</p>
                        <p className="text-level2 text-center">Choose the type of your volum
                        </p>
                        {/* <p className="text-level2 text-center max-w-[400px]">Discover the best plan for your needs</p> */}

                    </div>
                    <div className='flex flex-wrap gap-8 justify-center items-center'>
                     
                            {/* <div className='h-[603px]'>
                            <TierComponent 
                            data={{
                                title: {en:'Free'},
                                planId,
                                price:0,
                                cycleNbr:30,
                                nbrOfTestPeriodDays:0,
                                type:'INDIVIDUAL',
                                billingCycle:'MONTHLY',
                                features:[
                                    {
                                        id: '001',
                                        groupFeaturesId: '001',
                                        value: 'Free',
                                        title: {en:'Free'},
                                        code: '001'
                                    },
                                    {
                                        id: '005',
                                        groupFeaturesId: '005',
                                        value: '5',
                                        title: {en:'Free'},
                                        code: '005'
                                    }
                                ],
                                description:{
                                    en:'Start with five publications'
                                },
                                id:'free'
                            }} 
                            editTier={() => {}} 
                            noActions 
                            active={planId === 'free'} handleSelect={handleSelect}/>

                        
                        </div> */}
                        
                        {tiers?.sort((a,b) => a.price > b.price ? 1 : -1).map(tier => (
                            <div className=' h-max '>
                                <TierComponent key={tier.id} data={tier} editTier={() => {}} noActions active={tier.id === planId} handleSelect={handleSelect}/>

                            
                            </div>
                        ))}
                    </div>
                    
                    <StepsButtons
                        prevStep={prevStep}
                        nextStep={nextStep}
                        disable={!planId || !accountType}
                    />
                </div>
            </Spin>

        </div>
    </div>
  )
}
