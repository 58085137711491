import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Tree } from 'antd'
import React from 'react'
import { ReactSVG } from 'react-svg'
import ReactSvg from '../../../../../components/shared/ReactSvg'

import { getClusterTree } from '../../../../../services/api/request'
import { Server } from '../../../../../types'
import TreeLayout from '../../../../Qfs/components/sidebar/tree/Layout'


type Props = {
    handleServerSelection: (volume:any) => void,
    tree:any,
    settree:any

}
const loopThrough = (tree:any, id:string, data:any) => {
    return tree.map((node:Server) => {
        if (node.id === id) {
            return {
                ...node,
                children:data,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: loopThrough(node.children, id, data)
            };
        }
        return node;
    });
  }
export const mapTreeIcon = (tree:any[]) => {
    return tree.map(item => {
        item.icon = <ReactSvg src={item.iconUrl} className='icon-sm' />
        item.value = item.title
        item.key = item.title

        if(item.children) mapTreeIcon(item.children)
        return item
    })
}
export default function TreeCompenent({ handleServerSelection, tree, settree } : Props) {

    const updateTree:any = async( data:Server) =>{  
        if(data.children) return
        const  children = await fetch(getClusterTree({id:data.id})).then(res => res.json())
    
        
        const newTree =  loopThrough(tree, data.id, children.data)
        settree(newTree)
    }
  return (
    <div>
      
           <TreeLayout
                updateTree={updateTree}
                tree={tree}
                openAction={() => {}}
                handleLocationSelection={(node) => {
                    handleServerSelection(node)
                }}
                selectedKey={''}
            />    
    </div>
  )
}
