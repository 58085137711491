import React, { useState } from 'react'

import PopverLayout from './layout'
import { Server, VolumeSearch } from '../../../../../types'
import useGetPartners from '../../../../Qfs/hooks/useGetPartners'


type Props = {
    item: (Server | VolumeSearch ) & {coll:string},
    openEngine:() => void
}
export default function PublicVolume({ item, openEngine }: Props) {
    const [loading, setloading] = useState(false)
        
    const {partners} = useGetPartners({id:item.id!, coll:item.coll, setloading})
    console.log(partners, 'part')
  return (
    <PopverLayout
        partners={partners}
        loading={loading}
        item={item}
        openEngine={openEngine}
    />
  )
}
