import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import TreeComponent from './tree/TreeComponent'
import { iconType, Server, VolumeSearch } from '../../../../types'
import Search from './search'
import Icon from '../../../../components/icons/Icon'
import { getClusterTree } from '../../../../services/api/request'
import { Spin } from 'antd'

import useGetPartners from '../../hooks/useGetPartners'
import { Link } from 'react-router-dom'


type Props = {
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    handleSearchSelection : (doc:VolumeSearch) => void,
    openAction: ({title, icon,key, id}:{title:string, icon:iconType, key:string, id:string}) => void,
    datacenter: Server | null, 
    serverId:string|undefined, 
    server: Server | null,
    resultsLength:number,
    setresultsLength:any
}


export default function Sidebar({handleLocationSelection, selectedKey,handleSearchSelection, openAction, datacenter, serverId, server, resultsLength, setresultsLength}: Props) {
    const [serverTree, setserverTree] = useState<Server[] | null>([]);
    const [tree, settree] = useState<any[] | null>([]);
    const [partnerVolume, setpartnerVolume] = useState<Server | null>(null);

    const [mode, setmode] = useState<'search'  | 'localTree'>('localTree');
    const [searchValue, setsearchValue] = useState('');
    
    const [loading, setloading] = useState(false);
    const { partners } = useGetPartners({id:server?.id!, coll:'LocalClusters', setloading})
    useEffect(() => {
      (async () => {
        if(!server) return
        setloading(true)
        fetch(getClusterTree({id:server.id})).then(res => res.json()).then(data => {
          
          setserverTree(
            data.data.filter((vol:any) => !vol.media)
      
          
         )
         if(data.data.filter((vol:any) => vol.media)?.length){
            setpartnerVolume(data.data.filter((vol:any) => vol.media)[0])
         }
          
        }).finally(() => setloading(false))
      })()
    }, [server])
    useEffect(() => {
      if(server && datacenter && serverTree){
        settree(
          [
            {
              ...server,

              key:server?.id,
              id: server?.id,
              title:`${datacenter?.title} - ${server?.title}`,
              iconType:'icon',
              iconUrl:'Datacenter',
              path:`/LocalClusters/${server?.id}`,
              children: [
                ...partnerVolume ? [partnerVolume] : [],
                
              
                ...serverTree,

              ]
            }
            
           
          ]
        )

      }
      
    }, [partnerVolume, datacenter, serverTree]);
  return (
    <div className='modules h-full  '>
        <div className=" h-full ">
          <div className='pl-5 flex items-center bar-h space-x-3 '>
            <p className=''>
                <span className="bg-skin-fill-inverted font-bold text-skin-inverted p-2.5 w-max">{datacenter?.title.toLocaleLowerCase()}</span>
                <span className='font-bold relative top-[-2px] mx-0.5'>.</span>
                <span>qorddu</span>
                <span className='font-bold relative top-[-2px] mx-0.5'>.</span>
                <span>com</span>
            </p>
            <div className='h-[15px] w-[1px] bg-gray-500'></div>
            <p className=''>{server?.title}</p>
          </div>
        
          <div className="">
              <Header 
                mode={mode} 
                setmode={setmode} 
                serverId={serverId}
                search={{value:searchValue, setValue:setsearchValue}}
              />
            <Spin spinning={loading}>
              <div className='overflow-auto height-sidebar border-b border-main' id='tutorial-sidebar'>

                {mode === 'search' && (
                  <div className='space-y-2'>
                
                    {/* <SearchResults value={searchValue} selectedKey={selectedKey} handleSearchSelection={handleSearchSelection} results={searchResults}/> */}
                    <Search
                      selectedKey={selectedKey}
                      domainId={datacenter?.id!}
                      setResultsLength={setresultsLength}
                      handleSearchSelection={handleSearchSelection}
                      searchValue={searchValue}
                      serverId={serverId}
                    />
                  </div>

                )
                }
                {mode === 'localTree' && server && (
                  <div className='space-y-5 pt-4'>
                    {/* <ResourcesTree
                      tree={resourcesTree}
                      handleLocationSelection={handleLocationSelection}
                      selectedKey={selectedKey}
                      openAction={openAction}
                    
                    /> */}
                    <TreeComponent
                    handleLocationSelection={handleLocationSelection}
                    selectedKey={selectedKey}
                    openAction={openAction}
                    serverId={serverId}
                    datacenter={datacenter}
                    tree={tree}
                    setResultsLength={setresultsLength}
                    server={server}
                  />
                  </div>
                )}
                
              </div>

            </Spin>
             <Link to={'/connect/signup/steps/1'} target='_blank' className=' w-full bar-h-mini px-4 flex items-center space-x-3 bg-hover-transparent !text-inherit '>
                  <Icon name='AddLocation' className='icon-sm'/>
                  <p className=''>Create your Qorddu  volume</p>
              </Link>
        

          </div>
        
        
        </div>
        {/* <div className="mr-[9px]">
          <button className='bar-h border-t border-main py-2 px-5 flex items-center border-r space-x-3 w-full'>
              <Icon name='LocalTree' className='icon-md'/>
              <p>Create a new volume</p>
          </button>

        </div> */}
        {partners && partners.length > 0 ? (
          <div className='flex space-x-3 px-4 py-2 border-t border-main'>
            <img src={partners[0].logo} className='w-[60px] h-[60px] rounded' alt="" />
            <div className="flex-1">
              <p className="">Partner</p>
              <p className='font-bold'>{partners[0].name}</p>
              <div className='flex space-x-1.5 '>
                <a className='p-1 rounded bg-hover-transparent !text-inherit' href={partners[0].webResources.website} target='_blank' rel="noopener noreferrer">
                  <Icon name='OpenWindow' className='icon-mini'/>
                </a>
                <button className='p-1 rounded bg-hover-transparent disabled:fill-skin-muted' disabled>
                  <Icon name='Chat' className='icon-mini'/>
                </button>
                <button className='p-1 rounded bg-hover-transparent disabled:fill-skin-muted' disabled>
                  <Icon name='Card' className='icon-mini'/>
                </button>
              </div>
            </div>
          </div>
        ) : <div className='h-[78px]'></div>} 
        {/* <StatsBar volumes={resultsLength} /> */}
        
      
    </div>
  )
}
