import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 21.0037H5.99748C4.89202 21.0037 3.99664 20.1084 3.99664 19.0029V4.99708C3.99664 3.89162 4.89202 2.99625 5.99748 2.99625H17.0021C18.1075 2.99625 19.0029 3.89162 19.0029 4.99708V8.99875"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 6.99792H5.49728"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 17.0021H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 12H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 3V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5333 19.5H14.4667C14.2089 19.5 14 19.2886 14 19.0278V18.6444C14.0031 17.5534 14.8763 16.6698 15.9544 16.6667H19.0456C20.1237 16.6698 20.9969 17.5534 21 18.6444V19.0278C21 19.2886 20.7911 19.5 20.5333 19.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7326 14.7777C16.7026 14.7777 15.8673 13.9336 15.8659 12.8914C15.8645 11.8492 16.6976 11.0028 17.7276 11C18.7576 10.9972 19.5951 11.8391 19.5992 12.8813C19.6012 13.3836 19.4054 13.8659 19.0551 14.2218C18.7049 14.5777 18.2289 14.7777 17.7326 14.7777Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
