import React from 'react'
import Icon from '../../../../components/icons/Icon'

type Props={
    handleSelect: (value:string) => void
}
export default function UserVolumesDropdown({ handleSelect } : Props) {
  return (
    <div className='relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter'>
    
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => handleSelect('folder')}>
            <div className='flex items-center'>
                <Icon name={'Directory'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Volume 1</p>
            </div>
        </button>
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => handleSelect('folder')}>
            <div className='flex items-center'>
                <Icon name={'Folder'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Volume 2</p>
            </div>
        </button>
    </div>
  )
}
