import React, { useEffect, useState } from 'react';
import { HighlightQuery, Query } from '../../../types';
import { searchQueries } from '../../../services/search/search';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';



export default function useGetQueries({ domainId, query }: { domainId: string; query: string }) {
  const [queries, setQueries] = useState<undefined | { document: Query; highlight: HighlightQuery }[]>(undefined);
  const [isLoaded, setIsLoaded] = useState(true);

  // Debounced Query
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      if (domainId && debouncedQuery) {
        setIsLoaded(false); // Indicate loading state
        const req = await fetch(searchDocuments('queries'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: searchQueries({ q: debouncedQuery, domainId }),
          }),
        });

        const res: any = await req.json();
        setQueries(res.data?.hits);
        setIsLoaded(true); // Loading complete
      } else {
        setQueries([]);
      }
    })();
  }, [debouncedQuery, domainId]);

  return {
    queries,
    isLoaded,
  };
}
