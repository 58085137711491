import { Spin } from 'antd'
import React from 'react'
import PartnerWithWebsite from '../../../../../components/shared/cards/partners/PartnerWithWebsite'
import OwnerWithWebsite from '../../../../../components/shared/cards/owner/OwnerWithWebsite'
import { isObject } from '../../../../../utils/utils'
import { Server, VolumeSearch } from '../../../../../types'



type Props ={
    loading:boolean,
    item: Server | VolumeSearch ,
    partners?:any,
    owner?:any,
    openEngine:() => void
}
export default function PopverLayout({loading, item, partners, owner, openEngine} : Props) {

  return (
    <div className='p-4 relative z-10'>
        <Spin spinning={loading}>
            <div className="space-y-3 min-h-[200px] min-w-[200px]">
                <p className="font-bold pl-1">{isObject(item.title) ? item.title.en : item.title} with:</p>
                <div className="">
                    {partners && partners.length>0 && (
                        <PartnerWithWebsite partner={partners[0]}/>
                    )}
                    {owner && (
                        <OwnerWithWebsite owner={owner}/>
                    )}
                    <div className="flex w-full justify-end">
                        <button className='px-4 py-1.5 rounded bg-skin-fill-inverted text-skin-inverted' onClick={openEngine}>Open server</button>

                    </div>
                </div>

            </div>

        </Spin>
    </div>
  )
}
