import React, { useState } from 'react'
import useGetPartners from '../../hooks/useGetPartners'
import { useParams } from 'react-router-dom'
import LocationLayout from '../../components/locationLayout'

export default function PartnersNetwork() {
    const params = useParams()
    const [loading, setloading] = useState(false)
    const { partners } = useGetPartners({id:params.id!, coll:'LocalClusters', setloading:setloading})

  return (
        <LocationLayout
        layoutProps={
          {
            loading,
            selectedKey: '/partners',
            disabled:{filter:true, new:true, display:true,query:true},
            publicationBar:false,
            id:params.id!,
            collection: 'LocalClusters'
          }
          
        }
        allowSearch={false}
        showApps={false}
        publications={undefined}
        sponsors={null}
        partners={null}
        availableLocations={null}
        suffix='partners/'
        mainSites={partners?.filter(part => !part.globale )}
        isLoaded={true}
        webResources={[]}
        
   />
  )
}
