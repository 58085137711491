import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ReactSVG } from 'react-svg';
import OneLine from '../../../../../components/shared/object/oneLine';
import ResourceMenu from '../../../../../components/shared/menu/ResourceMenu';
import Icon from '../../../../../components/icons/Icon';
import { iconType, Server } from '../../../../../types';








const mapTree = (tree:any[]) =>{
    return tree.map(loc => {
      if(loc.children) mapTree(loc.children)
        if(!loc.path){
            loc.path = loc.key
        }
        loc.key = loc.id
      return loc
    })
  }
type Props = {

    defaultExpandedKeys?:any[],
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    openAction : ({title, icon,key, id}:{title:string, icon:iconType, id:string, key:string}) => void,
    tree: any[] | null,
    updateTree?: ( data:Server) => void,
    
}


export default function TreeLayout({ handleLocationSelection, selectedKey, openAction, tree, updateTree}: Props) {

    const ref = useRef<any>()
    const [autoExpandParent, setautoExpandParent] = useState(true)
    const [expandedKeys, setexpandedKeys] = useState<any[]>(['0', '1', '2', '3', '4', '5', '/'])
    // const [height, setHeight] = useState(
    //     typeof window === "undefined" ? 0 : window.innerHeight
    // );
    // const updateDimensions = () => {
    //     if (typeof window !== "undefined") {
    //       setHeight(window.innerHeight);
    //     }
    // };


    // useEffect(() => {
    //     if (typeof window !== "undefined") {
    //         window.addEventListener("resize", updateDimensions);
    //         return () => window.removeEventListener("resize", updateDimensions);
    //     }
    //     }, [updateDimensions]);
 
  useEffect(() => {
    if (ref?.current && tree && tree.length > 0) {
      setTimeout(() => {
        ref?.current?.scrollTo({
          key: selectedKey,
          align: "auto",
          behavior: "smooth",
          offset: 100,
        });
      }, 200);
    }
  }, [selectedKey, ref?.current]);

  useEffect(() => {
    setautoExpandParent(true)
    setexpandedKeys(prev => ([...prev, selectedKey]))
  }, [selectedKey, tree])



  return (
    <>
  
            {tree && tree?.length > 0 && (

                  <Tree
                    ref={ref}
                    // selectedKeys={[location.state?.key]}
                        treeData={tree ? mapTree(tree) : []}
                        selectedKeys={[selectedKey]}
                        autoExpandParent={autoExpandParent}
                        expandedKeys={expandedKeys}
                        onExpand={(expandedKeys) => {
                            setexpandedKeys(expandedKeys)
                            setautoExpandParent(false);
                        }}
                        // height={
                        //     containerRef?.current
                        //     ? containerRef.current.clientHeight
                        //     : 900
                        // }
                            className='sidebar bg-transparent w-full'
                        loadData={async data => updateTree ? updateTree(data) : {}}
                        switcherIcon={({ expanded } : any) =>
                            expanded ? (
                            <ChevronDownIcon className="w-3.5 h-3.5" />
                            ) : (
                            <ChevronRightIcon className="w-3.5 h-3.5" />
                            )
                        }
                        titleRender={(location: any) => (
                                
                            <OneLine
                                noHover
                                draggable
                                onDragStart={e => e.dataTransfer.setData('item', JSON.stringify({title: location.title,icon: location.iconModule ? {src: location.iconModule , type:'icon'}:{src: location.iconUrl, type:'image'} ,id:location.id, docType:'volume'}))}
                                active={selectedKey===location.key}
                                dropdownProps={
                                    (location.depth === 0) ? null : (

                                        {
                                            overlay: <ResourceMenu
                                            id={location.id}
                                            onSuccess={() => {}}
                                            type='locations'
                                            items={{shortcut:true, open:{action: () => {
                                                openAction({key:location.path,id:location.id, title:location.title, icon:{src: location.iconUrl, type: 'image'}})
                                        }}}}
                                            
                                            extraItems={[
                                            {
                                                type:'divider'
                                            },
                                            {
                                                label:'Report',
                                                icon:{src:'FlagIcon', type:'icon'},
                                                key:'report',
                                    
                                            }
                                            ]}
                                        />
            
                                        }
                                    )
                                    
                                
                                }
                                className='!p-0 !bg-transparent hover:!bg-transparent transition-none '
                            >
                                <div className='flex justify-between items-center w-full'>
                                
                                    <div className={`flex space-x-2.5 items-center  ` }>
                                        

                                                <div className='relative w-[20px] h-[20px]'>
                                                    {location.iconType === 'icon' ? (
                                                        <Icon name={location.iconUrl} className='w-full h-full'/>
                                                    ) : (
                                                        <ReactSVG 
                                                        src={location.iconUrl} 
                                                        className='w-full h-full'
                                                        beforeInjection={(svg) => {
                                                            svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
                                                        }}
                                                        />

                                                    )}
                                                </div>
                                        
                                    
                                        <p className={`
                                        ${location.depth === 2 && 'max-w-[136px]'}
                                            ${location.depth === 3 && 'max-w-[145px]'}
                                            ${location.depth === 4 && (selectedKey === location.key ? 'max-w-[115px]' : 'max-w-[149px]')}
                                            ${location.depth === 5 && (selectedKey === location.key ? 'max-w-[84px]' : 'max-w-[117px]')}

                                        truncate`}>{location.title}</p>

                                    </div>
                                
                                </div>
                                

                            </OneLine>
                        

                    )}
                    
                    onSelect={(node,e) => {
                        handleLocationSelection(e.node)
                    }}
                    
                />
                
                )}

      
          
    
    </>
  )
}
