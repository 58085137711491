import { Modal } from 'antd'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import Icon from '../../../components/icons/Icon'
import { useAuthContext } from '../../../context/AuthContext'



type Props = {
    picture?:string,
    name?:string,
    bio?:string,
    position?:string,
    company?:string,
    adress?:{city:string, country: string},
    website?:string,
    linkedin?:string
}
function Card(props : Props) {
  return (
    <div className="flex h-[300px] items-center pl-[34px] pt-0 pr-[20px] pb-[20px]  relative rounded" style={{boxShadow:'0px 2px 11px 4px rgba(0,0,0,0.2)'}}>

                        
        <div className='w-[140px] h-[168px]  overflow-hidden '>
                {props.picture ? (
                <div className='border border-main rounded p-[2px]  h-full w-full '>
                    <img className={`w-full h-full object-cover rounded`} src={'props.picture'}/>
                    </div>

                ) : (
                    <Icon className='w-full h-full' name='UserIcon'/>
                )}

        </div>

        <div className='w-[200px] py-3 pr-1.5 leading-[19px] flex-1 pt-[15px] pl-[29px]'>
            
                <p className='font-bold'>{props.name}</p>
                {props.bio ? (
                    <p className={`text-muted ellipsis-6`}>
                        <span>{props.bio}</span>
                    </p>

                ) : (
                    <p className={`text-muted ellipsis-6 italic`}>
                        <span>Your Bio</span>
                    </p>
                )}
                {props.position ? (
                    <p className=" mt-2">{props.position} {props.company && `AT ${props.company}`}</p>

                ) : (
                    <p className=" mt-2 italic text-muted">Your position</p>

                )}
                <p className='mt-2'>{(props as any)['adress.city'] && `${(props as any)['adress.country']}, ${(props as any)['adress.city']}`}</p>
        </div>
        <div className='flex space-x-1.5 mt-2 absolute right-6 bottom-4'>
            {props.website && (
                <button className='p-1 rounded bg-hover-transparent' >
                    <Icon name='OpenWindow' className='icon-mini'/>
                </button>

            )}
        {props.linkedin && (
                <button className='p-1 rounded bg-hover-transparent' >
                    <Icon name='Linkedin' className='icon-mini'/>
                </button>

            )}
        </div>




    </div>
  )
}

export default function WelcomSplashScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { user } = useAuthContext()
  return (
    <Modal
        visible={!!searchParams.get('subscription')}
        onCancel={() => {
            searchParams.set('subscription', '')
            setSearchParams(searchParams)
        }}
        destroyOnClose
        footer={null}
        width={1130}
        closeIcon={
            <button className='rounded p-1 bg-white'>
                <Icon className='icon-sm' name='Close'/>
            </button>
        }
    >
        <div className='flex h-[700px] rounded-[15px] overflow-hidden'>
            <div className='flex-1 bg-sub-windows flex items-center h-full'>
                <div className='pl-[53px] max-w-[450px]'>
                <p className="font-bold text-[24px] uppercase">Welcom {user?.name}, <br/>Complete your business card</p>
                <p className='font-light uppercase text-[20px]'>
                 Welcome! Complete your business card details to create a professional impression and connect effortlessly with others. It's quick, easy, and sets you up for success. Let’s get started!







                </p>
                <div className="flex space-x-4 mt-7 text-[18px]">
                    <button className='px-5 py-2 rounded border border-main bg-hover-transparent w-[120px] flex justify-center items-center' onClick={() => {
                    // startTutorial()
                    }}>
                    Skip
                    </button>
                    <button className='px-5 py-2 rounded bg-skin-fill-inverted text-skin-inverted w-[120px] flex justify-center items-center'>
                    Edit
                    </button>
                </div>
                </div>
            </div>
            <div className='w-1/2 h-full bg-skin-fill-inverted-main flex justify-center items-center'>
                <div className="w-[450px] rounded bg-sub-windows">
                <Card
                    name={'Your name'}
                    adress={{city: 'Your city', country: 'Your country'}}
                    position={'Your position'}
                    company={'Your comapny'}
                    bio={'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex dolor voluptatibus eveniet voluptate provident reprehenderit, officiis ipsa ullam dolorum enim inventore quisquam neque, rem nostrum similique blanditiis? Placeat, voluptatum optio!'}


                />

                </div>

            </div>
      
        </div>
    </Modal>
  )
}
