
import React, { act, useEffect, useRef, useState } from 'react'



import { Form, Input, Select } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { usePublicationCreationContext } from '../../../context/PublicationCreationContext';
import Header from './form/pageViewer/Header';
import PageViewer from './form/pageViewer';
import EditForm from './form/EditForm';
import UrlLists from './form/urlsList';

export default function EditPublication() {

    const [errors, seterrors] = useState<any>({})
    const [form] = Form.useForm()
    const { setEditData, setActive, editData, active } = usePublicationCreationContext()
    const navigate = useNavigate()
    const elRef = useRef<any>(null)
    const [url, seturl] = useState('')
    const [inputValue, setinputValue] = useState('')
      const [navigationActive, setnavigationActive] = useState({prev: false, next:false})
   useEffect(() => {
        setActive({edit:true})
    
    }, []);
      
   
      
      const handleInputChange = (e:any) => {
       setinputValue(e.target.value)
      }
      const handleInputClick = (e:any) => {
       if(e.key === 'Enter'){
           seturl(inputValue)
           form.setFieldValue('url', inputValue)
       }
      }
   const handleNext = () => {
    navigate(`/mydesk/publications/create/publish`)
   }
   console.log(url)
   const handleValuesChange = (changedValues:any,allValues:any) => {
    const { title, keywords, description, url, publicationType} = allValues
    setEditData(allValues)
    seterrors((prev:any) => {
        const state = {...prev}
        state[Object.keys(changedValues)[0]] = undefined
        return state
    })
    if(title && keywords && description && url && publicationType){
        seterrors({})
        setActive({publish:true})
    }else{
        setActive({publish:false})
    }
   }

 
  
  return ( 
    <>
  
        <Form 
            className='h-full flex '
            onValuesChange={handleValuesChange}
            initialValues={editData}
            form={form}
            
                >
                    <div className="w-[310px] border-r border-main">
                        <UrlLists/>
                    </div>
                    <div className="flex-1">
                        <Header elRef={elRef} inputValue={inputValue} navigationActive={navigationActive} handleInputChange={handleInputChange} handleInputClick={handleInputClick} />
                        <div className='flex h-full flex-1 bg-sub-windows' >
                            
                            <div className='flex-1 h-full'>
                            
                                <PageViewer url={url ? url : editData?.url} elRef={elRef} setinputValue={setinputValue} setnavigationActive={setnavigationActive}   />
                            </div>
                            <div className="w-[50%] bg-sub-windows height-with-2bars overflow-auto pb-4">
                                <div className="pt-5 " >
                                    <EditForm errors={errors} seterrors={seterrors} edit={false} url={url} initDescription={editData?.description} />
            
                                </div>
                            </div>
                        </div>

                    </div>
            </Form>

 
    
    </>
    
                    
    

  )
}

