import { SearchIcon } from '@heroicons/react/solid'
import { Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import TreeCompenent from './Tree'

import WithDivider from '../../../../../components/shared/WithDivider'
import Icon from '../../../../../components/icons/Icon'
import { LngLatLike } from 'mapbox-gl'
import { Server, VolumeSearch } from '../../../../../types'
import { Close } from '../../../../../components/icons'
import Results from '../../../../Qfs/components/sidebar/search/results'
import useSearchLocations from '../../../../Qfs/hooks/useSearchLocations'
import { Link } from 'react-router-dom'

type Props = {
    setselectedVolume: (volume:(Server & {map:LngLatLike, type:'tree'}) | (VolumeSearch & {map:LngLatLike, type: 'search'}) | undefined) => void, 
    tree:any,
    settree:any,
    domainId?:string,
    searchValue:string,
    setsearchValue:(value:string) => void,
    dispalyedValue:string,
    onClear:() => void

}
export default function DropdownOverley({ setselectedVolume, dispalyedValue, tree, settree, domainId, searchValue, setsearchValue, onClear } : Props) {
    const [query, setquery] = useState('')
    const {searchResults, resultsLength} = useSearchLocations({
        query, 
        sort: 'depth:asc,created_at:desc',
        filter: `&& domainsIds:=${domainId}`,
        status:'[enabled, coming_soon]'
        
    })

    useEffect(() => {
    
        if(searchValue.length > 2){
            setquery(searchValue)
        }else{
            setquery('')
        }
    }, [searchValue]);

    const handleServerSelection = (node:any, type:'tree' | 'search')    => {
        if(type === 'tree'){
            setselectedVolume({...node, map: node.map ? [ node.map.long,node.map.lat] : undefined, type, coll: node.coll??'LocalClusters'})

        }else{
            setselectedVolume({...node, map: node.map ? node.map.reverse() : undefined, type})
        }
    }
  return (
    <div className='bg-white w-full h-[600px] border rounded-[15px] overflow-hidden -top-[42px] shadow-lg border-gray-300 relative'
        
    >
        <div className="flex items-center px-4 border-b border-gray-300">
            <SearchIcon className='icon-mini'/>
            <input type="text" className='border-none flex-1 w-full' placeholder='Find a volume' value={dispalyedValue} onChange={e => setsearchValue(e.target.value)}  autoFocus/>
            <button className='flex space-x-1 items-centerabsolute right-1 top-3 bg-gray-300 rounded-full px-2 py-1' onClick={onClear}>
                <p className='text-[9px]'>clear</p>
                <Close className='icon-mini'/>
            </button>
        </div>
        <div className=' w-full'>
            {searchValue.length <3 ? (
                <div className='pl-3 pt-4 h-[calc(600px-71px)] overflow-auto'>
                    <TreeCompenent settree={settree}  handleServerSelection={(node) => handleServerSelection(node, 'tree')} tree={tree}/>

                </div>
            ) : (
                <div>
                    <div className="flex space-x-5 items-center bar-h-mini border-b border-gray-300">
                        <div className="icon-sm">
                        
                        </div>
                        <div className="flex-1">
                            <WithDivider>
                                <>Volumes ({resultsLength})</>
                            </WithDivider>
                        </div>
                    </div>
                    <div className="pl-0.5 h-[calc(600px-103px)] overflow-auto border-b border-gray-300">
                        <Results
                            selectedKey={''}
                            handleServerSelection={(node) => handleServerSelection(node, 'search')}
                            results={searchResults}
                        
                        /> 

                    </div>
                </div>

            )}
             <Link to={'/connect/signup/steps/1'} target='_blank' className=' w-full bar-h-mini px-4 flex items-center space-x-3 bg-hover-transparent !text-inherit '>
                <Icon name='AddLocation' className='icon-sm'/>
                <p className=''>Create your Qorddu  volume</p>
            </Link>
            {/*  */}
        </div>

    </div>
  )
}
