import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 9.99316L13.128 12.2762C12.445 12.7382 11.549 12.7382 10.867 12.2752L7.5 9.99316"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.94678C5.039 5.41978 3 8.46778 3 11.9998C3 16.9708 7.029 20.9998 12 20.9998L11 18.9998"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 5L12 3C16.971 3 21 7.029 21 12C21 15.531 18.961 18.579 16 20.053"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.15 15.375H8.85C8.104 15.375 7.5 14.771 7.5 14.025V9.975C7.5 9.229 8.104 8.625 8.85 8.625H15.15C15.896 8.625 16.5 9.229 16.5 9.975V14.025C16.5 14.771 15.896 15.375 15.15 15.375V15.375Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
