import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactDOM from "react-dom/client";
import Marker from './marker';
import { Server, VolumeSearch } from '../../../types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;
const center:any = [-0.481747846041145, 51.3233379650232]
type Props = {
  selectedGeoPoint: (Server & {map:LngLatLike, type: 'tree'}) | (VolumeSearch & {map:LngLatLike, type: 'search'}) | undefined;
  geoPoints: any[];
  domain:Server|null
};

const MapComponent = React.forwardRef(({ geoPoints, selectedGeoPoint, domain}:Props, ref ) =>{
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null); 
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const [selectedVolume, setselectedVolume] = useState<string | undefined>('');
  useEffect(() => {
    if (!mapRef.current) {
      const options:any = {
        container: mapContainerRef.current!,
        style: 'mapbox://styles/rania16/clnob3zte00aq01pl4yzsabbb',
        // style: 'mapbox://styles/rania16/cls057r1y00vo01pe4xb5ck7t'
        center,
        zoom: 3,
        renderWorldCopies: false,
        // pitch: 50,
       
      }
      mapRef.current = new mapboxgl.Map(options);

      mapRef.current.on('style.load', () => {
      

        // Adjust view with padding
        mapRef.current?.easeTo({
          padding: {
            top: 0,
            left: 800,
            right: 0, // Add padding to push the globe left
            bottom: 0, // Add padding to push the globe upward
          },
        });
      });
    
    }

    return () => mapRef.current?.remove(); 
  }, []);


  useEffect(() => {
    if (mapRef.current && selectedGeoPoint && selectedGeoPoint.map) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      const elm = document.createElement('div');
      const root = ReactDOM.createRoot(elm);
      root.render(<Marker selectedVolume={selectedVolume} setselectedVolume={setselectedVolume} domain={domain} item={selectedGeoPoint} />);

      const selectedMarker = new mapboxgl.Marker(elm)
        .setLngLat(selectedGeoPoint.map)
        .addTo(mapRef.current!);
        
      setselectedVolume(selectedGeoPoint.id)
      markersRef.current.push(selectedMarker);
      mapRef.current.flyTo({
        center: selectedGeoPoint.map,
        zoom: 4,
        speed: 1.5,
        curve: 1,
        easing: (t: any) => t,
        essential: true,
      });
    }
    if (mapRef.current && (!selectedGeoPoint || !selectedGeoPoint.map)) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];


      mapRef.current.flyTo({
        center,
        zoom: 3,
        speed: 1.5,
        curve: 1,
        easing: (t: any) => t,
        essential: true,
      })
      geoPoints.forEach((point: any) => {
        const elm = document.createElement('div');
        const root = ReactDOM.createRoot(elm);
        root.render(<Marker selectedVolume={selectedVolume} setselectedVolume={setselectedVolume} domain={domain} item={point} />);

        const marker = new mapboxgl.Marker(elm)
          .setLngLat([point.map[1], point.map[0]])
          .addTo(mapRef.current!);

        markersRef.current.push(marker);
      });

      return () => markersRef.current.forEach((marker) => marker.remove());
    }
  }, [geoPoints, selectedGeoPoint]);
  
  // Expose zoom methods to the parent component
  useImperativeHandle(ref, () => ({
    zoomIn: () => {
      mapRef.current?.zoomIn({ duration: 500 });
    },
    zoomOut: () => {
      mapRef.current?.zoomOut({ duration: 500 });
    },
  }));
  return (
    <div className='w-screen flex justify-end overflow-hidden'>
      <div ref={mapContainerRef} style={{ height: '100vh', width: '100vw' , overflow: 'hidden', position: 'relative' }} />
    </div>
  );
})
export default MapComponent;