import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 21.0037H5.99748C4.89202 21.0037 3.99664 20.1084 3.99664 19.0029V4.99708C3.99664 3.89162 4.89202 2.99625 5.99748 2.99625H17.0021C18.1075 2.99625 19.0029 3.89162 19.0029 4.99708V8.99875"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 6.99792H5.49728"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 17.0021H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 12H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 3V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5107 17.6783L15.7653 19.5343"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2404 11.6429L16.1383 11.8971C16.0537 12.1078 16.0432 12.3406 16.1086 12.5587L16.4697 13.7555L16.097 14.6834L14.7929 15.0326C14.4886 15.1141 14.241 15.3336 14.1236 15.6259L13.7453 16.5678L19.2769 18.7893L19.6552 17.8474C19.7726 17.5551 19.7456 17.2252 19.5821 16.9559L18.8818 15.8018L19.2545 14.8738L20.3429 14.2592C20.5397 14.1475 20.6936 13.9711 20.7782 13.7605L20.8803 13.5062C20.9831 13.2501 20.8587 12.9588 20.6026 12.8559L16.8908 11.3652C16.6346 11.2624 16.3433 11.3867 16.2404 11.6429Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
