import React, { useEffect, useState } from 'react'
import Icon from '../../../../components/icons/Icon'
import { PublicationTypes } from '../../../../types';
import ReactSvg from '../../../../components/shared/ReactSvg';
import { storageUrl } from '../../../../services/api/constants';


type Props = {
    publicationType:any,
}
export default function NewDropdown({ publicationType }: Props) {
    const [typesList, settypesList] = useState<any>([]);

    useEffect(() => {
        if(publicationType){

            settypesList(
    
                publicationType?.filter((type:any) => type.id === 'popular')?.[0]?.children
            )
        }
    }, [publicationType]);
  return (
    <div className="relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter" >
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'PinBinder'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Pin binder</p>
            </div>
        </button>

        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'SharedBinder'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Shared biner</p>
            </div>
        </button>
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'ExpertBinder'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Expert biner</p>
            </div>
        </button>

  
        <div className='w-full bg-skin-fill-muted h-[1px] my-1'></div>

        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'Directory'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Directory</p>
            </div>
        </button>
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'Folder'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Folder</p>
            </div>
        </button>
        <div className='w-full bg-skin-fill-muted h-[1px] my-1'></div>
        {typesList && typesList.map((type:PublicationTypes) => (
            <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <ReactSvg src={`${storageUrl}${type.icon.src}`} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>{type.name_singular.en}</p>
            </div>
        </button>
        ))}
 
    
  

</div>
  )
}
