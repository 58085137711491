import { Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getPartner } from '../../../../services/api/request/partners'


export default function PartnerDetailsUrl() {
    const location = useLocation()
    const [website, setwebsite] = useState<null | string>(null)
    const [loading, setloading] = useState(false)
    const params = useParams()
    
    useEffect(() => {
     if(location.state?.url){
        setwebsite(location.state.url)
     }else{
        if(params.partnerId){
            setloading(true)
            fetch(getPartner({id:params.partnerId})).then(res => res.json()).then(data => setwebsite(data.data?.website)).finally(() => setloading(false))
        }
     }
    }, [location.state, params.partnerId])
    

    return (
    <div className='h-full w-full'>
        <Spin spinning={loading}>
            {/* {website && (

                <WebviewUrl partition='partnerdatails' zoom={0.4} url={website} />
            )} */}
            <iframe src={website!} className='w-full h-full'></iframe>
        </Spin>
    
    </div>
  )
}
