import { PlayIcon } from '@heroicons/react/outline'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'

type Props ={
    open: boolean,
    onClose: () => void,
    title:string
}
export default function VideoBull({ open, onClose, title}: Props) {
   
    
  return (
    <Modal
        footer={null}
        visible={open}
        onCancel={onClose}
        destroyOnClose
        width={800}
        closable={false}
    >
        <div className='bg-white rounded p-5 space-y-3'>
            <div className='w-full h-[450px] border border-gray-300 rounded flex justify-center items-center'>
                <PlayIcon className='w-[100px] h-[100px] text-gray-300 fill-transparent'/>
            </div>
            <div className='flex justify-between items-center'>
                <p className="font-bold text-[22px]">{title}</p>
                <button className='w-[100px] text-center py-1.5 border border-black rounded' onClick={onClose}>Close</button>
               
            </div>
        </div>

    </Modal>
  )
}
