import { Form, Modal, Spin } from 'antd';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../../../utils/firebase';
import { useAuthContext } from '../../../context/AuthContext';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../utils/firebase';

export default function Login() {
    const [open, setopen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>(null);
    const [Status, setStatus] = useState(false);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const { user} = useAuthContext()
    const currentQueryParams = location.search;
    const submitForm = (event:any) => {
        event.preventDefault();
        setloading(true)
        setErrors(null)
        signInWithEmailAndPassword(auth, email,password).then(async userCredentials => {
            if(searchParams.get('EmailValidated') && searchParams.get('accountType')){
                if(searchParams.get('accountType') === 'INDIVIDUAL'){
                    navigate(`/connect/signup/steps/6/individual${currentQueryParams}`)
                }else{
                    navigate(`/connect/signup/steps/6/organization${currentQueryParams}`)
                }
            }else{
                navigate('/')

            }
      
          }
            ).catch(err => setErrors('Invalid credentials')).finally(() => setloading(false))
        
      };
    useEffect(() => {
      setopen(true)
    }, [])
    useEffect(() => {
        
        if(searchParams.get('EmailValidated') && searchParams.get('accountType') && user){
            if(searchParams.get('accountType') === 'INDIVIDUAL'){
                navigate(`/connect/signup/steps/4/individual${currentQueryParams}`)
            }else{
                navigate(`/connect/signup/steps/4/organization${currentQueryParams}`)
            }
        }
    }, [user]);
    
  return (
                      
            <Form className="flex-1 w-full">
                <div className="space-y-3 flex flex-col items-end w-full">
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                        
                    >
                        <input
                        type="email"
                        placeholder={'Email'}
                        className={`rounded-lg py-2 px-3 block max-w-[70%] w-full h-[50px] !text-[18px] placeholder:text-[18px] `}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                    >
                        <input
                        type="password"
                        placeholder={'Password'}
                        className="rounded-lg py-2 px-3 block max-w-[70%] w-full h-[50px] !text-[18px] placeholder:text-[18px] "
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>

                </div>
                <div className='w-full flex justify-end mt-2.5 max-w-[70%]'>
                    <p className='text-[14px] text-skin-base'>Forget password?</p>
                </div>
                <Spin spinning={loading}>
                    <div className='max-w-[70%]'>
                        <button
                        className="px-10 mt-4  text-white rounded-lg py-1.4 w-max block ml-auto disabled:text-teddra-gray-200 h-[50px] text-[18px] "
                        type="submit"
                        disabled={loading}
                        onClick={submitForm}
                        style={{background: '#000'}}
                        >
                        {'Login'}
                        </button>

                    </div>
                </Spin>
            </Form>

                             
               
                
         
     
        
  )
}
