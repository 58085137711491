import { ChevronDownIcon, ChevronRightIcon, SearchIcon } from '@heroicons/react/solid'
import { Dropdown, Spin, TreeSelect } from 'antd'
import { LngLatLike, Point } from 'mapbox-gl'
import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import DropdownOverley from './DropdownOverlay'

import { Link, useLocation } from 'react-router-dom'
import { Server, VolumeSearch } from '../../../../types'
import { useAppContext } from '../../../../context/AppContext'
import { Close } from '../../../../components/icons'



export const mapTreeIcon = (tree:any[]) => {
    return tree.map(item => {
        item.icon = <ReactSVG src={item.iconUrl} className='icon-sm' />
        item.value = item.title
        item.key = item.title

        if(item.children) mapTreeIcon(item.children)
        return item
    })
}
type Props = {
    setselectedVolume: (volume:(Server & {map:LngLatLike, type: 'tree'}) | (VolumeSearch & {map:LngLatLike, type: 'search'}) | undefined) => void,
    tree:any,
    settree:any,
    selectedVolume:(Server & {map:LngLatLike, type: 'tree'}) | (VolumeSearch & {map:LngLatLike, type: 'search'}) | undefined

}
export default function MapSearch({ tree, setselectedVolume, settree, selectedVolume } : Props) {
   const [searchValue, setsearchValue] = useState('');
    const [displayedValue, setdisplayedValue] = useState('');
    const { domain } = useAppContext()
   useEffect(() => {
    
        if(selectedVolume){
            const title = selectedVolume.type === 'search' ? selectedVolume.title.en : selectedVolume.title
            setdisplayedValue(title)
        }
   }, [selectedVolume?.id]);
   useEffect(() => {    
       setdisplayedValue(searchValue)
    }, [searchValue]);
    const onClear = () => {
        setsearchValue('')
        setdisplayedValue('')
        setselectedVolume(undefined)
    }
  return (
    <div className="flex w-[500px] space-x-3 items-center">
        <div className="flex-1">
            <Dropdown
                overlay={<DropdownOverley
                    settree={settree}
                    tree={tree}
                    setselectedVolume={setselectedVolume}
                    domainId={domain?.id}
                    searchValue={searchValue}
                    setsearchValue={setsearchValue}
                    dispalyedValue={displayedValue}
                    onClear={onClear}
                    
                />}
                trigger={['click']}
            >
                <div>
                    <div className="flex flex-1  items-center relative  border border-gray-300 rounded-[13px] overflow-hidden px-4" >
                        <SearchIcon className='icon-mini'/>
                        <input type="text" className='border-none flex-1 w-full' placeholder='Find a volume' value={displayedValue} onChange={e => setsearchValue(e.target.value)} />
                        <button className='flex space-x-1 items-centerabsolute right-1 top-3 bg-gray-300 rounded-full px-2 py-1' onClick={onClear}>
                            <p className='text-[9px]'>clear</p>
                            <Close className='icon-mini'/>
                        </button>
                    </div>
                

                </div>

            </Dropdown>

        </div>
        {/* <Link to={`/connect/signup/steps/1`} className='!text-inherit px-4 input-h flex items-center border border-gray-300 rounded-[13px]' target='_blank'>Create a volume</Link> */}
    </div>
  )
}
