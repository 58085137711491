import React from 'react'
import ObjectMd from '../ObjectMd'
import Arrows from '../Arrows'
import { iconType } from '../../../../types'
import ObjectSm from '../objectSm'
import PieChart from './PieChart'


const profiles:{icon:iconType, title:string, description:string}[] = [
    {
        icon: {type:'image', src:'https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fnode_server.svg?alt=media&token=0da0dfda-fbdf-4785-909f-4c9241905316'},
        title: 'Servers',
        description: '80'
    },
    {
        icon: {type:'image', src:'https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fdedicated_drive.svg?alt=media&token=247103b3-f400-4fe3-84b4-645ccc0331d6'},
        title: 'Drives',
        description: '120'
    },
    {
        icon: {type:'image', src:'https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fdedicated_directory.svg?alt=media&token=988e7a2f-fef3-4163-83ee-3f602be2d85a'},
        title: 'Directories',
        description: '165'
    },
    {
        icon: {type:'image', src:'https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fdedicated_folder.svg?alt=media&token=2aec337f-0d10-4d7b-9bcf-f4b2b7e3c608'},
        title: 'Folders',
        description: '350'
    }
    
]
export default function Subscriptions() {
  return (
    <div className='flex space-x-16'>
      <div className='space-y-12'>
        <div className="flex space-x-16">
            <ObjectMd
                title={'20K'}
                icon={{type:'icon', src: 'Subscribers'}}
                description={'Total subscribers'}
                label={''}
            />
            <ObjectMd
                title={'3047'}
                icon={{type:'icon', src: 'NewSubscribers'}}
                description={'New subscribers'}
                label={''}
            />
            <Arrows
                title='18%'
                description='Growth rate'
                direction='up'
            />

        </div>
        <div className="grid w-max gap-6 grid-cols-4 pl-2">
           
            {profiles.map(v=> (
                <ObjectSm
                    {...v}
                    key={v.title}
                />
            ))}
       
         
        </div>
     </div>
     <div className="flex-1">
        <PieChart/>
     </div>
    </div>
  )
}
