import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6638 17.071L10.9225 16.3296C10.3112 15.7187 9.97802 14.8834 10.0011 14.0194C10.0242 13.1555 10.4015 12.3391 11.0445 11.7617C12.3624 10.6593 14.3087 10.7651 15.4994 12.0038L16.1146 12.6201"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3406 14.846L19.0819 15.5873C19.6932 16.1982 20.0264 17.0336 20.0033 17.8975C19.9802 18.7614 19.6029 19.5778 18.9599 20.1552C17.6419 21.2579 15.6952 21.1517 14.505 19.9121L13.8898 19.2969"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1484 14.1037L16.856 17.8123"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11.5V5C20 3.895 19.105 3 18 3H6C4.895 3 4 3.895 4 5L4 19C4 20.105 4.895 21 6 21H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
