import React, { useEffect, useState } from 'react'
import Icon from '../../../../components/icons/Icon'




export default function StatusDropdown() {
   
  return (
    <div className="relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter" >
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'Document'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Published</p>
            </div>
        </button>

        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'Draft'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Draft</p>
            </div>
        </button>
        <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <Icon name={'Trash'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Trash</p>
            </div>
        </button>

  
        
 
    
  

</div>
  )
}
