// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {  connectStorageEmulator, getStorage } from 'firebase/storage'

 
const firebaseConfig = {

  apiKey: process.env.REACT_APP_FIREBASE_KEY,

  authDomain: process.env.REACT_APP_FIREBASE_AUTH,

  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  appId: process.env.REACT_APP_FIREBASE_APPID,

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const auth = getAuth(app)

// if((window as any).envVars.MODE === 'dev'){
  // connectFirestoreEmulator(db, '127.0.0.1', 8080);
  // connectStorageEmulator(storage, '127.0.0.1',9199 )
  // connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })
// }



