import { createContext, useContext, useMemo, useState } from "react";
import { Server } from "../types";


type States = {
    domain: Server | null,
    server: Server | null,


}
type Functions = {
    setDomain:(data:Server | null) => void,
    setServer:(data:Server | null) => void,



}
type Props = States & Functions
const AppContext = createContext<Props>({} as Props);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    domain:null,
    server:null,
   
  })
  

  const value = useMemo(() => {
    
    const setDomain =(data:Server | null) => {
        setState((prev) => ({
          ...prev,
          domain:data
        }))
      }

      const setServer =(data:Server | null) => {
        setState((prev) => ({
          ...prev,
          server:data
        }))
      }
   
    return {
      ...state,
      setDomain,
      setServer

     
    }
  }, [state])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};