// import { getAuth, sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import { Form, Input, Modal, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
// import 'react-phone-input-2/lib/style.css'

import StepsButtons from "../../../components/stepsButtons";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { signup } from "../../../../../services/api/request/user";
import { auth } from "../../../../../utils/firebase";
import { getAuth, sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import { useAuthContext } from "../../../../../context/AuthContext";
import { useSignupContext } from "../../../context/SignupContext";
import { useNavigate } from "react-router-dom";



type Props = {
    nextStep:() => void,
    prevStep : () => void,
    profileName:string,
    url:string

}
export const emailUrl = (profileId:string, profileName:string, planId:string, accountType:any) => `https://teddra-email-verification.vercel.app/verifyEmail/?planId=${profileId}&tierId=${planId}&profile=${profileName}&type=${accountType}`
export default function CreateAccount() {
  const {user} = useAuthContext()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
        title: '',
        text: '',
        showMessage: false, 
        icon: ''
    });
  const [errors, setErrors] = useState<{message:string, errors:any}>({errors:null, message:''});
  const { profile, planId, accountType, setCurrentStep } = useSignupContext()
  const navigate = useNavigate()
  
  useEffect(() => {
    
    setCurrentStep(4)
  }, []);
  const preFill = () => { 
  
      form.setFieldsValue({
        name: 'Maamar Bouheraoua',
        email: 'maamarbouheraoua@yahoo.com',
        password: '12345678',
        confirmPassword: '12345678'
      })
    }
  const submit = async () => {
    console.log('submit')
    
    try{
      const { email, password, confirmPassword, name } = await form.validateFields();
      console.log(email, password, confirmPassword, name)
    
      if (password !== confirmPassword) {
        setErrors({errors: { password: ["Passwords do not match"] }, message: 'Validation error'});
        return;
      }
      setLoading(true)
      setErrors({message:'', errors:null})
      const req = await fetch(signup, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          method: 'POST',
          body:JSON.stringify({displayName:name, email, password, passwordConfirmation: confirmPassword})
      })
      const res = await req.json()
      if(res.success){
          await signInWithCustomToken(auth, res.token)
          const authUser = getAuth().currentUser
          if(authUser){
            const actionCodeSettings = {
              url: emailUrl(profile.id, profile.name, planId, accountType),
              handleCodeInApp: false, // Whether the link should be handled in the web app
            };
            sendEmailVerification(authUser, actionCodeSettings).then(() => {
              // setMessage({
              //   title: 'Verify Email',
              //   text: 'Check your email to activate your account',
              //   showMessage: true,
              //   icon: 'EmailSent'
              // }); 
              nextStep()
    
            }).catch(() => {
              notification.open({
                  message: `Something went very wrong`,
                  type:'error'
                })
          }) 
          }
          nextStep()
  
      }else{
          setErrors({errors:res.error?.errors, message: res.message})
      
  
      }

    }catch(err){
      console.log(err)
    }finally{
      setLoading(false)

    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  const nextStep = () => {
    navigate('/connect/signup/steps/5')
  }
  return (
    <div className="space-y-2 h-full flex flex-col relative">
        <div className="relative overflow-hidden flex-1 flex flex-col justify-center space-y-[100px]">
          
          <div className='w-full flex flex-col items-center'>
            {/* <p className="label-level2">{profile?.name}</p> */}
            <p className="title-level2 text-center">Create your account
            </p>
            <p className="text-level2 text-center max-w-[400px]">Create your login and password</p>

          </div>
          <div className="flex items-center justify-center w-full">
             
              <Form 
                className="space-y-3 flex-1 max-w-[300px] w-full " 
                onChange={() => setErrors({errors:null, message: ''})}
                form={form}
                onFinishFailed={onFinishFailed}
                onFinish={submit}
                onValuesChange={() => setErrors({errors:null, message: ''})}
                initialValues={!user ? undefined : {
                  name: user.name,
                  email: user.email
                }}
              >
                
                  <Form.Item
                    // validateStatus={errors?.name?.length > 0 ? "error" : ''}
                    // help={errors?.name?.length > 0 && errors?.name[0]}
                    className="group flex-1"
                    style={{ marginBottom: "0" }}
                    name={'name'}
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Full name" className=" border !border-gray-500" />
                    {/* <input
                      type="text"
                      placeholder="Name"
                      className="rounded py-2 px-3 block w-full border border-main"
                    /> */}
                  </Form.Item>
                  <Form.Item
                    // validateStatus={errors?.email?.length > 0 ? "error" : ''}
                    help={errors.errors?.email?.length > 0 && errors.errors?.email[0]}
                    className="group flex-1"
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }, {  type: 'email', message: 'Email not valid' }]}
                  >
                  
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      type="email"
                      placeholder="Email"
                        className=" border !border-gray-500"
                    />
                  </Form.Item>
                  <Form.Item
                    // validateStatus={errors?.password?.length > 0 ? "error" : ''}
                    help={errors.errors?.password?.length > 0 && errors.errors?.password[0]}
                    className="group flex-1"
                    name="password"
                    rules={[{ required: true, message: 'Password is required' }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Password"
                        className=" border !border-gray-500"
                    />
                  </Form.Item>
                  <Form.Item
                    // validateStatus={errors?.password?.length > 0 ? "error" : ''}
                    help={errors.errors?.passwordConfirmation?.length > 0 && errors.errors?.passwordConfirmation[0]}
                    className="group flex-1"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Please confirm your password' }]}
                  >
                
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Confirm password"
                      aria-label="Confirm Password"
                        className=" border !border-gray-500"
                    />
                  </Form.Item>
                  {errors?.message && <p className="text-red-600">{errors.message}</p>}
                  {/* <Form.Item>
                    <button type="submit" disabled={loading || !!user?.id} className="px-4 py-2 rounded bg-skin-fill-inverted text-skin-inverted disabled:opacity-20">Create Account</button>
                  </Form.Item> */}
            

              
                
              </Form>


          </div>
          <StepsButtons
                nextStep={submit}
                prevStep={() => navigate('/connect/signup/steps/3')}
                // disable={!data?.name || !data.raisonSocial || !data.duns || !data.activity || !data.activityCode || !data.webResources?.website || !data.address || !data.address.city || !data.address.country || !data.address.zipcode || !data.address.line1 || !data.email}
                disable={loading}
                submit
            />

        </div>
        <button className="absolute bottom-5 right-5" onClick={preFill}>Pre-fill</button>
      {/* <NotificationModal visible={message.showMessage} onClose={onClose} text={message.text} icon={message.icon} title={message.title} action={{title: 'Resend email', handler: resendEmail}}/> */}
    </div>
  );
}