import React, { useEffect } from 'react'
import StepsButtons from '../../../components/stepsButtons'
import { useSignupContext } from '../../../context/SignupContext'
import { useNavigate } from 'react-router-dom'

type Props = {
    nextStep:() => void,
}
export default function Introduction() {
    const { setprofile, profile, setCurrentStep } = useSignupContext()
    const navigate = useNavigate()
    useEffect(() => {
        
          setCurrentStep(1)
        }, []);
  return (
    <div className='space-y-2 h-full flex flex-col relative'>
        <div className="relative overflow-hidden flex-1 flex flex-col justify-center space-y-[100px]">
            <div className='flex items-center justify-center w-full space-x-[150px]'>
                <div className='flex-1 max-w-[500px] space-y-8'>
                    <div>
                        <p className="title-level2 ">Lorem ipsum dolor</p>
                        <p className="text-level2  ">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. At similique    
                        </p>

                    </div>
                    <div className="grid grid-cols-3 gap-x-10">
                        <div>
                            <p className=' text-[16px]'>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                            </p>
                        </div>
                        <div>
                            <p className=' text-[16px]'>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                            </p>
                        </div>
                        <div>
                            <p className=' text-[16px]'>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-[600px] h-[600px] border border-gray-400 rounded"></div>
            </div>
            <StepsButtons
                nextStep={() => navigate('/connect/signup/steps/2')}
                disable={false}
                
            />
        </div>
    </div>
  )
}
