import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ConnectLayout from './Layout'
import Login from './pages/Login'
import LoginLayout from './components/LoginLayout'
import Home from './pages'
import Subscribe from './pages/signup'
import { SignupContextProvider } from './context/SignupContext'
import Profile from './pages/signup/steps/profile'
import PlansCom from './pages/signup/steps/plans'
import CreateAccount from './pages/signup/steps/createAccount'
import OrganizationPage from './pages/signup/steps/organization'
import SubscribePage from './pages/signup/steps/subscribe'
import Pro from './pages/signup/steps/Pro'
import Vlidate from './pages/signup/steps/Vlidate'
import Introduction from './pages/signup/steps/Introduction'
import Terms from './pages/signup/steps/Terms'

export default function LoginRoutings() {
  return (
    <Routes>
        <Route element={<ConnectLayout/>}>


            <Route element={<SignupContextProvider><Subscribe/></SignupContextProvider>}>
              <Route element={<Introduction/>} path='/signup/steps/1'/>
              <Route element={<Profile/>} path='/signup/steps/2'/>
              <Route element={<PlansCom/>} path='/signup/steps/3'/>

              <Route element={<CreateAccount/>} path='/signup/steps/4'/>
              <Route element={<Vlidate/>} path='/signup/steps/5'/>
              {/* <Route element={<PrivateRoute/>}> */}
                <Route element={<OrganizationPage/>} path='/signup/steps/6/organization'/>
                <Route element={<Pro/>} path='/signup/steps/6/individual'/>

                <Route element={<Terms/>} path='/signup/steps/7'/>
                <Route element={<SubscribePage/>} path='/signup/steps/8'/>

              {/* </Route> */}



            </Route>

            <Route element={<LoginLayout/>}>
                <Route element={<Home/>} path='/'/>

                <Route element={<Login/>} path='/*'/>
            
            </Route>

        </Route>
    </Routes>
  )
}
