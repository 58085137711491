import React, { useCallback, useState } from 'react'
import WithSidebar from '../../../components/shared/Layouts/WithSidebar'
import { useUIContext } from '../context/UIContext'
import Sidebar from '../components/sidebar'
import { Server, VolumeSearch } from '../../../types'
import useNavigateTo from '../hooks/useNavigateTo'
import { useQfsContext } from '../context/qfsContext'
import { useAppContext } from '../../../context/AppContext'
import StatsBar from '../components/sidebar/Stats'
import { generateSearchVolumKey } from '../../../utils/utils'
import Icon from '../../../components/icons/Icon'

type Props = {
    domain: Server|null,
    serverId: string | undefined,
    handleLocationSelection: (node:any)=> void,
    handleSearchSelection:(doc:VolumeSearch) => void,
    selectedKey: string

}

export default function SidebarContainer({children}:{children:JSX.Element}) {
    const { hidePanel } = useUIContext()
    const [sideBarWidth, setsideBarWidth] = useState(340);
    const { currentLocation } = useQfsContext()
    const { domain, server } = useAppContext()
    const [resultsLength, setresultsLength] = useState(0);
    const {goTo} = useNavigateTo()

    const handleLocationSelection = useCallback(
        (node: Server) => {
        goTo(node.path, {});
        },
        [goTo]
    );

    const handleSearchSelection = useCallback(
        (doc:VolumeSearch) => {
            goTo(generateSearchVolumKey(doc), {});
        },
        [goTo]
    );
  return (
    <>
    { (domain) && (
            <WithSidebar
                footer={<StatsBar volumes={resultsLength} />}
                setWidth={setsideBarWidth}
                sidebar={
                    <div className="relative" >
                        <Sidebar
                        server={server}
                        selectedKey={currentLocation ? (currentLocation.isDomain ? '/' : currentLocation.id) : '/'}
                        handleLocationSelection={handleLocationSelection}
                        handleSearchSelection={handleSearchSelection}
                        openAction={({ key, title, icon }) => {
                    
                        }}
                        resultsLength={resultsLength}
                        setresultsLength={setresultsLength}
                        datacenter={domain}
                        serverId={currentLocation?.clusterId} 
                    />
                    
                        
                    </div>

                }
                hideSidebar={false}
            >
                <div className='flex '>
                    {/* <div className='flex flex-col'>
                        <div className="-ml-[20px] flex items-center flex-1 relativd z-[1000]">
                            <ModulesBar/>
                        </div>
                        <div className="bg-black bar-h-mini"></div>
                    </div> */}

                <div className={`flex flex-1 flex-col transition-all bg-sub-windows z-100 relative ${hidePanel && `shadow-sidebar-opened  `}`} style={{marginLeft: hidePanel ? -sideBarWidth+60 : 0}}>
                        {children}

                </div>
                </div>
            </WithSidebar>


    )}
    
    </>
  )
}
