import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LocationLayout from '../../components/locationLayout'
import useGetWebResources from '../../hooks/useGetWebResources'
import { useSearchContext } from '../../context/searchContext'
import { useUIContext } from '../../context/UIContext'
import { retrieveDocumentsById } from '../../../../services/api/request/search'

export default function Website({suffix}: {suffix:string}) {
    const {  groupBy } = useSearchContext()
    const {disabled} = useUIContext()
    const params = useParams()
    const [mainWebsite, setmainWebsite] = useState<string | undefined>(undefined);
    const { webresources, isLoaded } = useGetWebResources({id: params.id ? params.id : params.serverId!, websiteId: params.websiteId!, groupBy: false})
    
    useEffect(() => {
      
      (async() => {
        if(!params.websiteId) return
        const req:any = await fetch(retrieveDocumentsById('publications', params.websiteId))
        const res:any = await req.json()
        const url = res?.data?.url 
        if(url){
          const hostname = new URL(url)
          if(hostname?.hostname){
            setmainWebsite(hostname?.hostname)
          }else{
            setmainWebsite(res.data?.title?.[res.data?.local])
          }
        }else{
          setmainWebsite(res.data?.title?.[res.data?.local])

        }
      })()
    }, [params.websiteId]);

    
  return (
    <LocationLayout
      partners={null}
      sponsors={null}
      publications={undefined}
      webResources={webresources}
      groupBy={groupBy}
      availableLocations={null}
      suffix={`${params.serverId ? `${params.serverId}/` : ''}${suffix === ':coll' ? params.coll : suffix}/${params.id ? params.id : params.serverId!}/webresources/${params.websiteId}/`}
      layoutProps={{
        selectedKey: params.id!,
        loading:!isLoaded,
        publicationBar:true,
        disabled:{...disabled, new:false},
        collection:suffix,
        id: params.id ? params.id : params.serverId!
      }}
      all={mainWebsite}
      isLoaded={isLoaded}


    
    />
  )
}
