import React, { useState } from 'react'
import { useUIContext } from '../context/UIContext';
import { useParams } from 'react-router-dom';
import ResourcesTopBar from '../../../components/shared/bars/ResourcesBar/ResourcesPanel';
import DetailsTopBar from '../../../components/shared/bars/ResourcesBar/DetailsPanel';
import DetailsActionsComp from '../../../components/shared/bars/TopBar/actionsBar/actions/details';

export default function QfsActionsBar() {
    const { disabled, barActivatedFields, mode, setObjectDisplayMode,} = useUIContext()

    const params = useParams()
  return (
    <>
       

        <div className="pl-[11px]">
        

            <div className='flex  bg-bars '>
                <div className='flex-1 flex flex-col'>
                    <ResourcesTopBar isPublication={!disabled.publicationBar} author={!!barActivatedFields.author} date={!!barActivatedFields.date}/>
                
                </div>
                <div className='w-[33%] flex justify-between'>
                    <DetailsTopBar/>
                    {(params.publicationId || params.webpageId || params.websitePublicationId )&& (
                      <DetailsActionsComp 
                        setObjectDisplayMode={setObjectDisplayMode}
                        mode={mode}
                        recheckPin={0}
  
                      
                      />

                    )}
                
                </div>
              </div>

        </div>
    </>
  )
}
