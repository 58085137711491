import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { message, notification, Tooltip } from 'antd'
import { ReactSVG } from 'react-svg'
import { Server } from '../../../types'

type Props = {
    item: Server,
    active: boolean,
    clickFn:() => void,
    path:string,
    selectedLocations:any,
    removeSelectedLocation:(loc:any) => void,
    addSelectedLocation:(data:Server) => void,
}
export default function ServerWithCheck({item, active, clickFn,path, selectedLocations, addSelectedLocation, removeSelectedLocation}: Props) {
    const handleSelectingLocations =(item: any) => {
        if(selectedLocations?.some((location:any) => location.key === item.key)){
            removeSelectedLocation(item)
        }else{
            if(selectedLocations?.length > 3){
                message.error("You can't select more than 4 volumes")
                return
            }
            addSelectedLocation({...item, description:path})
        }
    }
  
  return (
    <div className={`flex justify-between cursor-pointer px-2.5 py-1 rounded items-center ${active ? 'bg-skin-fill-inverted text-skin-inverted fill-skin-inverted' : 'bg-hover-transparent'} group`} onClick={clickFn}>
        <div className="flex flex-1 space-x-3  items-center">
            <ReactSVG 
            src={`${item.iconUrl}`} 
            className='icon-sm'
            beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
            }}
             />
            <p className={`ellipsis-1 flex-1`}>{item.title}</p>

        </div>
        {(item.coll === 'privateDirectories' || item.coll === 'privateFolders') ? (
            <Tooltip title='This volume is private'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon-sm fill-transparent opacity-10 group-hover:opacity-[1]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>

            </Tooltip>
        ) : (
                <>
                
                <div
                className="ml-1.5 inline-flex items-center justify-center w-3.5 h-3.5   mr-1"
                onClick={() => handleSelectingLocations(item)}
                >
                    <Tooltip title={selectedLocations?.some((location:any) => location.id == item.id) ? 'Remove volume' : 'Select volume'}>
                        {selectedLocations?.some((location:any) => location.id == item.id) ? <XCircleIcon className={`w-full h-full fill-transparent text-red-700 `} /> : <CheckCircleIcon className={`w-full h-full text-transparent group-hover:text-green-600 ${active && '!text-green-600'} fill-transparent `} />}

                    </Tooltip>
                </div>
          
               
        
          
                </>
        )}
       
    </div>
  )
}
