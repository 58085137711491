

import Collapse, { Panel } from "../../../../../../components/shared/Collapse";

import Publications from "../../../../../../components/shared/publications";
import { useUIContext } from "../../../../context/UIContext";
import { useEffect, useState } from "react";
import OneLineItem from "../../../../../../components/shared/publications/format/OneLine";
import { useParams } from "react-router-dom";


type Props ={
  publications:any,
  handlePublicationSelection: (item:any, type?:'website') => void,
  author:boolean,
  handleSelectGroupedWebresources:(id:string) => void,
  searchOn:boolean
}

export default function WebResourcesGroup({ publications, handlePublicationSelection, handleSelectGroupedWebresources, searchOn } : Props) {
  
    const { displayMode, barActivatedFields } = useUIContext()
    const [activateKeys, setactivateKeys] = useState<string[] | string>([]);
    const params = useParams()
    useEffect(() => {
        
        if(searchOn){
            setactivateKeys(prev => prev.constructor === Array ? [...prev, '0', '1'] : ['0', '1'])
        }else{
            if(params.coll && params.coll !== 'DedicatedDrives'){
                setactivateKeys(['0'])

            }else{
                setactivateKeys([])
            }

        }
    }, [searchOn]);
    const renderDate = (date:number) => {
        const formatedDate = new Date(date * 1000).toLocaleDateString("en-US")
        return `${formatedDate}`
      } 
    return (
        <div className="webresources">
            <Collapse activeKey={activateKeys} onChange={(v) => setactivateKeys(v)}>
                {publications && publications?.length > 0 && publications.map((group:any,index:number) => (
                <Panel 
                className='relative' 
                header={

                      <OneLineItem
                      item={{
                        document: {...group.hits?.[0]?.document?.publications, title: {[group.hits?.[0]?.document?.publications?.local]: group.hits?.[0]?.document?.publications?.url},publicationType: {iconUrl:'/o/icons%2Fpublications%2Fwebsite.svg?alt=media&token=bed0624e-4aac-43f0-bac5-27140924b3d5', title: {en: 'Website'}}},
                       
                    }}
                      active={group.hits?.[0]?.document?.publications?.id && (params.websitePublicationId === group.hits?.[0]?.document?.publications?.id)}
                    
                      handlePublicationSelection={(item) => handlePublicationSelection(item,'website')}
                      setRecheckPin={() => {}}
                      renderDate={renderDate}
                      barActivatedFields={barActivatedFields}
                    />
                    // <OneLine
                    //   active={false}
                    //   dropdownProps={null}

                    // >
                    // <div className={`grid grid-cols-6 gap-x-3  justify-between cursor-pointer w-full`} >
                    //   <div className='flex space-x-2 col-span-3 items-center bg-transparent relative' >
                    //       <div className='relative icon-sm'>
                    //           <ReactSvg src={`${storageUrl}/o/icons%2Fpublications%2Fwebsite.svg?alt=media&token=bed0624e-4aac-43f0-bac5-27140924b3d5`} className='w-full h-full'/>
                    //       </div>
                      
                    //         <p className='truncate max-w-full flex-1 text-start'>{group.hits?.[0]?.document?.publications_link?.url}</p>

                          
                          
                    //   </div>
                    
                    // </div> 
                    // </OneLine>
                } 
                key={`${index}`}
                > 
                    <div className="pl-[36px]">
                        <Publications barActivatedFields={barActivatedFields} setRecheckPin={() => {}} publications={group.hits} handlePublicationSelection={handlePublicationSelection} displayMode={displayMode}/>
                 
                        {group.found > group.hits?.length && (
                            <button className="bg-skin-fill-muted px-3 py-1.5 rounded mt-2" onClick={() => handleSelectGroupedWebresources(group.group_key[0]) }>See all...</button>
                            )}

                    </div>
                </Panel>
                

                ))}
            
            </Collapse>
                {/* <ButtonLg
                    buttonProps={{
                    // onClick:()=> goTo(`/${suffix}publications/all`,{})


                    }}
                >
                    <p>
                    See all web resources ({totalHits.webresources})
                    </p>
                   </ButtonLg> */}
           

        </div>
           
         
    
  )
}