import React from 'react';

import { useParams } from 'react-router-dom';
import { useSelectionHandlers } from '../../hooks/useSelectionHandler';
import PartnerWithWebsite from '../../../../components/shared/cards/partners/PartnerWithWebsite';
import SponsorWithMinuature from '../../../../components/shared/cards/sponsor/SponsorWithMinuature';
import { PartnerType } from '../../../../types';

type Props = {
  partners: PartnerType[] | null;
  sponsors: PartnerType[] | null;
  suffix: string;
};

const PartnersAndSponsors= React.memo(({ partners, sponsors, suffix }: Props) => {
  const params = useParams();
  const { handleSelectPartner } = useSelectionHandlers(suffix)

  return (
     
          
          <div className='flex flex-wrap ml-[-7px] gap-12'>
            {partners?.map(part => (
              <div
                key={part.id}
                className={`w-max rounded cursor-pointer ${part.id === params.partnerId ? '' : 'bg-hover-transparent'}`}
                onClick={() => handleSelectPartner(part)}
              >
                <PartnerWithWebsite active={params.partnerId === part.id} partner={part} />
              </div>
            ))}
            {sponsors?.map(sponsor => (
              <div
                key={sponsor.id}
                className={`w-max rounded cursor-pointer ${sponsor.id === params.partnerId ? '' : ''}`}
                onClick={() => handleSelectPartner(sponsor)}
              >
                <SponsorWithMinuature active={params.partnerId === sponsor.id} sponsor={sponsor} />
              </div>
            ))}
          </div>

   
  );
})

export default PartnersAndSponsors;
