import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

type Props = {
    backLink:string,
    children:JSX.Element
}
export default function ModalLayout({ backLink, children }: Props) {
    const [open, setopen] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        
        setopen(true)
    }, []);
  return (
    <Modal
      visible={open}
      footer={null}
      onCancel={() => navigate(backLink)}
      width={'100%'}
      style={{
        height:'100%',
        top:0
      }}
    
    >
     {children}
    </Modal>
  )
}
