import { createContext, useContext, useMemo, useState } from "react";
import { Organization, ProData } from "../../../types";

export type accountType =  "INDIVIDUAL" | "ORGANIZATION"
type States = {
    clientData:ProData | null,
    organizationData: Organization & ProData | null,
    profile: {id:string, name:string},
    planId:string,
    accountType: accountType | null | any,
    currentStep: number
}
type Functions = {
    setclientData:(data:ProData | null) => void,
    setorganization:(data:Organization & ProData | null | null) => void,
    setaccountType:(data:accountType | null | any) => void,
    setplanId:(data:string) => void,
    setprofile:(data:{id?:string, name?:string}) => void,
    setCurrentStep:(data:number) => void


}
type Props = States & Functions
const SignupContext = createContext<Props>({} as Props);

export const useSignupContext = () => useContext(SignupContext);

export const SignupContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    clientData:null,
    organizationData: null,
    planId:'',
    profile:{id:'', name:''},
    accountType:null,
    currentStep: 1
   
  })
  

  const value = useMemo(() => {
    
   
      const setclientData=(data:ProData | null) => {
        setState((prev) => ({
            ...prev,
            clientData:data
          }))
      }
      const setorganization=(data:Organization & ProData | null | null) => {
        setState((prev) => ({
            ...prev,
            organizationData:data
          }))
      }
      const setaccountType=(data:accountType | null | any) => {
        setState((prev) => ({
            ...prev,
            accountType:data
          }))
      }
      const setplanId=(data:string) => {
        setState((prev) => ({
            ...prev,
            planId:data
          }))
      }
      const setprofile=(data:{id?:string, name?:string}) => {
        setState((prev) => ({
            ...prev,
            profile:{...prev.profile, ...data}
          }))
      }
      const setCurrentStep=(data:number) => {
        setState((prev) => ({
            ...prev,
            currentStep:data
          }))
      }
    return {
      ...state,
      setprofile,
      setplanId,
      setaccountType,
      setorganization,
      setclientData,
      setCurrentStep
     
    }
  }, [state])

  return <SignupContext.Provider value={value}>{children}</SignupContext.Provider>;
};