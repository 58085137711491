import React, { useEffect } from 'react'
import Collapse, { Panel } from '../../../../components/shared/Collapse'
import ContentComponent from '../../components/content'
import Traffic from '../../components/traffic'
import { useStatsContext } from '../../context/statsContext'
import useFetchVolum from '../../../../hooks/useFetchVolum'
import { useAppContext } from '../../../../context/AppContext'
import { useParams } from 'react-router-dom'
import Tendance from '../../components/tendance'
import Subscriptions from '../../components/subscriptions'

export default function VolumePage() {
    const { setCurrentLocation, currentLocation } = useStatsContext()
    const {domain} = useAppContext()
    const params = useParams()
    const { volume, loading} = useFetchVolum(params.id, params.collection!, domain?.id)

    useEffect(() => {
        if(volume){
          const clusterId = volume.routes?.filter((v:any) => v.depth ===2)?.[0]?.id
          setCurrentLocation({...volume, clusterId: clusterId??null })
  
        }
        
      }, [volume?.id]);
 
   
  return (
    <div className='pl-[43px] pt-layout h-[calc(calc(100vh-186.5px))] overflow-auto space-y-7'>
        <p className='text-[25px] font-bold'>Report for {currentLocation?.title??domain?.title}</p>
        <Collapse defaultActiveKey={['content', 'traffic']}>
            <>
                <Panel key={'content'} header={<p className='text-groupe'>Content</p>}>
                    <div className="pl-[23px] pt-[30px] pb-[35px]">
                        <ContentComponent/>
                    </div>
                </Panel>
                <Panel key={'traffic'} header={<p className='text-groupe'>Traffic</p>}>
                    <div className="pl-[23px] pt-[30px] pb-[35px]">
                        <Traffic/>
                    </div>
                </Panel>
                <Panel key={'tendance'} header={<p className='text-groupe'>Tendance</p>}>
                    <div className="pl-[23px] pt-[30px] pb-[35px]">
                        <Tendance/>
                    </div>
                </Panel>
                <Panel key={'subscription'} header={<p className='text-groupe'>Subscriptions</p>}>
                    <div className="pl-[23px] pt-[30px] pb-[35px]">
                        <Subscriptions/>
                    </div>
                </Panel>
            
            </>
        </Collapse>
    </div>
  )
}
