import React from 'react'
import PublicationDetails from '../../../../components/shared/details/Publications'
import { useUIContext } from '../../context/UIContext'


export default function PublicationDetailsPage() {
    const { mode } = useUIContext()
  return (
   <PublicationDetails mode={mode}/>
  )
}
