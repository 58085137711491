import { Popover, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import Icon from '../../../../components/icons/Icon';
import Organizations from './Organizations';

interface DataType {
  key: string;
  keyword: string;
  number: number;
  growth: number;
  click:number
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Keywords',
    dataIndex: 'keyword',
    key: 'keyword',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Requests',
    dataIndex: 'number',
    key: 'number',
  },
  
  {
    title: 'Growth rate',
    dataIndex: 'growth',
    key: 'growth',
    render: number => <div className='flex items-center space-x-2'>
      {number > 0 ? <Icon className='icon-sm rotate-[80deg] text-green-500' name='Cursor'/> : <Icon className='icon-sm rotate-[180deg] text-red-500' name='Cursor'/>}
      <p>{number}%</p>
    </div>,
  },
  {
    title: 'Clicks',
    dataIndex: 'click',
    key: 'click',
   
  },
  {
    title: 'Top 10 organizations',
    dataIndex: 'entreprise',
    key: 'entreprise',
    render: text => <div>
      <Popover content={<Organizations/>} placement='right'>
      <button>
        <Icon className='icon-sm' name='Entreprise'/>

      </button>

      </Popover>
    </div>
   
  },
  {
    title: 'Top 10 publications',
    dataIndex: 'publiocation',
    key: 'publiocation',
    render: text => <div>
      <Icon className='icon-sm' name='Document'/>
    </div>
   
  },


 
];

const data: DataType[] = [
  {
    key: '1',
    keyword: 'Devops',
    number: 5890,
    growth: 17,
    click:2442
  },
  {
    key: '2',
    keyword: 'Java',
    number: 4643,
    growth: 1,
    click:5340
  },
  {
    key: '3',
    keyword: 'Big data',
    number: 4203,
    growth: 24,
    click:4234
  },
  {
    key: '4',
    keyword: 'AI',
    number: 3980,
    growth: -5,
    click:4521
  },
  {
    key: '5',
    keyword: 'Cloud',
    number: 2800,
    growth: -3,
    click:3253
  },
  {
    key: '6',
    keyword: 'SAAS',
    number: 2489,
    growth: 9,
    click:1342
  },
  {
    key: '7',
    keyword: 'Cybersecurity',
    number: 2345,
    growth: 27,
    click:2453
  },
  {
    key: '8',
    keyword: 'Azure',
    number: 2078,
    growth: -7,
    click:5340
  },
  {
    key: '9',
    keyword: 'Microsoft',
    number: 1600,
    growth: 12,
    click:2421
  },
  {
    key: '10',
    keyword: 'Oracle',
    number: 980,
    growth: -3,
    click:245
  }
  
];

const Keywords: React.FC = () => <Table columns={columns} dataSource={data} />;

export default Keywords;