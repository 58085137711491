import { useEffect, useState } from 'react'


import DropDownMenu from '.'
import { Spin, message, notification } from 'antd'
import { useParams } from 'react-router-dom'
import { TypeMenuItem } from '../../../types'
import Icon from '../../icons/Icon'

type Props = {
  id:string,
  onSuccess:() => void,
  type:string,
  extraItems?: TypeMenuItem[] ,
  items: {
    open?:{action:() => void}, 
    shortcut?:boolean,
    modules?:{
      name:string,
      icon:string,
      action:() => void,
      disabled?:boolean
    }[]
  }
}



export default function ResourceMenu({id, onSuccess, type, extraItems=[], items}:Props) {

  


   const renderItem = (data:any) => {
    return data.map((d:any) => {
      return {
        ...d,
        icon: d.icon ? <Icon name={d.icon.src} className='icon-mini'/> : '',
        disabled:true
      }
    })
   }
  return (
    <div className="min-w-[200px]">
      <Spin spinning={false}>
        <DropDownMenu
          selectedKeys={[]}
          items={
            [
           
          ...items.open ? [{
              label:'Open in new tab',
              icon:<Icon name='OpenWindow' className="icon-mini" />,
              key:'open',
              disabled:true,
              onClick:items.open.action
            }]: [],
            {
              label:'Pin',
              icon:<Icon name={'Pin'} className="icon-mini" />,
              key:'pin',
              disabled:true,
              onClick:() =>{}
            },
            ...items.shortcut ? [{
              label:'Create a shortcut',
              icon:<Icon name={'Shortcut'} className="icon-mini" />,
              key:'shortcut',
              disabled:true,
              onClick:() => {},
            }]: [],
            {
              label:'Share',
              icon:<Icon name='Share' className="icon-mini" />,
              key:'Share',
              disabled:true,
            },
            {
              label:'Broadcast',
              icon:<Icon name='Broadcast' className="icon-mini" />,
              key:'Broadcast',
              disabled:true,
            },
            ...items.modules ? [
              {
                type:'divider'
              },
              ...items.modules.map(module => ({
              label:module.name,
              icon:<Icon name={module.icon} className="icon-mini" />,
              key:module.name,
              disabled:module.disabled,
              onClick:module.action,
            
            }))] : [],
            ...renderItem(extraItems)
          ]}
        />

      </Spin>
 

  </div>
  )
}
