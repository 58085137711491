import { Dropdown } from 'antd';
import React from 'react'
import UserActionsButton from '../../../components/shared/bars/TopBar/userActionsButton';
import { useMydeskContext } from '../context/MydeskContext';
import { SearchIcon } from '@heroicons/react/solid';
import ReactSvg from '../../../components/shared/ReactSvg';
import TypesDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown';
import { ButtonLg } from '../../../components/shared/Buttons/ButtonLg';
import DisplayDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/DisplayDropdown';
import useGetPubsTypes from '../../../hooks/useGetPubsTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import Icon from '../../../components/icons/Icon';
import NewDropdown from './dropdowns/NewDropdown';
import StatusDropdown from './dropdowns/StatusDropdown';

export default function PathBarContainer() {
    const { currentLocation, query, setQuery, totalHits, selectedPubType, setSelectedPubType, disabled, groupBy, setDisplayMode, displayMode } = useMydeskContext()
     const { loading, publicationsTypes } = useGetPubsTypes()
     const params = useParams()
      const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div>
        <div className='flex justify-between items-center w-full pr-7'>
        <div className="flex-1 ">
        

            <div className="flex flex-1 space-x-2 bar-h menubar items-center pl-4 pr-2">
                
                    {currentLocation && (
                    
                        <div className="flex space-x-3 itesm-center">
                        {currentLocation?.icon && (
                                currentLocation.icon.type === 'icon' ? (
                                    <Icon name={currentLocation.icon.src} className='icon-sm'/>
                                ) : (
                                    <ReactSvg src={currentLocation.icon.src} className='icon-sm'/>
                                )
                            )}
                            
                        <p className='w-[120px] truncate'>{currentLocation.title}</p>
                        </div>
                    )}

                    <div className={`bar-h flex items-center  bg-sub-windows px-5 `}>
                            <div className="flex items-center rounded space-x-2">
                                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                <div className=' px-1 flex items-center '>
                                    <SearchIcon className='icon-mini'/>
                                    <input type="text" value={query} onChange={e => setQuery(e.target.value)} className='bg-input border-none roundedw-full placeholder:font-bold w-[200px] disabled:opacity-20' placeholder={totalHits === 0 ? 'Search' : `Search among ${totalHits} resources`} disabled={disabled.query} />
                                </div>
                                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                <Dropdown
                                    overlay={(disabled.filter) ? <></> : <TypesDropdown selectedType={selectedPubType} selectAction={(node:any) => setSelectedPubType({ value: node.id, icon: node.iconUrl, type:'type' , title:node.name_plural.en})} typesList={publicationsTypes}/>} trigger={['click']} disabled={!!(loading || params.group)} 
                                    >
                                        {/* <Spin spinning={loading}> */}
                                        <button>
                                            <ButtonLg
                                                suffix={{
                                                    icon:{type:'icon', src: selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                                                    size: 'mini',
                                                    onClick:() => selectedPubType.value ? setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                                                }}
                                                prefix={{
                                                    icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                                                    size: 'sm'
                                                }}
                                                buttonProps={{
                                                    disabled:!!(loading || disabled.filter || params.group)
                
                                                }}
                                                width='w-[150px]'
                                            ><>{selectedPubType?.value? selectedPubType.title :'Filter'}</></ButtonLg>
                
                                        </button>
                                    
                
                                </Dropdown>
                                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                <Dropdown
                                overlay={disabled.display ? <></> : <DisplayDropdown setGroupBy={
                                    (value:boolean) => {
                                        searchParams.set('grouped', value ? 'true' : '');  
                                        setSearchParams(searchParams)
                                    }
                                } groupBy={{paidPubs: !!groupBy, webresources:true}} displayMode={displayMode} setDisplayMode={setDisplayMode} barActivatedFields={{author:false, date:false}} setBarActivatedFields={() => {}} /> }
                                trigger={['click']}
                                disabled={(disabled.display)}
                                >
                                        <button>
                                            <ButtonLg
                                                suffix={{
                                                    icon:{type:'icon', src: 'ChevronDownIcon' },
                                                    size: 'mini'
                                                }}
                                                buttonProps={{
                                                    disabled: (disabled.display)
                                                }}
                                                prefix={{
                                                    icon:{type:'icon', src: groupBy ? 'GroupBy' : 'Degroup' },
                                                    size: 'sm'
                                                }}
                                                width='w-[150px]'
                                            ><>Diplay</></ButtonLg>
            
                                        </button>
                                                                                    
                                    
                                </Dropdown>
                                <Dropdown
                                overlay={disabled.status ? <></> : <StatusDropdown/> }
                                trigger={['click']}
                                disabled={(disabled.status)}
                                >
                                        <button>
                                            <ButtonLg
                                                suffix={{
                                                    icon:{type:'icon', src: 'ChevronDownIcon' },
                                                    size: 'mini'
                                                }}
                                                buttonProps={{
                                                    disabled: (disabled.status)
                                                }}
                                                prefix={{
                                                    icon:{type:'icon', src: 'Document' },
                                                    size: 'sm'
                                                }}
                                                width='w-[150px]'
                                            ><>Status</></ButtonLg>
            
                                        </button>
                                                                                    
                                    
                                </Dropdown>
                
                            </div>
                            
                        </div>
                            
        
                </div>


        </div>
            <UserActionsButton/>
        
        </div> 

        <div className="bar-h border-y border-main px-4">
            <Dropdown
                overlay={(disabled.new) ? <></> :  <NewDropdown publicationType={publicationsTypes}/>}  trigger={['click']} disabled={disabled.new} 
                >
                    {/* <Spin spinning={loading}> */}
                    <button>
                        <ButtonLg
                            suffix={{
                                icon:{type:'icon', src: selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                                size: 'mini',
                                onClick:() => selectedPubType.value ? setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                            }}
                            prefix={{
                                icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'PlusIcon'},
                                size: 'sm'
                            }}
                            buttonProps={{
                                disabled:disabled.new

                            }}
                            width='w-[150px]'
                        ><>New</></ButtonLg>

                    </button>
                

            </Dropdown>
                                              
        </div>
    </div>
  )
}
