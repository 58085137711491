import {Link, Outlet, useNavigate, useParams} from 'react-router-dom'


import Icon from '../../components/icons/Icon'
import PathBar from '../../components/shared/bars/TopBar/Pathbar'
import { useStatsContext } from './context/statsContext'
import { useEffect, useState } from 'react'
import WithSidebar from '../../components/shared/Layouts/WithSidebar'
import Sidebar from '../Qfs/components/sidebar'
import { useAppContext } from '../../context/AppContext'
import useNavigateTo from './hooks/useNavigateTo'
import ResourcesTopBar from '../../components/shared/bars/ResourcesBar/ResourcesPanel'
import { DatePicker } from 'antd'
import Divider from '../../components/shared/Divider'
import { DownloadIcon } from '@heroicons/react/solid'
import useFetchVolum from '../../hooks/useFetchVolum'


const { RangePicker } = DatePicker;

export default function StatsLayout() {
    const { currentLocation } = useStatsContext()
    const [hidePanel, setHidepanel] = useState(false);
    const { domain } = useAppContext()

    const [sidebarWith, setsidebarWith] = useState(300);

    const { goTo }= useNavigateTo()
    const params = useParams()
    const { volume:server } = useFetchVolum(params.serverId!, 'LocalClusters')
    const { setServer } = useAppContext()

    useEffect(() => {
      if(server){
        
        setServer(server)
      }else{
        setServer(null)
      }
    }, [server])
   

  return (
   
          
    <div className='flex-1  flex flex-col h-full overflow-hidden bg-sub-window'>
    
    <div className="bar-h flex justify-between  px-[29px] items-center">
        <div className='flex space-x-3 items-center'>
            <Icon name='TeddraLogo' className='icon-sm'/>
            <p className='font-bold'>Stats</p>
        </div>
        
    </div>
    <PathBar
    onCncelSearch={() => {}}
        Breadcrumb={{routes: currentLocation?.routes? 
            [
              {
                icon:{type:'icon', src:'Globe'},
                
              },
             ...domain ? [
              {
               name: domain.title 
              }
             ] : [],...currentLocation.routes,
            ] : 
            [
              {
                icon:{type:'icon', src:'Globe'},
                
              },
             ...domain ? [
              {
               name: domain.title 
              }
             ] : []
            ]
          }}
        hidePanel={{open:hidePanel, disabled: false}}
        setHidePanel= {setHidepanel}
    
    />
    <WithSidebar
        setWidth={setsidebarWith}
        hideSidebar={false}
    
        sidebar={
            <Sidebar
                handleLocationSelection={(node) =>goTo(`${node.path}`, {})}
                handleSearchSelection={() => {}}
                selectedKey={params.id ?? domain?.id!}
                openAction={() =>{}}
                datacenter={domain}
                serverId={currentLocation?.clusterId}
                server={server}
                resultsLength={0}
                setresultsLength={() => {}}

            />
        }
    >
        <div className={`flex flex-col transition-all bg-sub-windows z-100 relative ${hidePanel && `shadow-sidebar-opened  `}`} style={{marginLeft: hidePanel ? -sidebarWith+30 : 0}}>
            <div className='bar-h border-b border-main pl-[25px] pr-2 flex items-center space-x-3'>
                <RangePicker/>
                <Divider/>
                <button className='rounded px-4 py-1.5 bg-skin-fill-inverted text-skin-inverted flex items-center'>
                    <p>Download report (PDF)</p>
                    <DownloadIcon className='icon-sm'/>
                </button>
            </div>
            <div className='pl-[11px]'>
                <div className='flex  bg-bars'>
                    <div className='flex-1 flex flex-col'>
                        <ResourcesTopBar isPublication={false} author={false} date={false}/>
                    
                    </div>
                
                </div>

            </div>
            <div className="flex-1  flex flex-col">
                <Outlet/>
                
            </div>
            <div className="bar-h-mini bg-black border-t border-main"></div>

        </div>
        
    </WithSidebar>
    
    



    </div>
            

     
  )
}
