import { Navigate, Outlet, OutletProps, useLocation } from 'react-router-dom'
import ValidateEmail from './ValidateEmail'
import { useAuthContext } from '../context/AuthContext'


export default function PrivateRoute(props:OutletProps) {
  const { user } = useAuthContext()
  const location = useLocation()
    
    
    
  
    if(!user){
      return <Navigate to={`/connect${location.search}`} replace />;
    }
    // if(!user.emailVerified){
    //     return <ValidateEmail />
    // }
    
    return <Outlet {...props}/>
   
}
