import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Form, notification, Select, Spin } from 'antd'
import React, { useState } from 'react'
import Input from '../../../../../components/shared/input'
import SelectCluster from '../../../components/SelectCluster'
import SelectDomain from '../../../components/SelectDomain'
import SelectVolume from '../../../components/SelectVolume'
import { fetchInt } from '../../../../../utils/api/fetchInt'
import { createDedicatedVolume } from '../../../../../services/api/request'

export default function CreateFolder() {
    const [domainId, setdomainId] = useState('');
    const [clusterId, setclusterId] = useState('');
    const [parentColl, setparentColl] = useState('');
    const [loading, setloading] = useState(false);
    const submit = async (values:any) => {
        const {name, description, keywords, parentId} = values
        if(!parentColl){
            return
        }
        setloading(true)
        const submittedData:any = {
            title: {
                en: name,
            },
            description: {
                en: description
            }, 
            keywords:{
                en:keywords
            },
            status: 'enabled',
            icon: {
                id: 'icons/tree/all_icons/dedicated_folder',
                src:'/o/icons%2Ftree%2Fdedicated_folder.svg?alt=media&token=f81d411b-27a2-43e4-9c83-df8f3c9c0b54'
            }
        }
        try{

            const req = await fetchInt(createDedicatedVolume({collection:'DedicatedFolders', parentColl, parentId}),{
                method: 'POST', 
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                body: JSON.stringify(submittedData)
            })
            const res = await req.json()
            if(res.success){
                notification.success({message:res.message})
            }
        }catch(err){
            console.log(err)
        }finally{
            setloading(false)
        }
    }
  return (
    <div className='space-y-4'>
        <div className='bar-h '>
            <div className='flex space-x-2 items-center'>
                <ChevronLeftIcon className='icon-sm'/>
                <p>Back</p>
            </div>
           
        </div>

        <div className='h-full flex justify-center items-center'>
            <div className='space-y-7 w-full max-w-[500px]'>
                <p className='text-[20px] font-bold text-center'>Create a folder</p>
                <Form onFinish={submit}>
                    <div className="space-y-7">
                        <div className="space-y-2">
                            <p className="font-bold">Tell us about your folder:</p>
                            <Form.Item
                                name={'name'}
                                rules={[{required:true}]}
                            >
                                
                                <Input placeholder='Name' className='rounded border border-gray-500 w-full'/>

                            </Form.Item>
                            <Form.Item
                                name={'description'}
                                rules={[{required:true}]}
                            >
                                
                                <textarea placeholder='Description' className='rounded border border-gray-500 w-full' rows={4}/>

                            </Form.Item>
                            <Form.Item
                                name={'keywords'}
                                rules={[{required:true}]}
                            >
                                
                                <Select
                                    className=" transition-none border-opacity-20 rounded border border-gray-500 w-full"
                                    mode="tags"
                                    open={false}
                                    style={{width: "100%", background:'#fff'}}
                                    placeholder={'Insert a maximum of 8 keywords'}
                                    maxLength={8}
                                    // value={keywords}
                                    
                                    // onDeselect={(e) => setkeywords((prev:string[] ) => (prev.filter(item => item !== e)))}
                                    // onSelect={(e) => setkeywords((prev:string[]) => ([...prev, e]))}
                                />  

                            </Form.Item>
                        </div>
                        <div className="space-y-2">
                            <p className="font-bold">Where you want to publish your folder?</p>
                            <Form.Item
                                name={'domainId'}
                                rules={[{required:true}]}
                            >
                                <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) => prevValues.domainId !== curValues.domainId}
                                >
                                    {({ getFieldValue, setFieldsValue }) => (
                                        <SelectDomain
                                            value={getFieldValue('domainId')}
                                            setValue={(value:string | undefined, node:any | null) => {
                                                if(node && value){
                                                    setdomainId(node.id)
                                                    setFieldsValue({ domainId: value})

                                                }else{
                                                    setdomainId('')
                                                    setFieldsValue({domainId: undefined})
                                                }
                                            }}
                                        />
                                    )}

                                </Form.Item>
                                
                            </Form.Item>  
                            <Form.Item
                                name={'clusterId'}
                                rules={[{required:true}]}
                            >
                                <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) => prevValues.clusterId !== curValues.clusterId}
                                >
                                    {({ getFieldValue, setFieldsValue }) => (
                                        <SelectCluster
                                            domainId={domainId}
                                            value={getFieldValue('clusterId')}
                                            setValue={(value:string | undefined, node:any | null) => {
                                                if(node && value){
                                                    setclusterId(node.id)
                                                    setFieldsValue({ clusterId: value})

                                                }else{
                                                    setclusterId('')
                                                    setFieldsValue({clusterId: undefined})
                                                }
                                            }}
                                        />
                                    )}

                                </Form.Item>
                                
                            </Form.Item>
                            <Form.Item
                                name={'parentId'}
                                rules={[{required:true}]}
                            >
                                <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) => prevValues.parentId !== curValues.parentId}
                                >
                                    {({ getFieldValue, setFieldsValue }) => (
                                        <SelectVolume
                                            clusterId={clusterId}
                                            value={getFieldValue('parentId')}
                                            setValue={(value:string | undefined, node:any | null) => {
                                                if(node && value){
                                                    setFieldsValue({ parentId: value})
                                                    setparentColl(node.coll)
                                                }else{
                                                    setparentColl('')
                                                    setFieldsValue({parentId: undefined})
                                                }
                                            }}
                                        />
                                    )}

                                </Form.Item>
                                
                            </Form.Item>
                        </div>
                        <Spin spinning={loading}>
                            <button disabled={loading} type='submit' className='px-4 py-1.5 rounded bg-skin-fill-inverted text-skin-inverted'>
                                Submit
                            </button>

                        </Spin>
                    </div>

                </Form>
            </div>
        </div>
    </div>
  )
}
