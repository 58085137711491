import React, { useState } from 'react'
import PublishInLayout from '../../../components/publishLayout/publishIn'
import { useVolumeCreationContext } from '../../../context/volumeCreationContext'
import { useParams } from 'react-router-dom';
import { usePublicationCreationContext } from '../../../context/PublicationCreationContext';

export default function PublishPublication() {
    const [loading, setloading] = useState(false);
    const [selectedLocations, setselectedLocations] = useState<any>([]);
    const { editData} = usePublicationCreationContext()
    const params = useParams()
    const removeSelectedLocation = (loc:any) => {
       setselectedLocations((prev:any) => prev.filter((item:any) => item.id !== loc.id))
    }
    const addSelectedLocation = (loc:any) => {
       setselectedLocations((prev:any) => [...prev, loc])
    }
    const submit = async () => {
  //     if(!type){
  //       notification.error({message:'something went very wrong'})
  //       return
  //     }
  //       const insertedData = {
  //         title: {
  //           [editData.local]: editData.title
  //         },
  //         keywords: {
  //           [editData.local]: editData.keywords
  //         },
  //         publicationType: {
  //           title: type.name_singular, id: type.id, iconUrl: type.icon.src, groupe:type.groupe
  //         },
  //         description : {
  //           [editData.local]: editData.description
  //         },
  //         url: editData.url,
  //         local:editData.local,
  //         selectedVolumes: selectedLocations.map(volume => ({id:volume.id, collection:volume.coll})), 
  //         status: 'published'
  //       }
  // // data validation client side
   
  //     setloading(true)
  //     const req = await fetchInt(createPublication, {
  //       method:'POST',
  //       body: JSON.stringify(insertedData),
  //       headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //       },
  //     }).finally(() => setloading(false))
  //     const res = await req.json()
  //     if(res.success){
  //       notification.success({message:'Publication created succussfully'})
  //       navigate('/publish/published')
  //     }else{
  //       notification.error({message:res.message})
  //     }
  }
  return (
    <div>
        <PublishInLayout
            submit={submit}
            editData={editData}
            submitLoading={loading}
            selectedLocations={selectedLocations}
            removeSelectedLocation={removeSelectedLocation}
            addSelectedLocation={addSelectedLocation}
            footerTitle='Display my publication in:'
        />
    </div>
  )
}
