import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';






interface State {
  run: boolean;
  steps: Step[];
}



export default function Tutorial() {
  const [{ run, steps }, setState] = useState<State>({
    run: false,
    
    steps: [
    
      {
        content: <h2>Here you can create your volumes</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        target: '#my-volumes',
        disableBeacon:true
      },
      {
        content: <h2>Here you can create your publications</h2>,
        placement: 'bottom',
        styles: {
          options: {
            width: 300,
          },
        },
        target: '#publications',
        title: 'Publications',
      },
      {
        content: (
          <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium itaque nostrum saepe quos excepturi facilis 
         
           
          </div>
        ),
        placement: 'top',
        target: '#activities',
        title: 'Activities',
      },
      {
        content: (
          <div>
            <h3>manage your account</h3>
            <svg
              height="50px"
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 96 96"
              width="50px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
                  fill="#000000"
                />
              </g>
            </svg>
          </div>
        ),
        placement: 'left',
        target: '#account',
      }
    ],
    
  });

 
    useEffect(() => {
        
        setState(prev => ({
            ...prev,
        run: true,
        }));
    }, []);

  

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
        setState(prev => ({
            ...prev,
          run: false,
        }));
      
    }

  };

  return (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={true}
        scrollToFirstStep
        showProgress
        spotlightPadding={3}
        
        showSkipButton
        steps={steps}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 36,
            overlayColor: 'rgba(0, 0, 0, 0)',
            primaryColor: '#000',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          
            textColor: '#fff',
            width: undefined,
            zIndex: 100,
            },
        }}
      />
    
  );
}
