import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6296 5.205H12.627C12.2347 5.205 11.8673 5.041 11.6468 4.767L10.5742 3.437C10.3538 3.164 9.98756 3 9.59526 3H6.37037C5.06074 3 4 3.895 4 5V13C4 14.105 5.06074 15 6.37037 15H17.6296C18.9393 15 20 14.105 20 13V7.205C20 6.101 18.9393 5.205 17.6296 5.205Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.02V18.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 20H20"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20H10.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0607 18.9393C13.6465 19.5251 13.6465 20.4748 13.0607 21.0606C12.4749 21.6464 11.5252 21.6464 10.9394 21.0606C10.3536 20.4748 10.3536 19.5251 10.9394 18.9393C11.5252 18.3535 12.4749 18.3535 13.0607 18.9393"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
