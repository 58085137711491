import React from 'react'
import Icon from '../../../components/icons/Icon'
import { iconType } from '../../../types'

type Props = {
    icon:iconType,
    title:string|JSX.Element,
    description:string|JSX.Element,
    label?:string|JSX.Element,

}
export default function ObjectMd({ icon, title, description, label} :Props) {
  return (
    <div className="flex">
        <div className="flex space-x-3 flex-1">
            {icon.src ? (
                <Icon name={icon.src} className='w-[80px] h-[80px]'/>

            ): (
                <div className="w-[80px] h-[80px] bg-skin-fill-muted"></div>
            )}
            <div className="flex-1">
                <p className="text-[22px] font-bold leading-[22px]">{title}</p>
                <p className='leading-[16px]'>{description}</p>
                {label && (
                    <p className="text-muted">{label}</p>

                )}
            </div>
        </div>

    </div>
  )
}
