import React, { useState } from 'react'
import PublishInLayout from '../../../components/publishLayout/publishIn'
import { useVolumeCreationContext } from '../../../context/volumeCreationContext'
import { useParams } from 'react-router-dom';

export default function PublishVolume() {
    const [loading, setloading] = useState(false);
    const [selectedLocations, setselectedLocations] = useState<any>([]);
    const { editData} = useVolumeCreationContext()
    const removeSelectedLocation = (loc:any) => {
       setselectedLocations([])
    }
    const addSelectedLocation = (loc:any) => {
      setselectedLocations([loc])  
    }
    const submit = async () => {

    }
  return (
    <div>
        <PublishInLayout
            submit={submit}
            editData={editData}
            submitLoading={loading}
            selectedLocations={selectedLocations}
            removeSelectedLocation={removeSelectedLocation}
            addSelectedLocation={addSelectedLocation}
            footerTitle='Display my volume in:'
        />
    </div>
  )
}
