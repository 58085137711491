import React, { useState } from 'react'

import { notification, Tooltip } from 'antd'


import { useNavigate } from 'react-router-dom'
import ObjectWithIcon from '../../../../../components/shared/object/objectWithIcon'
import ReactSvg from '../../../../../components/shared/ReactSvg'
import { Server } from '../../../../../types'
import { XCircleIcon } from '@heroicons/react/solid'
import Input from '../../../../../components/shared/input'

type Props = {
    data:{title:string, type:string},
    submit:() => void,
    selectedLocations:any,
    removeSelectedLocation:(loc:any) => void,
    loading: boolean,
    title:string,
}
export default function PublishInFooter({data, title, submit, selectedLocations, removeSelectedLocation, loading}:Props) {

     

  
  return (
    <div className='py-2 px-5 flex space-x-10 justify-between flex-1'>
    <div className='space-y-3 pt-2 w-[600px]'>
      <p className='text-title pl-2'>{title} :</p>
      <div className='grid grid-cols-2 gap-x-10 grid-rows-3'>
        {selectedLocations?.length > 0 && selectedLocations.map((loc:Server) => (
          <ObjectWithIcon
            title={loc.title}
            icon={<ReactSvg src={loc.iconUrl} className='w-full h-full'/>}
            onSelect={() => {}}
            description={loc.description}
            extra={<div
              className="absolute top-1 right-1"
              onClick={() =>  removeSelectedLocation(loc)}
              >
                  <Tooltip title='Remove volume'>
                      <XCircleIcon className={`w-full h-full fill-transparent text-red-700 icon-sm`} />

                  </Tooltip>
              </div>}
          />
          //  <ServerWithCheck titleMaxWidth='w-[130px]' item={loc} active={false} key={loc.id} clickFn={() => {}} selectedLocations={selectedLocations} setselectedLocations={setselectedLocations}/>

        ))}
      </div>
    </div>
      <div className="space-y-2 flex-1 ">
        <div className="flex items-center gap-x-7">
          <p className="text-end font-bold w-[35px]">
            Title
          </p>
          <div className='input-h w-full border border-main  cursor-context-menu rounded flex items-center flex-1'>
         
              <Input
                type="text"
                className="col-span-3 m-0 border-none rounded h-full  ellipsis-oneline"
                value={data?.title}
              />
          </div>
        </div>
        <div className="flex items-center gap-x-7">
          <p className="text-end font-bold w-[35px]">
            Type
          </p>
          <div className='input-h w-full border border-main  cursor-context-menu rounded flex items-center flex-1 '>
            <p className='pl-3 truncate w-full'>{data?.type} </p>              
          </div>
        </div>

        <button disabled={loading} className='px-4 py-2 rounded ml-auto block bg-skin-fill-inverted text-skin-inverted' onClick={submit}>Submit</button>
        
        
      </div>
  </div>
  )
}
