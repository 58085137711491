import { TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ReactSVG } from 'react-svg';
import { getNodeDomains } from '../../../services/api/request';

type Props = {
    value : string,
    setValue:(id:string) => void,
    node: {id:string, parentId:string, featuredDomain:string} | undefined
}
export const mapTreeIcon = (tree:any[], parentId?:string) => {
    return tree.map(item => {
        item.icon = <ReactSVG src={item.iconUrl} className='icon-sm' />
        item.value = item.title
        item.key = item.title
        item.label = item.title
        
        if(item.featuredDomain){
            item.children = null
        }else{
            item.selectable = false
        }
        if(item.children) {
            
            mapTreeIcon(item.children)
        }
        return item
    })
}
export default function SelectInterests({ value, setValue, node }: Props) {
    const [domains, setdomains] = useState<any>([]);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        if(node){
            setloading(true)
            fetch(getNodeDomains({univerId: node.parentId, nodeId: node.id, featuredDomain: node.featuredDomain!})).then(res => res.json()).then(data => setdomains(data.data)).catch(err => console.log(err)).finally(() => setloading(false))
    
    
        }else{
          setdomains([])
        }
      }, [node]);
  return (
    <TreeSelect
        treeData={domains ? mapTreeIcon(domains) : []} 
        value={value}
        maxLength={5}
        className='w-full rounded border-gray-500'
        switcherIcon={(props : any) =>
            props.expanded ? (
                <ChevronDownIcon className='w-3.5 h-3.5' />
            ) : (
                <ChevronRightIcon className='w-3.5 h-3.5' />
            )
        }
        disabled={!node}
        dropdownMatchSelectWidth={400}
        suffixIcon={<ChevronDownIcon className='w-3.5 h-3.5'/>}
        treeNodeFilterProp='title'
        allowClear
        placeholder='Interests'
        labelInValue
        multiple
        treeCheckable
        onChange={(value,label) => setValue(value)}
        onClear={() => setValue('')}
        showSearch
        // onClear={() => setCountry(null)}
        treeIcon
    />
  )
}
