import React, { useEffect, useRef, useState } from 'react'

import { Popover } from 'antd'
import PrivateVolume from './popover/private'
import PublicVolume from './popover/public'
import { Server, VolumeSearch } from '../../../../types'
import Icon from '../../../../components/icons/Icon'
import { isObject } from '../../../../utils/utils'

type Props = {
    item: (Server & {type: 'tree'}) | (VolumeSearch & {type: 'search'}),
    domain:Server|null,
    selectedVolume:string|undefined,
    setselectedVolume:(value:string|undefined) => void
}
export default function Marker({item, domain, selectedVolume, setselectedVolume} : Props) {
    const [coll, setcoll] = useState<undefined | string>(undefined);
    
    useEffect(() => {
       if(item.type === 'tree'){
        setcoll(item.coll)
       }else{
        setcoll(item.cfs_type)
       }
    }, [item.id]);
    const openEngine = () => {
        console.log(domain, item)
       if(domain){
        if(coll === 'LocalClusters'){
            window.open(`https://${domain.title.toLocaleLowerCase().split(' ').join('-')}.qorddu.com/LocalClusters/${item.id}`, '_blank')

        }else{
            if(item.type === 'tree'){
                window.open(`https://${domain.title.toLocaleLowerCase().split(' ').join('-')}.qorddu.com/${item.clusterId}/${coll}/${item.id}`, '_blank')
            }else{
                window.open(`https://${domain.title.toLocaleLowerCase().split(' ').join('-')}.qorddu.com/${item.clustersIds[0]}/${coll}/${item.id}`, '_blank')
            }
            

        }
       }
    }
  return (
    <div className='space-y-2 w-[30px] flex flex-col items-center '>
        <Popover
            destroyTooltipOnHide
            // overlayClassName='popover-black'
            trigger={['click']}
            // onVisibleChange={(visible) => {
            //     console.log(visible, 'pop');
            //     if (!visible) setselectedVolume(undefined); // Close popover on click outside
            // }}
            // visible={selectedVolume === item.id}
        
            content={
                coll && (
                    item.private ? <PrivateVolume openEngine={openEngine} item={{...item, coll}} domain={domain} /> : <PublicVolume item={{...item, coll}} openEngine={openEngine} />

                )
         
            }
        >
            <Icon name='MapMarker' className={`icon-mini ${item.status === 'enabled' ? 'text-bg-selected' : 'text-gray-400'}`}/>
            <p className={`font-bold  truncate text-[10px] ${item.status === 'enabled' ? '' : 'text-muted'}`}>{isObject(item.title) ? item.title.en : item.title}</p>

        </Popover>
    </div>
  )
}
