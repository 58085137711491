import { useEffect, useState } from "react";
import { multisearch, searchDocuments } from "../../../services/api/request/search";
import { searchLocations, searchPublications, searchWebResources } from "../../../services/search/search";

const useFetchServerStatistics = (
    id: string | undefined,
  ) => {
    const [stats, setstats] = useState({organizations: 0, total_publications: 0, total_servers: 0});
    useEffect(() => {
      if (!id) return;
  
      const fetchStats = async () => {
        try {
         
        const [organizations, publications, webresources, volumes] =await Promise.all([
          fetch(searchDocuments('publications'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify({
              data : searchPublications({q:'*', mode:'roots', limit:1, volumeId:id, type: {type: 'type', value:'company_profile'}})
            })
          }).then(res => res.json()),
          fetch(searchDocuments('publications'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify({
              data : searchPublications({q:'*', mode:'roots', limit:1, volumeId:id})
            })
          }).then(res => res.json()),
          fetch(searchDocuments('webpages'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body : JSON.stringify({
              data : searchWebResources({q:'*', mode:'roots', limit:1, volumeId:id})
            })
          }).then(res => res.json()),
          fetch(searchDocuments('volumes'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              data: searchLocations({q: '*', filter: `&& roots:=[${id}]`, limit: 1})
            })
          }).then(res => res.json())
        ])
        setstats({organizations: organizations.data.found, total_publications: publications.data.found + webresources.data.found, total_servers: volumes.data.found})
        } catch (error) {
          console.error('Failed to fetch server statistics:', error);
          // Optionally set error state here
        }
      };
  
      fetchStats();
    }, [id]);

    return stats
};

export default useFetchServerStatistics