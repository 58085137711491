import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'


import useGetPublications from '../../../hooks/useGetPublications'
import LocationLayout from '../../../components/locationLayout'
import useGetOwner from '../../../hooks/useGetOwner'
import useGetWebResources from '../../../hooks/useGetWebResources'
import { useSearchContext } from '../../../context/searchContext'


export default function PrivateVolume() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { groupBy } = useSearchContext()

    const [searchParams] = useSearchParams();
    const { publications, isLoaded } = useGetPublications({id:params.id!, limit: 6, groupBy: !!searchParams.get('grouped'), mode: 'roots'})  
    const { webresources, isLoaded:isLoaded2 } = useGetWebResources({id:params.id!, groupBy:true, mode:  'roots'})  
    const { owner } = useGetOwner({coll: params.coll!, id:params.id!, setloading})

 
    
   


  
  
    
  return (
        <LocationLayout
            layoutProps={{
                loading,
                selectedKey:params.id!,
                disabled:{filter:false, query:false, display:false, new:false},
                publicationBar:true,
                collection:params.coll!,
                id:params.id!
            }}
            allowSearch={true}
            publications={publications}
            sponsors={null}
            partners={null}
            owner={owner}
            availableLocations={null}
            suffix={ `${params.serverId}/${params.coll}/${params.id}/`}
            groupBy={groupBy}
            webResources={webresources}
            isLoaded={isLoaded && isLoaded2}
            showApps={true}
        />
      

    
  )
}
