import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { iconType, Server } from '../../../../../types'

import TreeLayout from './Layout'
import useSearchLocations from '../../../hooks/useSearchLocations'



type Props = {
    handleLocationSelection: (node:Server) => void, 
    selectedKey:string,
    openAction : ({title,id, icon,key}:{title:string, icon:iconType,id: string, key:string}) => void,
    serverId: string | undefined,
    datacenter: Server | null, 
    server: Server,
    tree: any,
    setResultsLength:any
}
 


export default function TreeComponent({handleLocationSelection, selectedKey, openAction, datacenter, serverId, tree, server, setResultsLength}: Props) {

    
    const [loading, setloading] = useState(false)
    const {resultsLength} = useSearchLocations({
      query: '*', 
      filter: `&& roots:=${serverId}`,
      limit:1
    })

    useEffect(() => {
      
      setResultsLength(resultsLength)
    }, [resultsLength]);


    

  
   
    

 
    

  return (
         
          <Spin spinning={loading}>
            {server && datacenter && (
                <TreeLayout
                  tree={tree}
                  openAction={openAction}
                  handleLocationSelection={handleLocationSelection}
                  selectedKey={selectedKey}
                />

            )}
          </Spin>
        

      
  )
}
