import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { TreeSelect } from 'antd';
import { Server } from 'http';
import React, { useEffect, useState } from 'react'
import { mapTreeIcon } from './SelectCountry';
import { getGeographicServers } from '../../../services/api/request';


type Props = {
    selectedCountry: string | undefined,
    setselectedRegion: (value:string | null) => void,
    selectedRegion: string | undefined | null

}
export default function SelectCity({ selectedCountry, selectedRegion, setselectedRegion } : Props) {
    const [regions, setregions] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        if(selectedCountry){ 
            setselectedRegion(null)

            setloading(true)
            fetch(getGeographicServers({countryId: selectedCountry})).then(res => res.json()).then(data => setregions(data.data)).catch(err => console.log(err)).finally(() => setloading(false))
        }
    }, [selectedCountry]);
  return (
    <TreeSelect
        treeData={regions ? mapTreeIcon(regions) : []} 
        className='w-full input-h rounded border-gray-500'
        switcherIcon={(props : any) =>
            props.expanded ? (
                <ChevronDownIcon className='w-3.5 h-3.5' />
            ) : (
                <ChevronRightIcon className='w-3.5 h-3.5' />
            )
        }
        defaultValue={selectedRegion}
        disabled={!selectedCountry}
        dropdownMatchSelectWidth={400}
        suffixIcon={<ChevronDownIcon className='w-3.5 h-3.5'/>}
        treeNodeFilterProp='title'
        allowClear
        placeholder='City'
        showSearch
        onClear={() => setselectedRegion(null)}
        value={selectedRegion}
        // onClear={() => setCountry(null)}
        onSelect={(value,node) => setselectedRegion(node.value)}
        treeIcon
    />
  )
}
