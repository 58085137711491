import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../../../context/AuthContext';
import Icon from '../../../icons/Icon';
import { signOut } from 'firebase/auth';
import { auth } from '../../../../utils/firebase';

export default function UserActionsButton() {
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const {user} = useAuthContext()

     const logout = () => {
               signOut(auth).then(() => {
                   
       
               }).catch((err) => console.log(err))
           }
  return (
    <div>
          {user?.id ? (
            <Dropdown
                onVisibleChange={visible => setdropdownVisible(visible)}
                destroyPopupOnHide
                trigger={['click']}
                overlay={<div className='bg-sub-windows rounded shadow w-[150px] '>
                    <Link to={'/mydesk'} target='_blank'className='border-b border-main w-full px-4 py-2 bg-hover-transparent block !text-inherit'>
                        Open mydesk
                    </Link>
                    <button className='block w-full text-start py-2 bg-hover-transparent px-4' onClick={logout}>
                        Logout
                    </button>
                </div>}
            >
                <button id='profile' className={`flex space-x-2 items-center cursor-pointer px-3 py-0.5 rounded no-dragable profile ${dropdownVisible ? 'bg-skin-fill-inverted-main text-skin-inverted' : 'bg-hover-transparent'}`} >
                    
                        
                    {user?.picture ? <div className='bg-white  w-[25px] h-[30px] rounded overflow-hidden border p-[1px]'>
                    <img className='w-full h-full object-cover rounded-[4px]' src={user?.picture}/> 
                        </div> : (
                            <div className='w-[25px] h-[30px] flex items-center justify-center' ><Icon name='UserIcon' className='w-full h-full '/></div>

                    )}
                        <p className='max-w-[100px] truncate text-[11px] font-light'>{user?.name}</p>

                </button>
            </Dropdown>
        ) : (
            <Link to="/connect" target="_blank" className='bg-skin-fill-inverted text-skin-inverted px-4 py-1.5 rounded'>
                Login
            </Link>

        )}
    </div>
  )
}
