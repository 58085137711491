import React, { useEffect, useState } from 'react'
import { getDomains } from '../../../../services/api/request';
import { Server } from '../../../../types';
import OneLine from '../../../../components/shared/object/oneLine';
import WithDivider from '../../../../components/shared/WithDivider';

export default function DomainNavigation() {
    const [data, setdata] = useState<Server[]>([]);
    const [selectedUniver, setselectedUniver] = useState<Server | null>(null);

    useEffect(() => {
        
        fetch(getDomains).then(res => res.json()).then(data => setdata(data.data))
    }, []);
  return (
    <div className='' style={{
        // backdropFilter: 'blur(5px)',
        // background: 'rgba(255,255,255,0.5)'
    }}>
        
        <div className='w-[500px] drop-shadow-lg bg-white rounded  flex min-h-[400px] h-[53vh]'>
            <div className="w-[150px] h-full">
                <div className="bar-h-mini pl-2 border-b border-main">
                    <WithDivider><p>Univers</p></WithDivider>
                </div>
                <div className='pl-2 pt-1'>
                    {data.map(univer => (
                        <OneLine key={univer.id} active={selectedUniver?.id === univer.id} dropdownProps={null} onClick={() => setselectedUniver(univer)}>
                            <button className='flex items-center space-x-1.5 pl-[5px]'>
                                <p>{univer.title}</p>
                            </button>
                        </OneLine>
                    ))}

                </div>
            </div>
            <div className='flex-1 '>
                <div className="bar-h-mini pl-2 border-b border-main">
                    <WithDivider><p>Domains</p></WithDivider>
                </div>
                <div className="min-h-[400px] h-[50vh] overflow-y-auto pl-1 pt-1">
                    {selectedUniver && selectedUniver.children && selectedUniver.children.map(domain => (
                        <OneLine key={domain.id} dropdownProps={null} className='w-full'> 
                            <a href={`https://${domain.title.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' className='flex items-center space-x-1.5 text-center pl-[10px] !text-inherit'>
                                <p>
                                    <span className='font-bold'>{domain.title.split(' ').join('-').toLocaleLowerCase()}</span>
                                    <span className='font-light'>.qorddu.com</span>
                                </p>
                            </a>
                        </OneLine>
                    ))}

                </div>
            </div>
        </div>

    </div>
  )
}
