import { useNavigate } from "react-router-dom";
import Icon from "../../../../components/icons/Icon";
import Collapse, { Panel } from "../../../../components/shared/Collapse";
import ObjectMd from "../../../../components/shared/object/objectMd";
import { Dropdown } from "antd";
import VolumesDropdown from "../../components/dropdowns/VolumesDropdown";
import OneLine from "../../../../components/shared/object/oneLine";
import UserVolumesDropdown from "../../components/dropdowns/UserVolumesDropdown";
import { useMydeskContext } from "../../context/MydeskContext";
import { useEffect, useState } from "react";
import { volumeCodes } from "../../constants";



export default function MydeskHome(){
    const navigate = useNavigate()
    const { userCurrentSubscription } = useMydeskContext()
    const [volumes, setvolumes] = useState<{name:string, icon:string, disabled?:boolean, code:string}[]>([])
    
    useEffect(() => {
        if(userCurrentSubscription){
            const features = userCurrentSubscription.tier.features
            const data:{name:string, icon:string, disabled?:boolean, code:string}[] = []
        
            volumeCodes.map(code => {
                if(features.some(element => element.code === code.code)){
                    data.push({
                        name:code.name,
                        icon:code.icon,
                        code:code.code
                    })
                }else{
                    data.push({
                        name:code.name,
                        icon:code.icon,
                        disabled:true,
                        code:code.code
                    })
                }
            })
            setvolumes(data)
            
        }
    }, [userCurrentSubscription?.id])

    return (                                                 
      
        <div className="overflow-auto pt-5 ">
            <div className="w-[65%]">
                <Collapse defaultActiveKey={['1']}>
                    <>
                        <Panel
                            key={'1'}
                            header={
                                <div className="w-full flex justify-between">
                                    <p className="text-group">My volumes</p>
                                </div>
                            }
                        >
                            <div className="pl-2 flex flex-wrap gap-3">
                                <Dropdown
                                    overlay={
                                        <div className="relative -top-[40px] left-[40px]">
                                                <VolumesDropdown
                                                    volumes={volumes}
                                                    handleSelect={(value:string) => navigate(`/mydesk/volumes/create/${value}/edit`)}
                                                />
                                            
                                        </div>
                                    }
                                >
                                    <button>
                                        <ObjectMd
                                        iconSize="icon-lg"
                                            title="Create a new volume"
                                            icon={<Icon className="icon-lg" name="AddLocation" />}
                                            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, ab ea. Deserunt corporis sunt incidunt aperiam iure sint consectetur"
                                            onSelect={() => {}}

                                        />
                                        
                                    </button>

                                </Dropdown>
                                {/* <ObjectMd
                                    title="Volume 1"
                                    icon={<Icon className="icon-md" name="Directory" />}
                                    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, ab ea. Deserunt corporis sunt incidunt aperiam iure sint consectetur"
                                    onSelect={() => {}}

                                />
                                <ObjectMd
                                    title="Volume 2"
                                    icon={<Icon className="icon-md" name="Folder" />}
                                    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, ab ea. Deserunt corporis sunt incidunt aperiam iure sint consectetur"
                                    onSelect={() => {}}

                                /> */}
                                    

                            </div>
                        </Panel>
                        <Panel
                            key={'2'}
                            header={
                                <div className="w-full flex justify-between">
                                    <p className="text-group">My publications</p>
                                </div>
                            }
                        >
                             <Dropdown
                                    overlay={
                                        <div className="relative -top-[10px] left-[10px]">
                                                <UserVolumesDropdown
                                                    handleSelect={(value:string) => navigate(`/mydesk/publications/create/edit`)}
                                                />
                                            
                                        </div>
                                    }
                                >

                                    <button className="pl-4">
                                    
                                        <OneLine
                                            active={false}
                                            
                                            dropdownProps={null}
                                            >
                                            
                                            
                                            
                                            <div className={`grid grid-cols-6 gap-x-3  justify-between cursor-pointer w-full`}onClick={() => navigate('/mydesk/publications/create/edit')}>
                                            <div className='flex space-x-2 col-span-3 items-center bg-transparent relative' >
                                                <div className='relative icon-sm'>
                                                    <Icon name={`AddDocument`} className='w-full h-full'/>
                                                </div>
                                                
                                                <p className='truncate max-w-full flex-1 text-start'>Create new publication</p>
                                        
                                                
                                                
                                            </div>
                                        
                                        
                                            
                                            <span></span>
                                            <span></span>
                                        
                                            
                                            
                                            
                                        
                                        
                                        </div> 
                                            </OneLine>
                                        

                                    </button>
                                </Dropdown>
                        </Panel>
                    </>
                </Collapse>

            </div>
        </div>

    )
} 