import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99875 20.0033H7.99833C5.23576 20.0033 2.99625 17.7638 2.99625 15.0012V7.99833C2.99625 5.23576 5.23576 2.99625 7.99833 2.99625H16.0017C18.7642 2.99625 21.0037 5.23576 21.0037 7.99833V10.9996"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0004 8.99874H17.0021"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 8.50348L7.911 9.32387L9.74907 7.67319"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 13.5056L7.911 14.326L9.74907 12.6753"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0029 20.5035H15.0013V21.0037C15.0013 21.5563 14.5533 22.0042 14.0008 22.0042H13.0004C12.4479 22.0042 12 21.5563 12 21.0037V19.253C12 18.2861 12.7838 17.5023 13.7507 17.5023H20.2534C21.2203 17.5023 22.0042 18.2861 22.0042 19.253V21.0037C22.0042 21.5563 21.5563 22.0042 21.0037 22.0042H20.0033C19.4508 22.0042 19.0029 21.5563 19.0029 21.0037V20.5035Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1397 17.6181L13.7149 15.1596C13.8737 14.4809 14.479 14.0008 15.176 14.0008H18.8281C19.5252 14.0008 20.1305 14.4809 20.2893 15.1596L20.8644 17.6181"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2396 17.1246L12 16.5019"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7645 17.1246L22.0042 16.5019"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
