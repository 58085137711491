import React, { useState } from 'react'

import PopverLayout from './layout'
import useGetOwner from '../../../../Qfs/hooks/useGetOwner'
import { Server, VolumeSearch } from '../../../../../types'


type Props = {
    item: (Server | VolumeSearch ) & {coll:string},
    domain:Server|null,
    openEngine:() => void
}
export default function PrivateVolume({ item, openEngine }: Props) {
    const [loading, setloading] = useState(false)
        
    const {owner} = useGetOwner({id:item.id!, coll:item.coll, setloading})
    
  return (
    <PopverLayout
        owner={owner}
        loading={loading}
        item={item}
        openEngine={openEngine}
    />
  )
}
