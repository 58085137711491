import { useLocation } from 'react-router-dom'
import Icon from '../../../../components/icons/Icon'
import { iconType } from '../../../../types'

type Props = {
    tabs: {
        title:string,
        path:string, 
        handleNavigation:() => void,
        disabled?:boolean
    }[],
    icon: iconType,
    title: string
}
export default function Header({tabs, icon, title} : Props) {
    
    const location = useLocation()
   
    const isActive = (page:string) => { 
      return location?.pathname?.split('/')?.includes(page)
    }
    return (
        <div>
            <div className="bar-h border-b border-main pl-9 items-center">
                <div className='flex items-center space-x-3 h-full'>
                    <Icon className='icon-sm' name={icon.src}/>
                    <p>{title}</p>
                </div>
            </div>
            <div className="border-b border-main ">
                <div className='bar-h flex items-center space-x-9 pl-[43px]'>
                    {tabs.map(
                        tab => (
                            <button key={tab.path} disabled={tab.disabled} className={`${isActive(tab.path) && 'active-mode'} disabled:text-skin-muted`} onClick={() => tab.handleNavigation()}>{tab.title}</button>

                        )
                    )}
                    

                </div>

            </div>

        </div>

  )
}
