import React, { useEffect, useRef, useState } from 'react'
import MapComponents from './components/Map'
import { Dropdown } from 'antd'
import { ChevronDownIcon, MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import { signOut } from 'firebase/auth'

import TextBlock from './components/TextBlock'

import MapSearch from './components/search'
import { LngLatLike } from 'mapbox-gl'
import { useAppContext } from '../../context/AppContext'
import { useAuthContext } from '../../context/AuthContext'
import { Server, VolumeSearch } from '../../types'
import useFetchServerStatistics from '../Qfs/hooks/useFetchServerStats'
import { auth } from '../../utils/firebase'
import DomainNavigation from './components/domainNavigation'
import Divider from '../../components/shared/Divider'
import Icon from '../../components/icons/Icon'
import ReactSvg from '../../components/shared/ReactSvg'
import { getTree } from '../../services/api/request'
import SplashScreen from './components/SplashScreen'




export default function Home() {
    const { domain } = useAppContext()
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const {user} = useAuthContext()
    const [data, setdata] = useState<any>([]);
    const [selectedGeoPoint, setselectedGeoPoint] = useState<(Server & {map:LngLatLike, type:'tree'}) | (VolumeSearch & {map:LngLatLike, type:'search'}) | undefined>(undefined);
    const [geoPoints, setgeoPoints] = useState<any>([]);
    const mapRef = useRef<{ zoomIn: () => void; zoomOut: () => void }>(null);
    const { total_publications, total_servers, organizations } = useFetchServerStatistics(domain?.id)
    const [openSplashScreen, setopenSplashScreen] = useState(false);
    const logout = () => {
        signOut(auth).then(() => {
            

        }).catch((err) => console.log(err))
    }
    
    useEffect(() => {
        if(domain){
            fetch(getTree({id: domain.id})).then(res => res.json()).then(data => setdata(data.data))
        }
    }, [domain?.id]);
    
    useEffect(() => {
        
        if(data){   
            data.forEach((cont:any) => {
                if(cont.children){
                    cont.children.forEach((child:any) => {
                        if(child.map){
                            setgeoPoints((prev:any) => [...prev, {...child,map:[child.map.lat, child.map.long], coll: child.coll??'LocalClusters', type:'tree'}])
                        }
                    })
                }
            })
        }
    }, [data?.length]);
    const handleZoomIn = () => {
        mapRef.current?.zoomIn();
      };
    
      const handleZoomOut = () => {
        mapRef.current?.zoomOut();
      };

   
      
  return (
    <div className='relative' >
        <div className='absolute top-0 left-0  w-full'>
            <div className="w-full flex  justify-between p-4 pt-3 items-center">
                <div className=' relative z-10 w-max flex space-x-5 items-center' >
                    <Dropdown
                        trigger={['click']}
                        overlay={
                        <DomainNavigation/>
                        }
                    >
                        <button className='flex items-center space-x-8'>
                        
                            <p className=''>
                                <span className="bg-skin-fill-inverted font-bold text-skin-inverted p-2.5 w-max">{domain?.title.toLocaleLowerCase()}</span>
                                <span className='font-bold relative top-[-2px] mx-0.5'>.</span>
                                <span>qorddu</span>
                                <span className='font-bold relative top-[-2px] mx-0.5'>.</span>
                                <span>com</span>
                            </p>
                            <ChevronDownIcon className='icon-mini'/>
                        </button>
 
                    </Dropdown>
                    <Divider/>
                    {/* <MapSearch  tree={data} selectedVolume={selectedGeoPoint} setselectedVolume={(value, map) => setselectedGeoPoint({value,  map})}/> */}
               
                    <div className="flex space-x-2">
                        <button className='bg-hover-transparent p-1.5 rounded' onClick={handleZoomIn}>
                            <PlusIcon className='icon-mini'/>

                        </button>
                        <button className='bg-hover-transparent p-1.5 rounded' onClick={handleZoomOut}>
                            <MinusIcon className='icon-mini'/>

                        </button>
                    </div>

                </div>
                <div className="relative z-10">
                    {user?.id ? (
                        <Dropdown
                            onVisibleChange={visible => setdropdownVisible(visible)}
                            destroyPopupOnHide
                            trigger={['click']}
                            overlay={<div className='bg-sub-windows rounded shadow w-[150px] '>
                                <Link to={'/mydesk'} target='_blank'className='border-b border-main w-full px-4 py-2 bg-hover-transparent block !text-inherit'>
                                    Open mydesk
                                </Link>
                                <button className='block w-full text-start py-2 bg-hover-transparent px-4' onClick={logout}>
                                    Logout
                                </button>
                            </div>}
                        >
                            <button id='profile' className={`flex space-x-2 items-center cursor-pointer px-3 py-0.5 rounded no-dragable profile ${dropdownVisible ? 'bg-skin-fill-inverted-main text-skin-inverted' : 'bg-hover-transparent'}`} >
                                
                                    
                                {user?.picture ? <div className='bg-white  w-[25px] h-[30px] rounded overflow-hidden border p-[1px]'>
                                <img className='w-full h-full object-cover rounded-[4px]' src={user?.picture}/> 
                                    </div> : (
                                        <div className='w-[25px] h-[30px] flex items-center justify-center' ><Icon name='UserIcon' className='w-full h-full '/></div>
            
                                )}
                                    <p className='max-w-[100px] truncate text-[11px] font-light'>{user?.name}</p>
            
                            </button>
                        </Dropdown>
                    ) : (
                        <Link to="/connect" target="_blank" className='bg-skin-fill-inverted text-skin-inverted px-4 py-1.5 rounded'>
                            Login
                        </Link>
            
                    )}
                </div>
                

            </div>

        </div>
        <div className="absolute w-max z-[300] top-[30%] left-[100px]">

            <MapSearch settree={setdata} tree={data} selectedVolume={selectedGeoPoint} setselectedVolume={setselectedGeoPoint}/> 
            
            <div className="space-y-3 mt-7 max-w-[450px] ">
                <p className='ml-[5px] font-bold'>Network stats</p>
                <div className="grid grid-cols-2 space-x-3 w-max">
                    <div
                        className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px]`}
                    >
                        <div className='flex space-x-3  '>
                        <div className="relative w-[40px] h-[40px] mt-[4px]">
                            <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fcluster.svg?alt=media&token=c4e9e01f-3a49-4b23-aa07-6db0b6e5c1bc`} className="w-full h-full"/>
                        </div>
                    
                        <div className="flex-1 ">
                        
                            <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{total_servers}</p>
                                <p className='ellipsis-2 leading-[16px] text-muted'>Search engines: Servers, Drives, Directories, Folders</p>
                        </div>
                    
                        </div>
                        
                    </div>
                    <div
                        className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px] `}
                    >
                        <div className='flex space-x-3  '>
                        <div className="relative w-[40px] h-[40px] mt-[4px]">
                            <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fpublications%2Fentreprise.svg?alt=media&token=9e92e6f7-8ad0-425d-9dad-49e7b91cfb8b`} className="w-full h-full"/>
                        </div>
                    
                        <div className="flex-1 ">
                        
                                <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{organizations}</p>
                                <p className='ellipsis-2 leading-[16px] text-muted'>Companies, organizations and people connected</p>
                    
                        
                
                        
                        </div>
                    
                        </div>
                        
                    </div>

                </div>
                <div
                        className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px]`}
                    >
                        <div className='flex space-x-3  '>
                            <div className="relative w-[40px] h-[40px] mt-[4px]">
                                <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fpublications%2Fcalendar.svg?alt=media&token=145a9587-8372-438b-bdce-dbeebf9f59b1`} className="w-full h-full"/>
                            </div>
                    
                        <div className="flex-1 ">
                        
                                <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{total_publications}</p>
                                <p className='ellipsis-2 leading-[16px] text-muted'>Publications: Webpages, products sheets, job offers, events, ...</p>
                    
                        
                
                        
                        </div>
                    
                        </div>
                        
                    </div>
            </div>
        </div>
        <TextBlock/>
        <MapComponents ref={mapRef} geoPoints={geoPoints} selectedGeoPoint={selectedGeoPoint} domain={domain}/>
        <SplashScreen
            open={openSplashScreen}
            setOpen={setopenSplashScreen}
        />
    </div>
  )
}
