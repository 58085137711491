import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Server } from '../../../types'
import { getGeographicTree } from '../../../services/api/request'

export const mapTreeIcon = (tree:any[]) => {
    return tree.map(item => {
        item.icon = <ReactSVG src={item.iconUrl} className='icon-sm' />
        item.value = item.title
        item.key = item.title

        if(item.children) mapTreeIcon(item.children)
        return item
    })
}
type Props = {
    selectedCountry : {id:string | undefined, value: string | undefined | null},
    setselectedCountry:(id:string, value:string | null) => void,

}
export default function SelectCountry({ selectedCountry, setselectedCountry } : Props) {
    const [countries, setcountries] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true)
        fetch(getGeographicTree).then(res => res.json()).then(data => setcountries(data.data)).catch(err => console.log(err)).finally(() => setloading(false))
    }, []);
  return (
    <TreeSelect
        treeData={countries ? mapTreeIcon(countries) : []} 
        value={selectedCountry.value}
        className='w-full input-h rounded border-gray-500'
        switcherIcon={(props : any) =>
            props.expanded ? (
                <ChevronDownIcon className='w-3.5 h-3.5' />
            ) : (
                <ChevronRightIcon className='w-3.5 h-3.5' />
            )
        }
        dropdownMatchSelectWidth={400}
        suffixIcon={<ChevronDownIcon className='w-3.5 h-3.5'/>}
        treeNodeFilterProp='title'
        allowClear
        placeholder='Country'
        
        onSelect={(value,node) => setselectedCountry(node.id,node.value)}
        onClear={() => setselectedCountry('', null)}
        showSearch
        // onClear={() => setCountry(null)}
        treeIcon
    />
  )
}
