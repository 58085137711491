import { CameraIcon } from '@heroicons/react/outline'
import React from 'react'

export default function InfoBull({title}:{title: string}) {
  return (
    <div className='w-[450px] bg-white flex space-x-4 p-10'>
        <div className="w-[200px] h-[180px] border border-gray-300 rounded flex justify-center items-center">
            <CameraIcon className='w-[60px] h-[60px] fill-transparent text-gray-300' />
        </div>
        <div className="flex-1">
            <p className='font-bold'>{title}</p>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
            </p>
          
        </div>
    </div>
  )
}
