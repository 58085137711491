import { useEffect, useState } from "react";
import ObjectMode from "../modes/Object";
import { useLocation, useParams } from "react-router-dom";

import { Spin } from "antd";
import { OwnerType, PartnerType } from "../../../../types";
import { storageUrl } from "../../../../services/api/constants";
import { generateSearchVolumKey } from "../../../../utils/utils";
import { getVolumCollaborators, getVolumOwner } from "../../../../services/api/request";
import { retrieveDocumentsById } from "../../../../services/api/request/search";


export default function VolumeDetails(){
    const [volume,setvolume] = useState<{title:string, icon:string, key:string, breadcrumb:string, private: boolean, coll:string, id:string} | null>(null)
    const [owner, setowner] = useState<OwnerType | null>(null);
    const [partners, setpartners] = useState<PartnerType[] | null>(null);

    const [loading, setloading] = useState(false)
    const location = useLocation()
    const params = useParams()
    useEffect(()=>{
        if(location.state?.id){
            const item = location.state
            setvolume({title:item.title.en, icon:`${storageUrl}${item.iconUrl}`, breadcrumb:item.breadcrumbs?.[0]?.en, key: generateSearchVolumKey(item), private: location.state.private, coll:item.cfs_type, id:item.id})

        }else{
            if(params.volumeId){
                setloading(true)
                fetch(retrieveDocumentsById('volumes', params.volumeId)).then(res => res.json()).then(data => {
                    setvolume({title:data.data.title.en, icon:`${storageUrl}${data.data.iconUrl}`, breadcrumb:data.data.breadcrumbs?.[0]?.en, key: generateSearchVolumKey(data.data), private: data.data.private, coll:data.data.cfs_type, id:data.data.id})
                }).catch(err => console.log(err)).finally(() => setloading(false))
                
               
            }
        }
    },[location.state, params.volumeId])
    useEffect(() => {
        if(volume){
            setloading(true)
            if(volume.private){
                fetch(getVolumOwner({volumId: volume.id, volumColl: volume.coll})).then(res => res.json()).then(data => setowner(data.data)).catch(err => console.log(err)).finally(() => setloading(false))
                setpartners(null)
            }else{
                fetch(getVolumCollaborators({volumId: volume.id, volumColl: volume.coll})).then(res => res.json()).then(data => setpartners(data.data.partners)).catch(err => console.log(err)).finally(() => setloading(false))
                setowner(null)
            }
        }
      
    }, [volume]);
    
    return(
        <Spin spinning={loading}>
            {volume && <ObjectMode
                fields={
                    [
                        {
                            name:'Name',
                            value:volume.title,
                            center:true
                        },
                        ...owner ? [
                            {
                                name: 'Owner', 
                                value: <div className="space-y-2 w-[150x]">
                                    <img src={owner.logo} className="w-[100px] h-[100px] rounded block mx-auto" alt="" />
                                    <p className="text-center">{owner.name}</p>
                                </div>
                            }
                        ] : [],
                        ...partners && partners.length>0 ? [
                            {
                                name: 'With', 
                                value: <div className="flex gap-2">
                                {partners.map(p => (
                                    <div key={p.id} className="space-y-2">
                                        <img src={p.logo} className="w-[100px] h-[100px] rounded" alt="" />
                                        <p className="text-center">{p.name}</p>

                                    </div>

                                ))}
                                </div>
                            }
                        ] : []
                    ]
                }
                thumb={{
                    type:'icon',
                    icon:{type:'image', src:volume.icon}
                }}
            />}
        
        </Spin>
    )
}