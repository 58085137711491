import React from 'react'
import { Highlight, VolumeSearch } from '../../../../../types'
import ReactSvg from '../../../../../components/shared/ReactSvg'
import { storageUrl } from '../../../../../services/api/constants'

type Props = {
    results: {document:VolumeSearch , highlight?:Highlight}[],
    selectedKey:string,
    handleServerSelection:(doc:VolumeSearch) => void,
}
export default function Results({ results, handleServerSelection, selectedKey } :Props) {
 
  return (
    <>
    {results.length > 0 && results.map((item) => (
        <div
        key={item.document.id}
        className={`${selectedKey === item.document.id? 'bg-skin-fill-inverted !text-skin-inverted fill-skin-inverted' : 'bg-hover-transparent'} py-2 px-3 rounded flex justify-between cursor-pointer group mx-w-[250px]`}
        onClick={() => handleServerSelection(item.document)}
    >
      <div className='flex space-x-3  '>
        <div className="relative icon-sm mt-[4px]">
            <ReactSvg src={`${storageUrl}${item.document.iconUrl!}`} className="w-full h-full"/>
        </div>
    
        <div className="flex-1">
        <div>
        {item.highlight?.title?.en ? <p className='truncate ellipsis-1 flex-1 text-start' dangerouslySetInnerHTML={{__html: item.highlight?.title?.en?.snippet!}}></p> : (
        <p className='ellipsis-1 flex-1 truncate text-start'>{item.document?.title.en}</p>

      )}
            {/* {item.document.title.en.split("").map((str:string, i:any) => (
            <span
                key={i}
                className={
                getPosition(
                    item.document.title.en,
                    query
                ).includes(i)
                    ? `${selectedKey === item.document.id ? 'text-skin-inverted' : 'font-bold'}`
                    : " "
                }
            >
                {str}
            </span>
            ))} */}
        </div>
        <p className="text-small text-muted-darker ellipsis-1" style={{color:selectedKey === item.document.id ? '#fff' : ''}}>
            {/* {getLocationTypeAndPath(item.document.title.en.depth, item.type).name.en} locations: .../ */}
          
            {item.document.breadcrumbs?.length > 0 && item.document.breadcrumbs.map((breadcrumb:{en:string}, i:number) => (
              <span
              className={
              "text-small text-muted-darker"
              }
              style={{color:selectedKey === item.document.id ? 'var(--text-inverted)' : ''}}
          >
              {breadcrumb.en}
          </span>
            ))}
        </p>
        {item.document.description?.en && (
           item.highlight?.description?.en ? <p className='ellipsis-2' dangerouslySetInnerHTML={{__html: item.highlight?.description?.en?.snippet!}}></p> : (
            <p className='ellipsis-2'>{item.document?.description.en}</p>
    
          )

        )}
        </div>
  
      </div>
      <div className=''>
     
      </div>
    </div>
      )) 
      }
    
    </>
  )
}
