import { Modal } from 'antd'
import React from 'react'
import Icon from '../../../../components/icons/Icon'
import { useAuthContext } from '../../../../context/AuthContext'

type Props = {
    onClose: () => void,
    open: boolean
}
export default function WelcomePopup({ onClose, open }: Props) {
    const { user } = useAuthContext()
  return (
    <Modal
        visible={open}
        onCancel={onClose}
        destroyOnClose
        footer={null}
        width={500}
        closeIcon={
            <button className='rounded p-1 bg-white'>
                <Icon className='icon-sm' name='Close'/>
            </button>
        }
    >
        <div className='flex h-[700px] rounded-[15px] overflow-hidden'>
            <div className='flex-1 bg-sub-windows flex items-center h-full'>
                <div className='px-[30px] space-y-9 w-full'>
                    {/* <img src="/excellence.png" className='w-[200px] h-[200px] mx-auto' alt="" /> */}
                    <div className="text-center">
                        <p className="title-level2">Welcome {user?.name}</p>
                        <p className="text-level2">
                        Thank you for subscribing
                        </p>
                        {/* <p className='font-light uppercase text-[20px]'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem delectus, eius tenetur assumenda praesentium perferendis temporibus facere. Fugit laboriosam facere officiis! Harum illum.

                        </p> */}

                    </div>
                    <div className="flex space-x-3 justify-center">
                        <button className='px-5 py-2 rounded bg-skin-fill-inverted text-skin-inverted w-max block' onClick={onClose}>
                            Start
                        </button>
                        <button className='px-5 py-2 rounded bg-skin-fill-inverted text-skin-inverted w-max block' onClick={onClose}>
                            Start tutorial
                        </button>

                    </div>
                </div>
            </div>
          
      
        </div>
    </Modal>
  )
}
