import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { iconType, ServerWithHomeDesq, subscriptionDetails } from "../../../types";
import Icon from "../../../components/icons/Icon";


export type displayModes = 'OneLine' | 'TwoLines' | 'Thumb' | 'Blocks'
type States = {
  userCurrentSubscription: subscriptionDetails | null
  query: string,
  totalHits: number,
  publicationTypes: null | any[],
  mode: 'object' | 'url',
  selectedPubType : {value: string, icon:string, type:'type' | 'groupe', title:string },
  pagination : { offset: number, limit: number, currentPage: number },
  disabled: {publicationBar: boolean, query: boolean, filter:boolean, display:boolean, status:boolean, new:boolean},
  groupBy: string,
  stats: {volumes: number, publications: number},
  displayMode: displayModes,
  recheckPin:number,
  currentLocation: {title:string, icon:iconType} | null,

}
type Functions = {
  setUserCurrentSubscription: (data: subscriptionDetails | null) => void,
  setQuery: (query:string) => void,
  setPublicationTypes: (data:any[]) => void,
  setObjectDisplayMode: ( mode: 'object' | 'url') => void,
  clearSearch : () => void,
  setSelectedPubType: (data:{ value: string, icon: string, type:'type' | 'groupe', title:string }) => void,
  setTotalHits: (value:number) => void,
  setPaginate : (data: { offset?: number, limit?: number, currentPage?: number }) => void,
  setdisable : (data:{publicationBar?: boolean, query?: boolean, filter?:boolean, display?:boolean, status?:boolean, new?:boolean}) => void,
  setGroupBy: (value:string)=> void,
  setStats: (data:{volumes: number, publications: number}) => void,
  setDisplayMode: (data: displayModes) => void,
  setRecheckPin:() => void,
  setCurrentLocation: (data:{title:string, icon:iconType}) => void,

}
type Props = States & Functions
const MydeskContext = createContext<Props>({} as Props);

export const useMydeskContext = () => useContext(MydeskContext);

export const MydeskContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    userCurrentSubscription:null,
    query: '',
    totalHits: 0,
    publicationTypes: null,
    mode: 'object' ,
    selectedPubType : {value: '', icon: '', type:'type', title:''},
    pagination:{ offset: 0, limit: 18, currentPage: 1 },
    disabled: {publicationBar: true, query: true, filter:true, display:true, status: true, new: true},
    groupBy:'',
    stats: {volumes: 0, publications: 0},
    displayMode: 'OneLine',
    recheckPin:0,
    currentLocation:{title:'My desk', icon:{src:'Manage', type:'icon'}},
  })
  

  const value = useMemo(() => {
    
    const setUserCurrentSubscription = (data: subscriptionDetails | null) => {
      setState((prev) => ({
        ...prev,
        userCurrentSubscription:data
      }))
    }

    const setQuery = (query:string) =>{
      setState((prev) => ({
        ...prev,
        query,
        pagination:{ offset: 0, limit: state.pagination.limit, currentPage: 1 }
      }))
    }

    const setPublicationTypes = (data:any[]) =>{
      setState((prev) => ({
        ...prev,
        publicationTypes: data,
        pagination:{ offset: 0, limit: state.pagination.limit, currentPage: 1 }
      }))
    }

    const setselectedKey = (data:string) => {
      setState((prev) => ({
        ...prev,
        selectedKey: data
      }))
    }

    const setObjectDisplayMode = (mode: 'url' | 'object') => {
      setState((prev) => ({
        ...prev,
        mode
      }))
    }

    const clearSearch = () => {
      setState((prev) => ({
        ...prev,
        locationFilter:{value: '', label: undefined, icon: <Icon className="icon-sm" name="Document"/>},
        query: '',
        expandedPanel:['partners', 'publications', 'childrens', 'owner'],
        selectedPubType: {value:'', icon:'', type: 'type', title: ''},
        pagination:{ offset: 0, limit: state.pagination.limit, currentPage: 1 }
      }))
    }

    const setSelectedPubType = (data:{ value: string, icon: string  , type:'type' | 'groupe', title:string}) => {
      setState((prev) => ({
        ...prev,
       selectedPubType: data,
       pagination:{ offset: 0, limit: state.pagination.limit, currentPage: 1 },
       groupBy:''
      }))
    }

    const setTotalHits = (value:number) => {
      setState((prev) => ({
        ...prev,
       totalHits: value
      }))
    }

    const setPaginate = (data : { offset?: number, limit?: number, currentPage?: number }) => {
      setState((prev) => ({
        ...prev,
       pagination: {...prev.pagination,...data},
       expandedPanel: ['publications']
      }))
    }

    const  setdisable = (data:{publicationBar?: boolean, query?: boolean, filter?:boolean, display?:boolean, status?:boolean, new?:boolean}) => {
      setState((prev) => ({
        ...prev,
        disabled: {...prev.disabled, ...data}
      }))
    }


    const setGroupBy = (value:string)=> {
      setState((prev) => ({
        ...prev,
        groupBy: value,
        selectedPubType:{value: '', icon: '', type:'type', title:''},
        pagination:{ offset: 0, limit: state.pagination.limit, currentPage: 1 }
      }))
    }

    const setStats= (data:{volumes: number, publications: number}) => {
      setState((prev) => ({
        ...prev,
        stats: data
      }))
    }

    const setDisplayMode= (data:displayModes) => {
      setState((prev) => ({
        ...prev,
        displayMode: data
      }))
    }

  

    const setRecheckPin= () => {
      setState((prev) => ({
        ...prev,
        recheckPin: prev.recheckPin + 1
      }))
    }

    const setCurrentLocation = (data:{title:string, icon:iconType}) => {
      setState((prev) => ({
        ...prev,
        currentLocation:data
      }))
    }
    return {
      ...state,
      setUserCurrentSubscription,
      setQuery,
      setPublicationTypes,
      setselectedKey,
      setObjectDisplayMode,
      clearSearch,
      setSelectedPubType,
      setTotalHits,
      setPaginate,
      setdisable,
      setGroupBy,
      setStats,
      setDisplayMode,
      setRecheckPin,
      setCurrentLocation
    }
  }, [state])

  return <MydeskContext.Provider value={value}>{children}</MydeskContext.Provider>;
};