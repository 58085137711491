import React from 'react'
import { iconType } from '../../../types'
import ReactSvg from '../../../components/shared/ReactSvg'

type Props = {
    icon:iconType,
    title: string,
    description: string
}
export default function ObjectSm({ icon, title, description }: Props) {
  return (
    <div className="flex space-x-2">
        <ReactSvg className='icon-lg' src={icon.src}/>
        <div className="flex-1">
            <p className='text-[20px] font-bold leading-[16px]'>{description}</p>
            <p className="">{title}</p>
        </div>
    </div>
  )
}
