import {Link, Outlet, useParams} from 'react-router-dom'

import Footer from './components/Footer'

import PathBarContainer from './GlobalLayout/PathBarContainer'
import SidebarContainer from './GlobalLayout/SidebarContainer'
import QfsActionsBar from './GlobalLayout/QfsActionsBar'
import WelcomSplashScreen from './GlobalLayout/WelcomSplashScreen'
import useFetchVolum from '../../hooks/useFetchVolum'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../context/AppContext'
import { useQfsContext } from './context/qfsContext'
import SplashScreen from './GlobalLayout/SplashScreen'
import Tutorial from './components/tutorial'




export default function FoldersLayout() {

    const { currentLocation } = useQfsContext()
    const { volume:server } = useFetchVolum(currentLocation?.clusterId!, 'LocalClusters')
    const { setServer } = useAppContext()
    const [openSplashScreen, setopenSplashScreen] = useState(false);
    const [startTutorial, setstartTutorial] = useState(false);
    useEffect(() => {
      
      if(!localStorage.getItem('tutorialDone') && !openSplashScreen){
        setTimeout(() => {
          setstartTutorial(true)
        }, 1000);
      }
    }, [openSplashScreen]);
    useEffect(() => {
      if(server){
        
        setServer(server)
      }else{
        setServer(null)
      }
    }, [server])
   
  return (
   
          
            <div className='flex-1  flex flex-col h-full overflow-hidden bg-sub-window'>
              
              
              
                <div className="flex-1  flex flex-col">
                        <SidebarContainer
                        >
                            <>
                                {/* <TopBar/> */}
                                <PathBarContainer/>
                                    <QfsActionsBar/>

                                <div className={`flex-1`}>
                                    <Outlet/>
                                </div>
                                <div className='border-t border-main flex items-center bg-topbar'>
                                    <div className="flex items-center w-full justify-between bg-black text-white pr-5">
                                        <div className="flex-1">
                                            <Footer />

                                        </div>
                                      
                                    </div>
                                </div>
                            
                            </>
                                
                        </SidebarContainer>
                    
                </div>
              

            
                {/* <div className='absolute bottom-0 right-0'>
                    <div className="bg-skin-fill-inverted text-skin-inverted w-[100px] h-[40px]"></div>
                </div> */}
                
                <WelcomSplashScreen/>
                <SplashScreen
                  open={openSplashScreen}
                  setOpen={setopenSplashScreen}
                /> 
                {startTutorial && <Tutorial/>}
              </div>
            

     
  )
}
