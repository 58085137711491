import { ButtonSm } from '../../../../components/shared/Buttons/Small'
import Input from '../../../../components/shared/input'


type Headerprops = {
    mode: 'search' | 'globalTree' | 'localTree',
    setmode: any,
    serverId:undefined|string,
    search: {
      value:string,
      setValue:(value:string) => void
    }

}
export default function Header({mode, setmode, serverId, search}: Headerprops) {
  return (
    <div className='border-b border-main pl-5 '>
        
        <div className='flex items-center bar-h -ml-1.5 space-x-1'>
          
           <ButtonSm icon='LocalTree' iconSize='sm' onClick={() => setmode('localTree')} active={mode === 'localTree'} disabled={!serverId}/>
            <div className={ `relative ${mode === 'search' && 'search-mode'}`}>
            <Input
                className={`w-full relative input-h rounded !border-none ${mode === 'search' && 'active-mode-mini'}`}
                value={search.value}
                onChange={e => search.setValue(e.target.value)}
                placeholder='Search a drive, diretcory,...'
                onFocus={() => setmode('search')}
            />

            </div>


        </div>

    </div>
  )
}
