import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelectionHandlers } from '../../hooks/useSelectionHandler'
import OwnerWithWebsite from '../../../../components/shared/cards/owner/OwnerWithWebsite'
import { OwnerType } from '../../../../types'

type Props = {
    owner: OwnerType,
    suffix:string
}
const Owner = React.memo(({ owner, suffix } : Props) =>{
    const { handleSelectOwner } = useSelectionHandlers(suffix)
    const params = useParams()

    useEffect(() => {
        console.log(`MyComponent rendered `);
    });

  return (
      
        <div className='flex flex-wrap ml-[-7px]'>
        
                <div className={`${params.ownerId ? '' :'bg-hover-transparent'} w-max  rounded cursor-pointer`} onClick={() => handleSelectOwner(owner)}>
                    <OwnerWithWebsite active={!!params?.ownerId} owner={owner}/>
                </div>
            
            <div>
             

            </div>
        
        </div>
)
}, (prevProps, nextProps) => {
    return prevProps.owner.id === nextProps.owner.id && 
           prevProps.suffix === nextProps.suffix;
});
export default Owner
