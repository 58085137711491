import React, { useEffect, useState } from 'react'
import StepsButtons from '../../../components/stepsButtons'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'antd';
import { useSignupContext } from '../../../context/SignupContext';

export default function Terms() {
    const navigate = useNavigate()
    const [accepted, setaccepted] = useState(false);
    const { setCurrentStep} = useSignupContext()
    useEffect(() => {
        
      setCurrentStep(7)
    }, []);
  return (
       <div className='space-y-2 h-full flex flex-col relative'>
           <div className="relative overflow-hidden flex-1 flex flex-col justify-center space-y-[100px]">
               <div className='w-full space-x-[150px] flex justify-center'>
                   <div className='flex-1 max-w-[500px] space-y-8 text-center'>
                       <div>
                           <p className="title-level2 ">Lorem ipsum dolor</p>
                           <p className="text-level2  ">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. At similique    
                           </p>
   
                       </div>
                       <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore temporibus, ducimus sint possimus, ipsum delectus saepe exercitationem iure autem distinctio recusandae doloribus illo fugit magnam laboriosam omnis soluta earum officia!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi eaque totam assumenda unde ipsum ut nihil numquam exercitationem labore reprehenderit id enim, magni, debitis rerum eos ipsam placeat eligendi quaerat.
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam minus aspernatur provident enim similique rem eum voluptas, optio magnam dolorum repudiandae illo rerum, nobis voluptatem recusandae sed necessitatibus. Voluptatem, dolorum.
                       </p>
                    
                   </div>
               </div>
               <div className='space-y-5'>
                    <div className="flex justify-center">
                        <Checkbox checked={accepted} onChange={e => setaccepted(e.target.checked)}>I have read and agree to the terms antd conditions</Checkbox>

                    </div>
                    <StepsButtons
                        nextStep={() => navigate('/connect/signup/steps/8')}
                        disable={!accepted}
                    
                    />

               </div>
           </div>
       </div>
  )
}
