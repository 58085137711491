import React, { useEffect, useState } from 'react';
import { Highlight, Publication } from '../../../types';
import { searchPublications } from '../../../services/search/search';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';
import { useSearchContext } from '../context/searchContext';

// Debounce Hook


export default function useGetPublications({
  id,
  limit,
  sponsored,
  groupBy,
  mode,
  extraFilter,
  partner,
}: {
  id: string;
  limit?: number;
  sponsored?: boolean;
  groupBy: boolean;
  mode?: 'roots' | 'volume';
  extraFilter?: string;
  partner?: boolean;
}) {
  const [publications, setPublications] = useState<undefined | { document: Publication; highlight: Highlight }[]>(undefined);
  const { query, selectedPubType, setTotalHits, setGroupBy, pagination, setPaginate, setSelectedPubType } = useSearchContext();
  const [newRequest, setNewRequest] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
 
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);
  const debouncedSelectedPubType = useDebounce(selectedPubType, QUERY_DEBOUNCE_DELAY);
  const debouncedPagination = useDebounce(pagination, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      if (id) {
        const req = await fetch(searchDocuments('publications'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: searchPublications({
              q: debouncedQuery,
              volumeId: id,
              type: { value: debouncedSelectedPubType.value, type: debouncedSelectedPubType.type },
              limit: limit && !(debouncedSelectedPubType.value || debouncedQuery) ? limit : debouncedPagination.limit,
              offset: debouncedPagination.offset,
              page: debouncedPagination.page,
              mode,
              sponsored,
              groupBy: groupBy ? 'publicationGroupe.id, publicationGroupe.title.en' : undefined,
              extraFilter,
            }),
          }),
        });

        const res: any = await req.json();

        if (res.data.grouped_hits) {
          setGroupBy({ paidPubs: true });
          setPublications(res.data?.grouped_hits);
          if (partner) {
            setTotalHits({ partnerPubs: res.data.found_docs });
          } else {
            setTotalHits({ pubs: res.data.found_docs });
          }
        } else {
          setGroupBy({ paidPubs: false });
          setPublications(res.data?.hits);
          if (partner) {
            setTotalHits({ partnerPubs: res.data.found });
          } else {
            setTotalHits({ pubs: res.data.found });
          }
        }

        if (debouncedSelectedPubType.value) {
          setTotalHits({ webresources: null });
        }
      } else {
        setPublications([]);
      }
    })();
  }, [
    id,
    debouncedQuery,
    debouncedSelectedPubType.value,
    debouncedSelectedPubType.type,
    groupBy,
    debouncedPagination.offset,
    debouncedPagination.page,
    newRequest,
    mode,
    extraFilter,
    partner,
  ]);

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setNewRequest((prev) => prev + 1);
      setIsLoaded(true);
    }, 100);
  }, [pagination.limit, groupBy]);

  useEffect(() => {
    setSelectedPubType({ type: 'type', value: '', icon: '', title: '' });
    setPaginate({ page: 1, offset: 0 });
  }, [groupBy]);

  return {
    publications,
    isLoaded,
  };
}
