import React from 'react'

type Props = {
    nextStep? : () => void,
    prevStep?:() => void,
    disable: boolean,
    submit?:boolean
}
export default function StepsButtons({ nextStep, prevStep, disable , submit } : Props) {
  return (
    <div className='flex space-x-3 justify-center'>
            <button className='w-[100px] justify-center input-h flex items-center rounded bg-sub-windows disabled:opacity-40 border border-black ' disabled={!prevStep} onClick={prevStep}>Previous</button>
        
        <button className='w-[100px] justify-center input-h flex items-center rounded bg-sub-windows disabled:opacity-40 border border-black' type={submit ? 'submit' : 'button'} disabled={disable} onClick={nextStep ? nextStep : () => {}}>Next</button>
    </div>
  )
}
