import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'


import useGetPublications from '../../../hooks/useGetPublications'
import useGetPartners from '../../../hooks/useGetPartners'
import LocationLayout from '../../../components/locationLayout'
import useGetWebResources from '../../../hooks/useGetWebResources'
import { useSearchContext } from '../../../context/searchContext'
import useSearchLocations from '../../../hooks/useSearchLocations'


export default function PublicDrive({coll}:{coll: 'RegionalServers'| 'ThematicServers' | 'DepartmentDrive'}) {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { groupBy } = useSearchContext()
    const [searchParams] = useSearchParams();
    const { publications, isLoaded } = useGetPublications({id:params.id!, limit: 6, groupBy: !!searchParams.get('grouped')})  
    const { webresources, isLoaded:isLoaded2 } = useGetWebResources({id:params.id!, groupBy:true})  
    const { searchResults } = useSearchLocations({query: '*', filter: `&& roots:=${params.id} && id:!=${params.id}`, sort: 'created_at:desc', limit: 6})
    const { partners, sponsors} = useGetPartners({id:params.id!, coll, setloading:setloading})


   
    
    useEffect(() => {
      (async () => {
        if( params.id ){
          setloading(true)
         
          // const res:any = await searchChildLocations({parentId:params.id})
          // setavailableLocations(res.hits)
          setloading(false)

        }

      })()
     
  }, [params.id])

  
  return (
        <LocationLayout
            layoutProps={{
                loading,
                selectedKey:params.id!,
                disabled:{filter:false, query:false, display:false, new:false},
                publicationBar:true,
                collection:coll!,
                id:params.id!
            }}
            allowSearch={true}
            publications={publications}
            sponsors={sponsors}
            partners={null}
            availableLocations={searchResults}
            suffix={`${params.serverId}/${coll}/${params.id}/`}
            groupBy={groupBy}
            webResources={webresources}
            isLoaded={isLoaded && isLoaded2}

        />
      

    
  )
}
