import PaginationComponent from "../../../components/shared/pagination";
import { useSearchContext } from "../context/searchContext";
import { useUIContext } from "../context/UIContext";

export default function Footer() {
  const {
    totalHits,
    pagination,
  
    setPaginate,
    groupBy,
    selectedPubType
  } = useSearchContext()
  const { disabled, hidePanel } = useUIContext()

 
 

  return (
    
      <PaginationComponent
          disabled={disabled.query}
          pagination={pagination}
          totalHits={totalHits}    
          hidePanel={hidePanel}
          setPaginate={setPaginate}
          allowPaginate={{
            webresources: !groupBy.webresources,
            pubs: !groupBy.paidPubs && !!selectedPubType.value
          }}
      />
    
  
  )
}
