import React from 'react'
import ReactSvg from '../../../../components/shared/ReactSvg'

type Props = {
    total_servers:number,
    total_publications:number,
    organizations:number
}
export default function Stats({total_servers, total_publications, organizations}:Props) {
  return (
    <div className="space-y-3 max-w-[450px] ">
        <div className="grid grid-cols-2 space-x-3 w-max">
            <div
                className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px]`}
            >
                <div className='flex space-x-3  '>
                <div className="relative w-[40px] h-[40px] mt-[4px]">
                    <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Ftree%2Fcluster.svg?alt=media&token=c4e9e01f-3a49-4b23-aa07-6db0b6e5c1bc`} className="w-full h-full"/>
                </div>
            
                <div className="flex-1 ">
                
                    <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{total_servers}</p>
                        <p className='ellipsis-2 leading-[16px] text-muted'>Search engines: Servers, Drives, Directories, Folders</p>
                </div>
            
                </div>
                
            </div>
            <div
                className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px] `}
            >
                <div className='flex space-x-3  '>
                <div className="relative w-[40px] h-[40px] mt-[4px]">
                    <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fpublications%2Fentreprise.svg?alt=media&token=9e92e6f7-8ad0-425d-9dad-49e7b91cfb8b`} className="w-full h-full"/>
                </div>
            
                <div className="flex-1 ">
                
                        <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{organizations}</p>
                        <p className='ellipsis-2 leading-[16px] text-muted'>Companies, organizations and people connected</p>
            
                
        
                
                </div>
            
                </div>
                
            </div>

        </div>
        <div
                className={` py-2 px-3 rounded flex justify-between cursor-pointer group max-w-[220px]`}
            >
                <div className='flex space-x-3  '>
                    <div className="relative w-[40px] h-[40px] mt-[4px]">
                        <ReactSvg src={`https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fpublications%2Fcalendar.svg?alt=media&token=145a9587-8372-438b-bdce-dbeebf9f59b1`} className="w-full h-full"/>
                    </div>
            
                <div className="flex-1 ">
                
                        <p className='ellipsis-1 max-w-full flex-1 text-start font-bold'>{total_publications}</p>
                        <p className='ellipsis-2 leading-[16px] text-muted'>Publications: Webpages, products sheets, job offers, events, ...</p>
            
                
        
                
                </div>
            
                </div>
                
            </div>
    </div>
  )
}
