import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.48749 14.6125V15.6125"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.48749 14.6125V15.6125"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 7.13751H18.0125"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 6.63751V7.63751"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 6.63751V7.63751"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={3}
      y={3.125}
      width={18}
      height={8}
      rx={1.5}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 19.125H4.5C3.67157 19.125 3 18.4534 3 17.625V12.625C3 11.7966 3.67157 11.125 4.5 11.125"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.138 21.883L17.205 21.322C17.502 21.166 17.857 21.166 18.154 21.322L19.221 21.883C19.565 22.064 19.981 22.034 20.295 21.805L20.693 21.516C21.007 21.288 21.164 20.901 21.099 20.519L20.895 19.331C20.838 19 20.948 18.663 21.188 18.428L22.051 17.587C22.329 17.316 22.429 16.911 22.309 16.542L22.157 16.074C22.037 15.705 21.718 15.436 21.334 15.38L20.141 15.207C19.809 15.159 19.522 14.95 19.373 14.649L18.839 13.568C18.668 13.22 18.313 13 17.925 13H17.433C17.045 13 16.69 13.22 16.519 13.568L15.986 14.649C15.837 14.95 15.55 15.159 15.218 15.207L14.025 15.38C13.641 15.436 13.322 15.705 13.202 16.074L13.05 16.542C12.93 16.911 13.03 17.316 13.308 17.587L14.171 18.428C14.411 18.662 14.521 19 14.464 19.331L14.26 20.519C14.194 20.902 14.352 21.288 14.666 21.516L15.064 21.805C15.378 22.033 15.794 22.063 16.138 21.883V21.883Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
