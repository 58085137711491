import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Popover, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { Tier } from '../../../../../types';
import { showTier, subscrib } from '../../../../../services/api/request/plans';
import { fetchInt } from '../../../../../utils/api/fetchInt';

import TierComponent, { features } from '../../../components/Tier';
import { useAuthContext } from '../../../../../context/AuthContext';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';


export default function SubscribePage() {
    const [autoRenewale, setautoRenewale] = useState(true);
    const [loading, setloading] = useState(false);
    const [tier, settier] = useState<Tier | null>(null);
    const { user } = useAuthContext()
    const { profile, planId, organizationData, setCurrentStep, accountType } = useSignupContext()
    const navigate = useNavigate()

    useEffect(() => {
    
        setCurrentStep(8)
      }, []);
    useEffect(() => {
        setloading(true)
        fetch(showTier(profile.id, planId)).then(res => res.json()).then(data => settier({...data.data.tierData})).catch(err => console.log(err)).finally(() => setloading(false))
    }, [planId, profile.id]);
    const prevStep = () => {
        navigate(`/connect/signup/steps/6/${accountType === 'INDIVIDUAL' ? '/individual' : 'organization'}`)
    }
    
    const submit = async () => {
        const submittedData = {
            ...organizationData,
            adminIntrests : organizationData?.adminIntrests? organizationData.adminIntrests.map((i:any) => i.label) : [],
            planId: profile.id, 
            tierId:planId,
            isAutoRenewal: autoRenewale
        }

        const req = await fetchInt(subscrib, {
            method: 'POST', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(submittedData)
        })
        const res = await req.json()
        if(res.data){
            // setpaymetUrl(res.data.paymentUrl)
            // openPageViewer({type: 'payment', data:res.data.paymentUrl, title: 'Payment', icon:{src: 's', type: 'icon'}})
            window.open(res.data.paymentUrl, '_blank')
            
        }
        console.log(res)
    }
  return (
    <div className='lex-1 h-full flex flex-col space-y-2'>
        <div className="flex-1 p-4 flex flex-col space-y-[120px] justify-center items-center">
            <div className='w-full flex flex-col items-center'>
                <p className="label-level2">{profile?.name}</p>
                
                <p className="title-level2 text-center">Order summury
                </p>
                <p className="text-level2 text-center max-w-[400px]">Check your informations before paying</p>

            </div>
            <div className='w-max space-y-3'>
                <div className="flex space-x-14 mt-5 ml-8 w-max">
                    {tier && (
                         <TierComponent key={tier.id} data={tier} editTier={() => {}} noActions active={tier.id === planId}/>

                    )}
                    <div className='space-y-5 border border-gray-500 rounded w-[500px] p-5'>
                        <div>
                            <p className="font-bold">Client infos</p>
                        </div>
                        {/* <div className='space-y-3'>
                            <p className='font-bold'>Auto renewale</p>
                            <Switch checked={autoRenewale} onChange={v => setautoRenewale(v)} />
                        </div> */}
                        <div className="space-y-3">
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end"></p>
                                    <img src={organizationData?.organizationLogo} alt="" className='w-[220px] h-[220px] rounded'/>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Client name</p>
                                    <p>{user?.name}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Client email</p>
                                    <p>{user?.email}</p>
                                </div>
                                
                                {/* <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">City:</p>
                                    <p>{user?.adminCity}</p>
                                </div> */}
                            <div className='space-y-3'>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">organization name</p>
                                    <p>{organizationData?.organizationName}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Activity</p>
                                    <p className='flex-1 truncate'>{organizationData?.organizationActivity}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Email</p>
                                    <p className='flex-1 truncate'>{organizationData?.organizationEmail}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Phone</p>
                                    <p className='flex-1 truncate'>{organizationData?.organizationPhone}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Website</p>
                                    <p className='flex-1 truncate'>{organizationData?.organizationWebsite}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <p className="font-bold w-[120px] text-end">Auto renewal</p>
                                    <div className='flex-1'>
                                        <Switch checked={autoRenewale} onChange={v => setautoRenewale(v)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                  

                    </div>
                </div>
                <div className='w-max ml-auto'>
                    <Spin spinning={loading}>
                        <button
                            disabled={loading}
                            onClick={submit}
                            className='px-7 py-2 rounded bg-skin-fill-inverted text-skin-inverted'
                        >
                            PAY 
                        </button>

                    </Spin>

                </div>

            </div>

        </div>
        {/* <Payment
            open={!!paymentUrl}
            onClose={() => setpaymetUrl('')}
            url={paymentUrl}
        /> */}
        {/* <StepsButtons
            prevStep={prevStep}
            disable={false}
        
        /> */}
    </div>
  )
}
