import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, notification } from 'antd'



export default function ConnectLayout() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams.get('EmailValidated') === 'true'){
      notification.success({
        type:'success',
        message:'Email validated successfully'
      })
    }
  }, [searchParams]);
  return (
 
        <div className='h-full flex flex-col rounded'>
      
            <div className='flex-1  relative'>
                <Outlet/>
            </div>
        </div>

 
  )
}
