import React from 'react'

export default function TextBlock() {
  return (
    <div className='absolute z-[20] p-6 bottom-[50px] left-[20px] w-[400px] h-max bg-white rounded-lg teddra-shadow'>
        {/* <p className="title-level2">Lorem ipsum dolor</p> */}
        <p className="text-[20px]">Access to thousands of Qorddu's search engines by exploring the map or searching by keywords.
        </p>
        
    </div>
  )
}
