import React from 'react'
import ObjectMd from '../../../../../../components/shared/object/objectMd'
import Icon from '../../../../../../components/icons/Icon'

export default function UrlLists() {
  return (
    <div className='px-4 py-7 space-y-2'>
        {/* <button className='block bg-skin-fill-inverted text-skin-inverted py-2 w-full text-center rounded'>Start crawling my website</button> */}
        <button className='flex space-x-2 justify-center border border-main py-2 w-full text-center rounded'>
            <Icon className='icon-md' name='PlusIcon'/>
            <p>Add a new page</p>
        </button>
        <div className="space-y-2">
            <ObjectMd
                title='Page title 1'
                description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatem.'}
                icon={<Icon className='icon-md' name='WebPage'/>}
                onSelect={() => {}}
            />
            <ObjectMd
                title='Page title 2'
                description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatem.'}
                icon={<Icon className='icon-md' name='WebPage'/>}
                onSelect={() => {}}
            />
            <ObjectMd
                title='Page title 3'
                description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatem.'}
                icon={<Icon className='icon-md' name='WebPage'/>}
                onSelect={() => {}}
            />
            <ObjectMd
                title='Page title 4'
                description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatem.'}
                icon={<Icon className='icon-md' name='WebPage'/>}
                onSelect={() => {}}
            />
            <ObjectMd
                title='Page title 5'
                description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, voluptatem.'}
                icon={<Icon className='icon-md' name='WebPage'/>}
                onSelect={() => {}}
            />
        </div>
    </div>
  )
}
