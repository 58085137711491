import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import useFetchVolum from '../../../../hooks/useFetchVolum'
import { useSearchContext } from '../../context/searchContext'
import { useUIContext } from '../../context/UIContext'
import { useQfsContext } from '../../context/qfsContext'
import { useAppContext } from '../../../../context/AppContext'

type Props = {
    loading:any 
    children:JSX.Element,
    selectedKey:string,
    disabled:{filter:boolean, display:boolean, new:boolean,query:boolean},
    publicationBar:boolean,
    collection:string,
    id:string
}
export default function LocationgGlobalLayout(props : Props) {
    const { clearSearch } = useSearchContext()
    const { setdisable } = useUIContext()
    const { setCurrentLocation } = useQfsContext()
    const {domain} = useAppContext()
    const { volume, loading} = useFetchVolum(props.id, props.collection, domain?.id)
    useEffect(() => {
      clearSearch()
      // setselectedKey(props.selectedKey)
      
      setdisable({query:props.disabled.query, filter:props.disabled.filter, display:props.disabled.display, publicationBar:!props.publicationBar})
    }, [props.selectedKey])

    useEffect(() => {
      if(volume){
        const clusterId = volume.routes?.filter((v:any) => v.depth ===2)?.[0]?.id
        let routes
        if(volume.routes){
          routes = volume.routes.map((route:any) => {
            if(route.depth>2){
              route.path = `/${clusterId}${route.path}`
              
            }
            return route
          })
         
        }
        setCurrentLocation({...volume, clusterId: clusterId??null, isDomain: volume.id === domain?.id, routes: routes??volume.routes, title: clusterId === volume.id ? `${domain?.title} - ${volume.title}` : volume.title })

      }
      
    }, [volume?.id]);
  return (
    <Spin spinning={props.loading || loading}>
      <div className='flex  '>
        <div className='flex-1 flex flex-col'>
          <div className="flex-1" >
            {props.children}
          

          </div>
        </div>
        <div className='w-[33%] flex flex-col'>
          <div className='flex-1'>
            <Outlet/>

          </div>
        </div>
      </div>
    
    </Spin>
  )
}
