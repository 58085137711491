import React, { useEffect, useState } from 'react'
import { Spin } from 'antd';
import StepsButtons from '../../../components/stepsButtons';
import Plan from '../../../components/Plan';
import { allPlans } from '../../../../../services/api/request/plans';
import { Plans } from '../../../../../types';
import { useSignupContext } from '../../../context/SignupContext';
import { useNavigate } from 'react-router-dom';



export default function Profile() {
    const [plans, setplans] = useState<Plans[]>([]);
    const [loading, setloading] = useState(false);
    const { setprofile, profile, setCurrentStep } = useSignupContext()
    const navigate = useNavigate()
 
    useEffect(() => {
    
      setCurrentStep(2)
    }, []);
    
    useEffect(() => {
        fetch(allPlans).then(res => res.json()).then(data => setplans(data.data.reverse().map((p:any) => ({id:p.id,...p.planData})))).finally(() => setloading(false)).catch(err => console.log(err))
    }, []);
  return (
    <div className='space-y-2 h-full flex flex-col'>
        <Spin spinning={loading}>
          <div className='flex flex-col h-full rounded p-4'>
                <div className="flex-1 h-full flex flex-col justify-center space-y-[100px]">
                      <div className='w-full flex flex-col items-center'>
                        
                        <p className="title-level2 text-center">Choose your profile
                        </p>
                        <p className="text-level2 text-center max-w-[400px]">Do you suscribe for you, for your company, for your comunity... ?</p>

                      </div>
                        
                 
                      
                      <div className='flex gap-8 flex-wrap justify-center w-full'>
                          {plans.map(plan => (
                              <Plan editPlan={() => {}} key={plan.id} plan={{...plan,}} handleNavigate={(id) => {
                                setprofile({id, name:plan.title.en})
                              }} active={profile.id === plan.id} noActions />
                          ))}
                      </div>
                    <div className='flex justify-center'>
                      <StepsButtons
                          nextStep={() => navigate('/connect/signup/steps/3')}
                          prevStep={() => navigate('/connect/signup/steps/1')}
                          disable={!profile.id}
                      />

                    </div>
                </div>

          </div>
        </Spin>
        
        
      
    </div>
  )
}
