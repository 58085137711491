import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import SingleServerLine from '../../../components/SingleServerLine';
import WithDivider from '../../../../../components/shared/WithDivider';
import { getDomainsAndFeaturedDcs } from '../../../../../services/api/request/network';
import { Server } from '../../../../../types';
import PublishInFooter from './Footer';
import Clusters from './clusters';
import Servers from './Servers';

type Props = {
    editData:any,
    submit:() => void,
    selectedLocations:any,
    removeSelectedLocation:(loc:any) => void,
    submitLoading: boolean,
    addSelectedLocation:(data:Server) => void,
    footerTitle:string

}
export default function PublishInLayout({ editData, submit, submitLoading, selectedLocations, removeSelectedLocation, addSelectedLocation, footerTitle }:Props) {
    const [domains, setdomains] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
    const [selectedNetwork, setselectedNetwork] = useState<Server | null>(null);
    const [selectedCluster, setselectedCluster] = useState<Server | null>(null);
    const [selectedServer, setselectedServer] = useState<Server | null>(null);
    useEffect(() => {
        setloading(true)
        fetch(getDomainsAndFeaturedDcs).then(res => res.json()).then(data => setdomains(data.data)).finally(() => setloading(false))

        
    }, [])
    useEffect(() => {
       setselectedCluster(null)
    }, [selectedNetwork])
    useEffect(() => {
        setselectedServer(null)
     }, [selectedCluster])
  return (
        <div className="h-full">
            <div className="grid grid-cols-4 bar-h pl-[35px]">
                <WithDivider>
                    <p>Domains</p>
                </WithDivider>
                <WithDivider>
                    <p>Clusters</p>
                </WithDivider>
                <WithDivider>
                    <p>Servers</p>
                </WithDivider>
                <WithDivider>
                    <p>Drives</p>
                </WithDivider>
            </div>
            <div className='flex flex-col h-full'>
                <div className="pl-[35px] grid grid-cols-4 border-main border-b">
                    <Spin spinning={loading}>
                        <div className='height-publishin overflow-auto pl-3 space-y-7'>
                            {domains?.map(domain => (
                                <div key={domain.id} className='space-y-2'>
                                    <p className='text-group'>{domain.title}</p>
                                    <div className="-ml-2">
                                        {domain.children?.map(network => (
                                            <SingleServerLine item={network} domain key={network.id} active={network.id === selectedNetwork?.id} clickFn={() => setselectedNetwork(network)}/>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </Spin>
                    <div>
                        {selectedNetwork && (
                            <Clusters
                                selectedNetworkId={selectedNetwork.id}
                                selectedClusterId={selectedCluster? selectedCluster.id : ''}
                                onSelectCluster={(cluster:Server) => setselectedCluster(cluster)}
                            />
                        )}
                        
                    </div>
                    <div>
                        {selectedCluster && selectedNetwork && (
                            <Servers
                                selectedClusterId={selectedCluster.id}
                                selectedServerId={selectedServer ? selectedServer.id : ''}
                                onSelectServer={(server:Server) => setselectedServer(server)}
                                path={`/${selectedNetwork.title}/${selectedCluster.title}`}
                                selectedLocations={selectedLocations}
                                removeSelectedLocation={removeSelectedLocation}
                                addSelectedLocation={addSelectedLocation}
                            />
                        )}
                        
                    </div>
                    <div>

                    </div>
                </div>
                <div className="flex-1">
                    <PublishInFooter
                        data={{title:editData.title, type:''}}
                        submit={submit}
                        loading={submitLoading}
                        selectedLocations={selectedLocations}
                        removeSelectedLocation={removeSelectedLocation}
                        title={footerTitle}
                    />

                </div>
            </div>
        </div>
  )
}
