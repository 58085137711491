import { message, notification, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import StepsButtons from '../../../components/stepsButtons'
// import { getAuth, sendEmailVerification } from 'firebase/auth'
import Icon from '../../../../../components/icons/Icon'
import { useAuthContext } from '../../../../../context/AuthContext'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { emailUrl } from './createAccount'
import { useSignupContext } from '../../../context/SignupContext'
import { useNavigate } from 'react-router-dom'




export default function Vlidate() {
    // const dispatch = useDispatch<AppThunkDispatch>()
    const [loading, setloading] = useState(false)
    const { user, setUser } = useAuthContext()
    const { planId, profile, accountType, setCurrentStep } = useSignupContext()
    const navigate = useNavigate()
    useEffect(() => {
      setCurrentStep(5)
    }, []);
    const prevStep = () => {
        navigate('/connect/signup/steps/4')
    }
    const nextStep = () => {
        navigate(`/connect/signup/steps/6${accountType === 'INDIVIDUAL' ? '/individual' : '/organization'}`)
    }
    useEffect(() => {
        if(user?.emailVerified){
            notification.open({
                message: 'Email verified succesufully'
            })
            nextStep()
        }
        
    }, [user?.emailVerified]);
    
    const resendEmail = async () => {
        const authUser = getAuth().currentUser;
            if (authUser) {
                setloading(true)
                try {
                    const actionCodeSettings = {
                        url: emailUrl(profile.id, profile.name, planId, accountType),
                        handleCodeInApp: false, // Whether the link should be handled in the web app
                      };
                    await sendEmailVerification(authUser,actionCodeSettings);
                    notification.success({ message: "Email resent successfully" });
                } catch {
                    notification.error({ message: "Something went wrong" });
                }finally{
                    setloading(false)
                }
            }
      }
    const emailVerified = async () => {
        const authUser = getAuth().currentUser
        await authUser?.reload();
        if(!authUser?.emailVerified){
            message.error('Email is not yet verified')
            return
        }
        setUser({id:authUser.uid, name: authUser.displayName!, email:authUser.email!, picture: authUser.photoURL!, emailVerified: authUser.emailVerified  })
       
    }
  return (
    <div className="flex flex-col h-full space-y-2">
        <div className='flex-1 flex flex-col w-full items-center justify-center space-y-[100px]'>
            <div className='w-full flex flex-col items-center'>
                <p className="label-level2">{profile?.name}</p>
                <p className="title-level2 text-center">Verify Email
                </p>
                <p className="text-level2 text-center max-w-[400px]">check your email to activate your account </p>

            </div>
            <div className="flex flex-col justify-center items-center">
                <div className='flex flex-col justify-center items-center w-max h-max p-10 rounded'>
                    <Icon name={'EmailSent'} className="mx-auto w-[100px] h-[100px] fill-transparent"/>
                     
                    <div className="space-y-3 mt-8">
                       
                        <button className="bg-skin-fill-inverted text-skin-inverted px-4 py-1.5 rounded block mx-auto w-[120px]" disabled={loading} onClick={resendEmail} >Resend email</button>
                       
                        <button className="bg-skin-fill-muted  px-4 py-1.5 rounded block mx-auto w-[200px]" onClick={emailVerified}>Email already verified?</button>
                    </div>

                </div>

            </div>
            <StepsButtons
            nextStep={nextStep}
            prevStep={prevStep}

            // disable={!data?.name || !data.raisonSocial || !data.duns || !data.activity || !data.activityCode || !data.webResources?.website || !data.address || !data.address.city || !data.address.country || !data.address.zipcode || !data.address.line1 || !data.email}
            disable={false}
            submit
        />

        </div>
    </div>
  )
}
