import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MydeskContextProvider } from './context/MydeskContext'
import MydeskLayout from './Layout'
import MydeskHome from './pages/home'

import Settings from './pages/account/Settings'
import Myvolumes from './pages/volumes'
import CreateFolder from './pages/volumes/create/Folder'
import CreateVolumeLayout from './pages/create/volume/layout'
import EditVolume from './pages/create/volume'
import { VolumeCreationContextProvider } from './context/volumeCreationContext'
import PublishVolume from './pages/create/volume/publish'

import { PublicationCreationContextProvider } from './context/PublicationCreationContext'
import CreatePublicationLayout from './pages/create/publications/layout'
import EditPublication from './pages/create/publications'
import PublishPublication from './pages/create/publications/publish'


export default function MydeskNavigation() {
 
  return (
    <MydeskContextProvider>
      <Routes>
        {/* <Route element={<PrivateRoute />}> */}
          <Route element={<MydeskLayout/>}>
              <Route path='/account/settings' element={<Settings/>}/>
              <Route path='/my-volumes' element={<Myvolumes/>}/>
              <Route path='/my-volumes/folder' element={<CreateFolder/>}/>



              
              {/* <Route element={<PinnedContextProvider><PinnedLayout/></PinnedContextProvider>}>
                <Route element={<PublicationsPage/>}>
                  <Route element={<></>} path='/pinned/:dcId/publications/all' />
                  <Route element={<PublicationDetailsPage/>} path='/pinned/:dcId/publications/all/:publicationId' />
                </Route>
                <Route element={<VolumesPage/>}>
                  <Route element={<></>} path='/pinned/:dcId/volumes/all' />
                  <Route element={<VolumesDetailsPage/>} path='/pinned/:dcId/volumes/all/:volumeId' />
                </Route>
                <Route element={<PinnedResources/>}>
                    <Route path='/pinned/:dcId' element={<></>}></Route>
                    <Route element={<PublicationDetailsPage/>} path='/pinned/:dcId/publications/:publicationId' />
                    <Route element={<VolumesDetailsPage/>} path='/pinned/:dcId/volumes/:volumeId' />
                </Route>
              </Route> */}

              <Route element={<MydeskHome/>} path='/'/>
            
              {/* <Route element={<Ed/>} path='/publications/new/:publicationType'/> */}


              <Route path='/*' element={<></>}></Route>

          </Route>
          <Route element={<VolumeCreationContextProvider><CreateVolumeLayout/></VolumeCreationContextProvider>}>
            <Route element={<EditVolume/>} path='/volumes/create/:type/edit'/>
            <Route element={<PublishVolume/>}>
              <Route element={<></>} path='/volumes/create/:type/publish'/>
              
            </Route>
          </Route>

          <Route element={<PublicationCreationContextProvider><CreatePublicationLayout/></PublicationCreationContextProvider>}>
            <Route element={<EditPublication/>} path='/publications/create/edit'/>
              <Route element={<PublishPublication/>}>
                <Route element={<></>} path='/publications/create/publish'/>
             
              </Route>
          </Route>
        {/* </Route> */}

      </Routes>

    </MydeskContextProvider>
  )
}
