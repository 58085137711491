import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';






interface State {
  run: boolean;
  steps: Step[];
}



export default function Tutorial() {
  const [{ run, steps }, setState] = useState<State>({
    run: false,
    
    steps: [
    
        {
          content: <h2>Here you can search publications</h2>,
          placement: 'bottom',
          styles: {
            options: {
              width: 300,
            }, 
          },
          target: '#tutorial-search',
          title: 'Search', 
          disableBeacon:true
        },
      {
        content: <h2>Here you can explore qorddu volumes</h2>,
        placement:'auto',
        target: '#tutorial-sidebar',
        title: 'Explore',
        
      },
     
    ],
    
  });

 
    useEffect(() => {
        
        setState(prev => ({
            ...prev,
        run: true,
        }));
    }, []);

  

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
        setState(prev => ({
            ...prev,
          run: false,
        }));
        localStorage.setItem('tutorialDone', 'true')
      
    }

  };

  return (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={true}
        scrollToFirstStep
        showProgress
        spotlightPadding={3}
        
        showSkipButton
        steps={steps}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 36,
            overlayColor: 'rgba(0, 0, 0, 0)',
            primaryColor: '#000',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          
            textColor: '#fff',
            width: undefined,
            zIndex: 100,
            },
        }}
      />
    
  );
}
