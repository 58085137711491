import React from 'react'
import Icon from '../../../../components/icons/Icon'

type Props={
    handleSelect: (value:string) => void,
    volumes: {
        name:string,
        icon:string,
        disabled?:boolean,
        code:string
    }[]
}
export default function VolumesDropdown({ handleSelect, volumes } : Props) {
  return (
    <div className='relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter'>
        {volumes.map(volume => (
            <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={volume.disabled} onClick={() => handleSelect(volume.code)}>
                <div className='flex items-center'>
                    <Icon name={volume.icon} className='icon-sm ant-dropdown-menu-item-icon'/>
                    <p className='ant-dropdown-menu-title-content'>{volume.name}</p>
                </div>
            </button>
        ))}
        
    </div> 
  )
}
