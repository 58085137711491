import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context/AppContext'
import { Modal } from 'antd';
import Icon from '../../../components/icons/Icon';
import { useSearchParams } from 'react-router-dom';

type Props = {
    open:boolean
    setOpen:(open:boolean) => void
}
export default function SplashScreen({open, setOpen}:Props) {
    const { domain } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        
        if(domain?.id && !searchParams.get('subscription')){
            setOpen(true)
        }
    }, [domain?.id]);
  return (
    <Modal
        visible={open}
        onCancel={() => setOpen(false)}
        destroyOnClose
        footer={null}
        width={1130}
        closeIcon={
            <button className='rounded p-1 bg-white'>
                <Icon className='icon-sm' name='Close'/>
            </button>
        }
    >
        <div className="relative">
            <div className=' flex items-center space-x-8 w-full h-[700px] rounded-[15px] relative px-[70px]'
            
            >
                <div 
                    className=' max-w-[49%] h-full rounded-[15px] flex flex-col justify-between pt-[180px] pb-[40px] space-y-14'
                    
                >
                    <div className=''>
                        <p className='text-[41px] leading-[48px]'>
                            <span className='bg-skin-fill-inverted font-bold text-skin-inverted p-5 w-max'>{domain?.title.toLocaleLowerCase()}</span>
                            <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                            <span className=''>qorddu</span>
                            <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                            <span>com</span>

                        </p>
                        <p className='text-[21px] ml-[107px] mt-1  font-light leading-[29px]'>
                            {/* {domain?.description} */}
                            Global search engine network dedicated to information and communication technologies
                        </p>
                    

                    </div>
                    <div className='ml-[107px]'>
                        <p className=' text-[16px]'>Main partners</p>
                        <div className="flex flex-wrap gap-8 items-center">
                            <div>
                                <div className='w-[40px]  ' >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-dev.appspot.com/o/partners%2Flogo%2F537e3d66-f21b-418a-9bc3-509d64b0c783?alt=media&token=d5504d60-ab7b-45cc-a182-2240daf68f90" className='w-full h-auto' alt="" style={{filter:'grayscale(1)'}}/>

                                </div>
                                {/* <p>France</p> */}

                            </div>
                            <div>
                                <div className='w-[80px]  ' >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/partners%2Flogo%2F139cd38d-3f3d-47b8-9dc7-3400db151de9?alt=media&token=967156bf-9bba-4a08-b968-e76970c90775" className='w-full h-auto object-cover' alt="" style={{filter:'grayscale(1)'}} />

                                </div>
                                {/* <p>France</p> */}

                            </div>
                            <div>
                                <div className='w-[40px]  ' >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/partners%2Flogo%2Fb4ad43b0-3c59-4d18-bb7f-6b5f976a76be?alt=media&token=b0fdc2f7-c7c5-48b7-8de1-f8a25094d297" className='w-full h-auto' alt="" style={{filter:'grayscale(1)'}} />

                                </div>
                                {/* <p>France</p> */}

                            </div>
                            <div>
                                <div className='w-[40px]  ' >
                                    <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-dev.appspot.com/o/partners%2Flogo%2F39cd5873-37c0-4f5b-9fbd-8f908ec51619?alt=media&token=f97006f4-e0af-4261-aa54-a94dce7db420" className='w-full h-auto' alt="" style={{filter:'grayscale(1)'}} />

                                </div>
                                {/* <p>France</p> */}

                            </div>

                        </div>
                    </div>
                </div>
                <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/homedesqs%2FGroup%201.png?alt=media&token=4f79d01d-370b-4e3a-af09-cf9666fc2117" className='w-[496px] flex-1 h-auto object-cover' alt="" />
                {/* <div className='absolute bottom-10 text-white right-10'>
                    <Icon className='w-[50px] h-[50px]' name='TeddraLogo'/>
                </div> */}
                
            </div>
            <div className="absolute bottom-[53px] right-10">
                    <img className='w-[70px] h-auto' src='/logo.png'/>
            </div>

        </div>
    </Modal>
  )
}
