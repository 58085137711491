export const BASE_PATH='mydesk'
export const volumeCodes = [
    {
         name: 'Server',
         icon:'Server',
         code: '015'
     },
     {
         name: 'Drive',
         icon:'Drive',
         code: '004'
     },
     {
         name: 'Directory',
         icon:'Directory',
         code: '003'
        },
        {
              name: 'Folder',
              icon:'Folder',
              code: '002'
          },
      
]