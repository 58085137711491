import React, { useEffect, useMemo, useState } from 'react'
import { Highlight, OwnerType, PartnerType, Publication, VolumeSearch } from '../../../../types'

import { useHandleLimits } from '../../hooks/useHandleLimits'
import PartnersAndSponsors from './Partners'
import Owner from './Owner'
import AvailableLocations from './AavilableLocations'
import PublicationsLink from './publications/PublicationsLink'
import WebResources from './publications/WebResources'
import { useUIContext } from '../../context/UIContext'
import { useSearchContext } from '../../context/searchContext'
import PartnerPublications from './publications/PartnerPublications'
import Collapse, { Panel } from '../../../../components/shared/Collapse'
import Stats from './Stats'

type Props = {
    availableLocations: {document :VolumeSearch}[] | null,
    suffix: string,
    publications: undefined | {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    partnerPublications?:{document:Publication, highlight:Highlight}[] ,
    isLoaded:boolean,
    sponsors: PartnerType[] | null
    partners : PartnerType[] | null,
    mainSites?:PartnerType[],
    allowSearch: boolean,
    groupBy?:{
      webresources:boolean,
      paidPubs: boolean
    },
    owner?:OwnerType | undefined,
    showApps?:boolean,
    all?:string,
    home?:boolean,
    partnerId?:string,
    partnerName?:string,
    stats?:{
      total_publications:number,
      total_servers:number,
      organizations:number
    }
}


export default function ResourcesLayout({partners, sponsors, availableLocations, suffix,webResources, publications, allowSearch, groupBy, owner, stats, all, isLoaded, partnerPublications, partnerId, partnerName} : Props) {
    const { displayMode, barActivatedFields, setAll} = useUIContext()
    const {query, selectedPubType, totalHits,  } = useSearchContext()
    const [activePanels, setactivePanels] = useState<string |string[]>(['partners', 'locations', 'owner','webresources','publications', 'stats']);
    useHandleLimits({displayMode, disabled: !all && !selectedPubType.value})
    useEffect(() => {
      if(all){
        
        setAll(all)
      }else{
       
        setAll('')
      }
    }, [all]);
   
    const isSearchActive =  !!query || !!selectedPubType.value
    const showPartnersAndSponsors = (!isSearchActive || !allowSearch) && ((partners && partners?.length>0) || (sponsors && sponsors?.length > 0))
    const showOwner = (!isSearchActive || !allowSearch) && owner?.website
    const showStats = (!isSearchActive || !allowSearch) && stats

    const showAvailableLocations = (!isSearchActive  || !allowSearch)&& (
      availableLocations && availableLocations.length > 0)
    const showPublications = isLoaded && allowSearch && publications && publications.length>0
    const showWebResources = isLoaded && webResources && webResources.length>0 && !selectedPubType.value
    const showPartnerPublications = isLoaded && allowSearch && partnerPublications && partnerPublications.length>0 && (!selectedPubType.value || all) && !groupBy?.paidPubs
    useEffect(() => {
      
     if(isSearchActive){
      setactivePanels(prev =>  Array.isArray(prev) ? [...prev, 'publications', 'webresources', 'publications-partner','webresources'] : ['publications', 'webresources', 'publications-partner','webresources'])
     }
    }, [isSearchActive]);
    useEffect(() => {
      
      setactivePanels(['partners', 'locations', 'owner','webresources', 'publications', 'stats'])
    }, [suffix]);

    return (
        <div className="pl-[20px] pt-[14px] overflow-auto height-explorer space-y-[60px]" >
             <Collapse activeKey={activePanels} className='' onChange={(key) => setactivePanels(key)}>
             <>
                {
                  showStats && (
                    <Panel
                      header={<p className='text-groupe'>Stats</p>}
                      key={'stats'}
                    >
                      <div className='ml-3'>
                        <Stats total_publications={stats.total_publications} total_servers={stats.total_servers} organizations={stats.organizations}/>

                      </div>
                    </Panel>
                  )
                }
                {showPartnersAndSponsors && (
                  <Panel
                    header={<p className='text-groupe'>{partners && partners.length > 0 ? 'Partners & Sponsors' : 'Sponsors'}</p>}
                    key={'partners'}
                  >
                    <div className='mb-[40px] ml-[20px] mt-[10px]'>
                      <PartnersAndSponsors
                        partners={partners}
                        sponsors={sponsors}
                        suffix={suffix}
                      />

                    </div>

                  </Panel>


                )}
        
                
                {showOwner && (
                  <Panel
                    header={<p className='text-groupe'>Owner</p>}
                    key={'owner'}
                  >
                    <div className="mb-[40px] ml-[20px] mt-[10px]">
                      <Owner
                        owner={owner}
                        suffix={suffix}
                      />

                    </div>

                </Panel>


                )}
                
                {showAvailableLocations && (
                    <Panel
                    header={<p className='text-groupe'>Recently deployed</p>}
                    key={'locations'}
                  >
                    <div className="mb-[40px] ml-[14px] mt-[2px]">
                      <AvailableLocations
                        availableLocations={availableLocations}
                        setRecheckPin={() => {}}
                        suffix={suffix}
                      />

                    </div>

                  </Panel>


                  )
                }
                {showPartnerPublications && (
                <Panel
                  header={<p className='text-groupe'>{isSearchActive ? `${totalHits.partnerPubs} articles found from ${partnerName}` : `Recently added articles from ${partnerName}`}</p>}
                  key={'publications-partner'}
                >
                  <div className="mb-[40px] ml-[18px] mt-[2px]">
                    <PartnerPublications
                      publications={partnerPublications}
                      totalHits={totalHits}
                      searchon={isSearchActive}
                      suffix={suffix}
                      partnerId={partnerId}
                      setRecheckPin={() => {}}
                      displayMode={displayMode}
                      barActivatedFields={barActivatedFields}
                  
                    />

                  </div>

                </Panel>


                )}
                {showPublications && (
                    <Panel
                    header={<p className='text-groupe'> {groupBy?.paidPubs ? 'Groupes' : (
                      isSearchActive ? `${totalHits.pubs} publications found` : `Recently added publications`
                  )}</p>}
                    key={'publications'}
                  >
                    <div className="mb-[40px] ml-[18px] mt-[2px]">
                      <PublicationsLink
                        publications={publications}
                        webResources={webResources}
                        groupBy={groupBy}
                        totalHits={totalHits}
                        searchon={isSearchActive}
                        selectedPubType={selectedPubType}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                      />
                      {(groupBy?.paidPubs || !groupBy?.webresources || !isSearchActive) && webResources && (
                        <WebResources
                        webResources={webResources}
                        searchon={isSearchActive}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                        groupBy={groupBy}
                        totalHits={totalHits}
                      />
                      )}
                    {/* <p className='italic mt-2'>Seacrh among {totalHits.webresources ?? 0 + (totalHits.pubs??0)} resources in this server</p> */}
                    </div>
                </Panel>
      
                )}
                {showWebResources && (!groupBy?.webresources || isSearchActive) && (!groupBy?.paidPubs || all) && (
                 
                  <Panel
                    header={<p className='text-groupe'>{isSearchActive ? ` ${totalHits.webresources} webpage found` : 'Latest web resources'}</p>}
                    key={'webresources'}
                  >
                    <div className="ml-[18px] mt-[2px]">
                      <WebResources
                        webResources={webResources}
                        searchon={isSearchActive}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                        groupBy={groupBy}
                        totalHits={totalHits}
                      />

                    </div>

                  </Panel>

                  )}
                
             
             </>
              
             
             </Collapse>

      

          
  
        </div>


    )
}
