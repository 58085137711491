import React, { useEffect, useState } from 'react'
import { useSearchContext } from '../context/searchContext';
import { useUIContext } from '../context/UIContext';
import { useQfsContext } from '../context/qfsContext';

import { Dropdown } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';

import ReactSvg from '../../../components/shared/ReactSvg';
import { storageUrl } from '../../../services/api/constants';
import { ButtonLg } from '../../../components/shared/Buttons/ButtonLg';
import TypesDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown';
import useGetPubsTypes from '../hooks/useGetPubsTypes';
import { SearchIcon } from '@heroicons/react/solid';
import DisplayDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/DisplayDropdown';
import UserActionsButton from '../../../components/shared/bars/TopBar/userActionsButton';
import SearchDropdown from '../components/searchDropdown';
import Icon from '../../../components/icons/Icon';

export default function PathBarContainer() {
    const { disabled, hidePanel, setHidePanel, displayMode, setDisplayMode, barActivatedFields, setBarActivatedFields, mode, setObjectDisplayMode} = useUIContext()
    const { currentLocation } = useQfsContext()
    const { selectedPubType, setSelectedPubType, groupBy, query, setQuery, totalHits, } = useSearchContext()
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams()
    const { loading, publicationsTypes } = useGetPubsTypes()
    
    const resourcesLength = (totalHits.pubs??0) + (totalHits.webresources??0)
    const [validQuery, setvalidQuery] = useState('');
    const [openSearchDropdown, setopenSearchDropdown] = useState(false);
    useEffect(() => {
    
        if(validQuery.length > 2){
          setQuery(validQuery)
        }else{
          setQuery('')
        }
      }, [validQuery]);

    const onSelectQuery = (query:string) => {
      setQuery(query)
      setvalidQuery(query)
      setopenSearchDropdown(false)
    }
    return (
    <div className='flex justify-between items-center w-full pr-7'>
      <div className="flex-1 ">
     

           <div className="flex flex-1 space-x-2 bar-h menubar items-center pl-4 pr-2">
              <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                  <Icon name='PanelOpen' className='icon-sm'/>
                </button>
                {currentLocation && (
                  
                    <div className="flex space-x-3 itesm-center">
                      <ReactSvg className='icon-sm' src={`${storageUrl}${currentLocation.icon?.src}`}/>
                      <p className='w-[120px] truncate'>{currentLocation.title}</p>
                    </div>
                )}

                  <div className={`bar-h flex items-center  bg-sub-windows px-5 ${!hidePanel && 'ml-[-9px]'}`}>
                            <div className="flex items-center rounded space-x-2">
                                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                <div className=' px-1 flex items-center ' id='tutorial-search'>
                                    <SearchIcon className='icon-mini'/>
                                    <Dropdown
                                        overlay={<SearchDropdown onSelectQuery={onSelectQuery} query={query}/>} trigger={['click']}
                                        visible={openSearchDropdown}
                                    >
                                        <input 
                                        type="text" 
                                        value={validQuery} 
                                        onChange={e => setvalidQuery(e.target.value)} 
                                        className='bg-input border-none roundedw-full placeholder:font-bold w-[200px]' 
                                        placeholder={resourcesLength === 0 ? 'Search' : `Search among ${resourcesLength} resources`} 
                                        onFocus={() => setopenSearchDropdown(true)} 
                                        onBlur={(e) => {
                                            const relatedTarget = e.relatedTarget;
                                            if (!relatedTarget || !relatedTarget.closest('.ant-dropdown')) {
                                                setopenSearchDropdown(false);
                                            }
                                        }}
                                        />

                                    </Dropdown>
                                </div>
                                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                <Dropdown
                                    overlay={(disabled.filter) ? <></> : <TypesDropdown selectedType={selectedPubType} selectAction={(node:any) => setSelectedPubType({ value: node.id, icon: node.iconUrl, type:'type' , title:node.name_plural.en})} typesList={publicationsTypes}/>} trigger={['click']} disabled={!!(loading || params.group)} 
                                    
                                    >
                                        {/* <Spin spinning={loading}> */}
                                        <button>
                                            <ButtonLg
                                                suffix={{
                                                    icon:{type:'icon', src: selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                                                    size: 'mini',
                                                    onClick:() => selectedPubType.value ? setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                                                }}
                                                prefix={{
                                                    icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                                                    size: 'sm'
                                                }}
                                                buttonProps={{
                                                    disabled:!!(loading || disabled.filter || params.group)
                
                                                }}
                                                width='w-[150px]'
                                            ><>{selectedPubType?.value? selectedPubType.title :'Filter'}</></ButtonLg>
                
                                        </button>
                                    
                
                                    </Dropdown>
                                    <div className='h-[15px] w-[1px] bg-gray-300'></div>
                                     <Dropdown
                                        overlay={disabled.display ? <></> : <DisplayDropdown setGroupBy={
                                            (value:boolean) => {
                                                searchParams.set('grouped', value ? 'true' : '');  
                                                setSearchParams(searchParams)
                                            }
                                        } groupBy={groupBy} displayMode={displayMode} setDisplayMode={setDisplayMode} barActivatedFields={barActivatedFields} setBarActivatedFields={setBarActivatedFields} /> }
                                        trigger={['click']}
                                        disabled={(disabled.display)}
                                        >
                                                <button>
                                                    <ButtonLg
                                                        suffix={{
                                                            icon:{type:'icon', src: 'ChevronDownIcon' },
                                                            size: 'mini'
                                                        }}
                                                        buttonProps={{
                                                            disabled: (disabled.display)
                                                        }}
                                                        prefix={{
                                                            icon:{type:'icon', src: groupBy ? 'GroupBy' : 'Degroup' },
                                                            size: 'sm'
                                                        }}
                                                        width='w-[150px]'
                                                    ><>Diplay</></ButtonLg>
                    
                                                </button>
                                                                                            
                                            
                                        </Dropdown>
                
                            </div>
                            {/* <ActionsBar
                                resourcesActions={{
                                    filter: disabled.filter ? undefined :{
                                        selectedPubType,
                                        setSelectedPubType
                                    },
                                    display: disabled.display ? undefined :{
                                        displayMode,
                                        setDisplayMode,
                                        groupBy,
                                        setGroupBy: (value:boolean) => {
                                            searchParams.set('grouped', value ? 'true' : '');  
                                            setSearchParams(searchParams);
                                        }
                
                                    },
                                    bar: {
                                        barActivatedFields, 
                                        setBarActivatedFields
                                    } 
                                }}
                                detailsActions={{
                                    mode,
                                    setObjectDisplayMode,
                                    recheckPin:0, 
                                    openActions: {
                                        volumes:({key,volume}:any) => {
                                        
                
                                        }
                                    }
                                }}
                            /> */}
                        </div>
                         
       
            </div>


      </div>
      <UserActionsButton/>
    </div>        
   
  )
}
