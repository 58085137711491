import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 21.0037H5.99748C4.89202 21.0037 3.99664 20.1084 3.99664 19.0029V4.99708C3.99664 3.89162 4.89202 2.99625 5.99748 2.99625H17.0021C18.1075 2.99625 19.0029 3.89162 19.0029 4.99708V8.99875"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 6.99792H5.49728"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 17.0021H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49603 12H5.2972"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 3V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6688 17.1773L13.8362 17.2129C14.1581 17.2227 14.4269 16.9697 14.4367 16.6479L14.4722 15.4804C14.482 15.1586 14.2291 14.8897 13.9072 14.8799L12.7398 14.8444C12.4179 14.8346 12.1491 15.0876 12.1393 15.4094L12.1038 16.5769C12.094 16.8987 12.3469 17.1675 12.6688 17.1773Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2466 20.146L18.414 20.1815C18.7359 20.1913 19.0047 19.9383 19.0145 19.6165L19.05 18.449C19.0598 18.1272 18.8069 17.8584 18.485 17.8486L17.3176 17.813C16.9957 17.8032 16.7269 18.0562 16.7171 18.378L16.6816 19.5455C16.6728 19.8674 16.9247 20.1362 17.2466 20.146V20.146Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4186 14.4926L18.5861 14.5281C18.9079 14.5379 19.1767 14.285 19.1865 13.9631L19.2221 12.7956C19.2318 12.4738 18.9789 12.205 18.6571 12.1952L17.4896 12.1597C17.1677 12.1499 16.8989 12.4028 16.8891 12.7246L16.8536 13.8921C16.8448 14.214 17.0968 14.4828 17.4186 14.4926V14.4926Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7221 18.3442L14.421 16.7034"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4427 15.3334L16.8572 13.9062"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
