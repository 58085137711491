import { PencilIcon, TrashIcon } from '@heroicons/react/solid'
import React from 'react'
import { ReactSVG } from 'react-svg'
import { Plans } from '../../../types'
import Icon from '../../../components/icons/Icon'

type Props = {
    plan:Plans
    handleNavigate:(id:string, title:string) => void, 
    active:boolean, 
    editPlan:(data:Plans) => void,
    noActions?:boolean
}
export default function Plan(props: Props) {
  return (
    <div className={` rounded bg-input border !border-gray-500  ${props.active ? 'bg-black fill-white !text-white' : 'bg-hover-transparent'} ${props.noActions && 'cursor-pointer'} ${props.plan.isHidden && 'opacity-30 !cursor-not-allowed'}`} onClick={() => props.noActions ? props.handleNavigate(props.plan.id, props.plan.title.en) : {}}>
     
        <div className={` h-full p-5 max-w-[200px]`}>
            <div className=" ">
                {props.plan.coverImageUrl ? <ReactSVG src={props.plan.coverImageUrl} className='w-[100px] h-[100px]'/> :<Icon name='Brand' className='w-[100px] h-[100px]' />}
                
            </div>
            <div>
                <p className="text-[22px] font-semibold flex-1 leading-[26px]">{props.plan.title.en}</p>
                <p className={`text-[16px] ellipsis-3 ${!props.active && 'text-gray-500'}`}>{props.plan.description?.en}</p>

            </div>

        </div>
    </div>
  )
}
