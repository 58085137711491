import React from 'react'
import ModalLayout from '../../../components/publishLayout/ModalLayout'
import Header from '../../../components/publishLayout/Header'
import { Outlet } from 'react-router-dom'
import { usePublicationCreationContext } from '../../../context/PublicationCreationContext'

export default function CreatePublicationLayout() {
    const { active } = usePublicationCreationContext()
  return (
    <div>
        <ModalLayout backLink='/mydesk'>
            <>
                <Header
                    tabs={[
                        {
                            title: 'Edit',
                            path: 'edit',
                            handleNavigation: () => {},
                            disabled: false
                        },
                        {
                            title: 'Publish',
                            path: 'publish',
                            handleNavigation: () => {},
                            disabled: !active.publish
                        }
                    ]}
                    title={'Create a publication'}
                    icon={{src:'AddDocument', type:'icon'}}
                
                />
                <Outlet/>
            </>
        </ModalLayout>
    </div>
  )
}
