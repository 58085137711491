import { Spin } from 'antd'
import React, { useState } from 'react'
import { Highlight, OwnerType, PartnerType, Publication, VolumeSearch } from '../../../../types/index'
import ResourcesLayout from '../resourcesLayout'
import LocationgGlobalLayout from './layout'

type Props = {
  partners: null | PartnerType[],
  sponsors: null | PartnerType[],
  availableLocations:{document :VolumeSearch}[] | null,
  publications: {document:Publication, highlight:Highlight}[] | undefined,
  webResources: {document:Publication, highlight:Highlight}[] | undefined,
  partnerPublications?:{document:Publication, highlight:Highlight}[] | undefined,
  suffix:string,
  showApps?:boolean,
  allowSearch?:boolean,
  groupBy?:{
    webresources:boolean,
    paidPubs:boolean
  },
  owner?: OwnerType,
  all?:string,
  mainSites?:PartnerType[],
  home?:boolean,
  stats?:{
    total_publications:number,
    total_servers:number,
    organizations:number
  }
  layoutProps:{
      loading:boolean 
      selectedKey:string,
      disabled:{filter:boolean, display:boolean, new:boolean,query:boolean},
      publicationBar:boolean,
      id:string,
      collection:string
    },
    isLoaded:boolean,
    partnerId?:string,
    partnerName?:string
}
export default function LocationLayout({ layoutProps, partners, sponsors, availableLocations, publications, suffix, showApps = false, allowSearch = true, groupBy, owner,all , mainSites, home, webResources, isLoaded, partnerId, partnerPublications, partnerName, stats} : Props) {
    
  return (
   <LocationgGlobalLayout {...layoutProps}>
     <ResourcesLayout
       partners={partners}
       sponsors={sponsors}
       availableLocations={availableLocations}
       publications={publications}
       suffix={suffix}
       allowSearch={allowSearch}
       showApps={showApps}
       groupBy={groupBy}
       owner={owner}
       all={all}
       mainSites={mainSites}
       home={home}
       webResources={webResources}
       isLoaded={isLoaded}
       partnerPublications={partnerPublications}
      partnerId={partnerId}
      partnerName={partnerName}
      stats={stats}
     />

   </LocationgGlobalLayout>
          

       
    
  )
}
