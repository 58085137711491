import { createContext, useContext, useMemo, useState } from "react";


type States = {
  editData:{keywords:string[],  title:string, description:string, id?:string},
  active: {publish:boolean, edit:boolean},
  InitId:string | null,
  isLoaded:boolean

}
type Functions = {
  
  setEditData:(data:{keywords:string[], title:string, description:string})=> void,

  setActive: (data: {publish?:boolean, edit?:boolean}) => void,
  setInitId: (data:string | null) => void,
  setisLoaded:(data:boolean) => void

}
type Props = States & Functions
const VolumeCreationContext = createContext<Props>({} as Props);

export const useVolumeCreationContext = () => useContext(VolumeCreationContext);

export const VolumeCreationContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    editData:{keywords:[], description: '', title: '',},

    active: {publish:false, edit:false},
    InitId:null,
    isLoaded:false
  })
  

  const value = useMemo(() => {
    

   
   
   
    const setEditData =(data:{keywords:string[], title:string, description:string})=> {
      setState((prev) => ({
        ...prev,
        editData:data
      }))
    }

    const setActive = (data: {publish?:boolean, edit?:boolean}) => {
      setState((prev) => ({
        ...prev,
        active: {...prev.active, ...data}
      }))
    }

    const setInitId= (data:string | null) => {
      setState((prev) => ({
        ...prev,
        InitId: data
      }))
    
    }

    const setisLoaded = (data:boolean) => {
      setState((prev) => ({
        ...prev,
        isLoaded: data
      }))
    }
    return {
      ...state,
   
     setEditData, setActive,setInitId,setisLoaded
    }
  }, [state])

  return <VolumeCreationContext.Provider value={value}>{children}</VolumeCreationContext.Provider>;
};