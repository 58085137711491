
import React, { act, useEffect, useRef, useState } from 'react'



import { Form, Input, Select } from 'antd';

import { useVolumeCreationContext } from '../../../context/volumeCreationContext';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import Icon from '../../../../../components/icons/Icon';
import { volumeCodes } from '../../../constants';


export default function EditLink() {

    const [errors, seterrors] = useState<any>({})
    const [form] = Form.useForm()
    const { setEditData, setActive, editData, active } = useVolumeCreationContext()
    const navigate = useNavigate()
    const params = useParams()
    const [volumeType, setvolumeType] = useState<{icon:string, name:string}>({icon: '', name:''});
   
   const handleNext = () => {
    navigate(`/mydesk/volumes/create/${params.type}/publish`)
   }
   
   const handleValuesChange = (changedValues:any,allValues:any) => {
    const { title, keywords, description} = allValues
    setEditData(allValues)
    seterrors((prev:any) => {
        const state = {...prev}
        state[Object.keys(changedValues)[0]] = undefined
        return state
    })
    if(title && keywords && description){
        seterrors({})
        setActive({publish:true})
    }else{
        setActive({publish:false})
    }
   }

   useEffect(() => {
    
    const volume =volumeCodes.filter(code => code.code === params.type)[0]
    if(volume){
        setvolumeType(volume)
    }
   }, [params.type]);

 
  
  return ( 
    <>
  
        <Form 
        form={form}
        className='h-full flex flex-col'
        onValuesChange={handleValuesChange}
        initialValues={editData}
        
         >
            <div className=' px-7 flex justify-center relative top-[20%]'>
                <div>
                    <div>
                        <Icon name={volumeType.icon} className='w-[200px] h-[200px] -rotate-12'/>
                    </div>
                    <div className="flex">
                        <div className="flex-1 max-w-[300px] ">
                            <p className='font-bold title-level2 '>Tell us about your {volumeType.name}</p>
                            <p className='text-level2  '>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam cumque, dolorum nam a exercitationem
                            </p>
                        </div>
                        <div className="max-w-max w-[70%] bg-sub-windows overflow-auto pb-4 ">
                        <div className='relative pr-5 space-y-2 w-max' >     
                                <div className="flex gap-4 w-max items-center">
                                <p className="md:text-end font-bold w-[150px]">Title </p>
                                <div className="flex-1 max-w-full w-[450px] "> 
                                    <Form.Item
                                        validateStatus={errors?.title && "error"}
                                        help={errors?.title && errors?.title}
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={`title`}
                                        required
                                    >
                                        <input
                                        type="text"
                                        placeholder={'Volum title (20 characters max)'}
                                        
                                        autoComplete="off"
                                        className=" border border-main border-opacity-20 rounded input-h w-full"
                                        />
                
                                    </Form.Item>
                                            
                                </div>
                                </div>
                                <div className="flex gap-4 w-max">
                                <p className="md:text-end font-bold w-[150px]  pt-[10px]">
                                    Description
                                </p>
                                <div className="flex-1 max-w-full w-[450px] ">
                                    <Form.Item
                                        validateStatus={errors?.description && "error"}
                                        help={errors?.description  && errors?.description }
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={'description'}
                                        required
                                        
                                    >
                                        
                                        <TextArea rows={7} className='border border-main' placeholder='Describe your volum (100 characters max)'/>
                                    </Form.Item>
                                </div>
                                </div>
                                <div className="flex gap-4 w-max ">
                            
                                <p className="md:text-end font-bold w-[150px]  pt-[10px]">
                                    Keywords
                                </p>
                                <div className="flex-1 max-w-full w-[450px] "> 
                                    <Form.Item
                                        validateStatus={errors?.keywords && "error"}
                                        help={errors?.keywords  && errors?.keywords }
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={`keywords`}
                                        required
                                    >
                                    <Select
                                        className="input-style rounded transition-none w-full rightpage  border border-main "
                                        mode="tags"
                                        open={false}
                                        style={{width: "100%", background:'#fff'}}
                                        placeholder={'Insert a maximum of 8 keywords'}
                                        maxLength={8}
                                        // value={keywords}
                                        
                                        // onDeselect={(e) => setkeywords((prev:string[] ) => (prev.filter(item => item !== e)))}
                                        // onSelect={(e) => setkeywords((prev:string[]) => ([...prev, e]))}
                                    />       
                
                                    </Form.Item>
                                </div>
                                </div>
                                <div className='flex'>
                                    <div className="w-[150px]"></div>
                                    <div className="flex-1 max-w-full w-[450px] ">
                                        <button type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted' disabled={!active.publish} onClick={handleNext}>Next</button>

                                    </div>
                
                                    
                
                
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Form>

 
    
    </>
    
                    
    

  )
}

