import { Dropdown, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useMydeskContext } from './context/MydeskContext'


import { useAuthContext } from '../../context/AuthContext'

import { fetchInt } from '../../utils/api/fetchInt'
import { getUserSubscriptions, getUserVolumes, updateUser } from '../../services/api/request'
import Tutorial from './components/Tutorial'
import WelcomePopup from './components/welcome'
import PathBarContainer from './components/TopBarContainer'


export default function MydeskLayout() {
    const { setUserCurrentSubscription } = useMydeskContext()
    const {user} = useAuthContext()
    const [startTutorial, setstartTutorial] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const [welcomePopup, setwelcomePopup] = useState(false);
 
  
   useEffect(() => {
        
        fetchInt(getUserVolumes).then(res => res.json()).then(data => console.log(data, 'volumes')).catch(err => console.log(err))
        
     }, []);

    useEffect(() => {
        
       if(!user?.firstTimeAccess){
       
        fetchInt(updateUser, {
            method: 'POST', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify({firstTimeAccess:true})
        }).then(res => res.json()).then(() =>{}).catch(err => console.log(err))
       }
    }, [user?.id]);

    useEffect(() => {
        fetchInt(getUserSubscriptions(true)).then(res => res.json()).then(data => setUserCurrentSubscription(data.data)).catch(err => console.log(err))
        
    }, []);
    useEffect(() => {
        
        if(searchParams.get('subscription')){
            setwelcomePopup(true)
        }
    }, []);
    return (

        <div className="flex flex-col h-full teddra-rounded rounded-t-none overflow-hidden ">
            <PathBarContainer/>
         
            <div className="flex-1  flex flex-col pl-4" id='test'>
                <Outlet/>

                 
            </div>
            
            {/* {!user?.currentSubscription?.id && (
            <div className="absolute bottom-0 left-0 w-full bar-h-mini bg-red-700 text-center text-white flex items-center justify-center">
                <Link to={'/connect/signup/steps/1'} className='underline text-inherit'>Please continue your subscribtion</Link>
            </div>

            )} */}
            {startTutorial&& (
                <Tutorial/>

            )}
            {welcomePopup && (
                <WelcomePopup
                    open={welcomePopup}
                    onClose={() => {
                        setwelcomePopup(false)
                        // setstartTutorial(true)
                        
                    }}
                
                />
            )}
        </div>

  )
}
