import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'

import useGetPublications from '../../hooks/useGetPublications'
import LocationLayout from '../../components/locationLayout'
import useGetWebResources from '../../hooks/useGetWebResources'
import { useSearchContext } from '../../context/searchContext'
import useSearchLocations from '../../hooks/useSearchLocations'
import useFetchServerStatistics from '../../hooks/useFetchServerStats'

export default function ServerLocationLayout() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { groupBy } = useSearchContext()
    const [searchParams] = useSearchParams();
    
    const { webresources, isLoaded:isLoaded2 } = useGetWebResources({id:params.id!, groupBy:true})
    const { searchResults } = useSearchLocations({query: '*', filter: `&& roots:=${params.id} && id:!=${params.id}`, sort: 'created_at:desc', limit: 6})
    
    // const { partners, sponsors} = useGetPartners({id:params.id!, coll:'LocalClusters', setloading:setloading})
    const { publications, isLoaded } = useGetPublications({id:params.id!, limit: 6, groupBy: !!searchParams.get('grouped')})
    const { total_publications, total_servers, organizations } = useFetchServerStatistics(params.id)

    
  return (
    <>
    <LocationLayout
         layoutProps={
           {
             loading,
             selectedKey: params.id!,
             disabled:{filter:false, new:false, display:false,query:false},
             publicationBar:true,
             collection: 'LocalClusters',
             id: params.id!
           }
           
         }
         groupBy={groupBy}
         allowSearch={true}
         publications={publications}
         sponsors={null}
         partners={null}
         availableLocations={searchResults}
         suffix={`LocalClusters/${params.id}/`}
         home
         webResources={webresources}
         isLoaded={isLoaded && isLoaded2}
         partnerPublications={undefined}
         stats={{total_publications, total_servers, organizations}}
        //  partnerId={partners?.[0]?.id}
        //  partnerName={partners?.[0]?.name}
         
         
    />
    </>
  )
}
