import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context/AppContext'
import { Modal } from 'antd';
import Icon from '../../../components/icons/Icon';
import { useSearchParams } from 'react-router-dom';

type Props = {
    open:boolean
    setOpen:(open:boolean) => void
}
export default function SplashScreen({open, setOpen}:Props) {
    const { domain, server } = useAppContext()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        
        if(domain?.id && !searchParams.get('subscription')){
            setOpen(true)
        }
    }, [domain?.id]);
  return (
    <Modal
        visible={open}
        onCancel={() => setOpen(false)}
        destroyOnClose
        footer={null}
        width={1130}
        closeIcon={
            <button className='rounded p-1 bg-white'>
                <Icon className='icon-sm' name='Close'/>
            </button>
        }
    >
        <div className="relative">
            <div className=' flex items-center space-x-8 w-full h-[700px] rounded-[15px] relative px-[70px]'
            
            >
                <div 
                    className=' max-w-[49%] h-full rounded-[15px] flex flex-col justify-between pt-[180px] pb-[40px] space-y-14'
                    
                >
                    <div className="flex ">
                        <span className='bg-skin-fill-inverted font-bold text-skin-inverted p-5 w-max text-[41px] leading-[48px] h-max max-w-[150px] truncate '>{domain?.title.toLocaleLowerCase()}</span>
                        <div className="flex-1 mt-4">
                            <div className=''>
                                <p className='text-[41px] leading-[48px]'>
                                    <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                                    <span className=''>qorddu</span>
                                    <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                                    <span>com</span>
                                    <span className='mx-2'>|</span>
                                    <span>{server?.title}</span>

                                </p>
                                <div className='ml-4 space-y-4 mt-5'>
                                    <p className='text-[21px] leading-[29px]'>
                                        {/* {domain?.description} */}
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit veritatis animi, perspiciatis quibusdam iure alias ipsam. Suscipit ad quisquam
                                    </p>
                                    <p className='text-[21px]  font-light leading-[29px]'>
                                        {/* {domain?.description} */}
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit veritatis animi, perspiciatis quibusdam iure alias ipsam. Suscipit ad quisquam
                                    </p>
                                    <button className='px-3 py-2 border border-main rounded'>En savoir plus sur Qorddu</button>

                                </div>
                            

                            </div>

                        </div>
                    </div>
                  
                </div>
                {/* <img src="https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/homedesqs%2FGroup%201.png?alt=media&token=4f79d01d-370b-4e3a-af09-cf9666fc2117" className='w-[496px] flex-1 h-auto object-cover' alt="" /> */}
                <div className='bg-skin-fill-muted w-[450px] h-[450px]'>

                </div>
                {/* <div className='absolute bottom-10 text-white right-10'>
                    <Icon className='w-[50px] h-[50px]' name='TeddraLogo'/>
                </div> */}
                
            </div>
            {/* <div className="absolute bottom-[53px] right-10">
                    <img className='w-[70px] h-auto' src='/logo.png'/>
            </div> */}

        </div>
    </Modal>
  )
}
